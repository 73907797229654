<div *ngIf="mostrarfiltros" class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="sample-page">
      <section class="range-selection-DP">
        <div class="row">
          <div class="col-12">
            <div class="card">


              <div class="card">
                <div class="row">
                  <div class="col-12">
                    <span style="font-weight: bolder; margin-left: 1rem;">Fechas</span>
                    <div class="row" style="margin-left:1rem;">
                      <form class="form-inline">
                        <div class="form-group">
                          <div class="input-group w-0 ">
                            <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                              [autoClose]="'outside'" [showWeekNumbers]="false"
                              (dateSelect)="onDateSelection($event,datepicker)" [displayMonths]="1" [dayTemplate]="t"
                              outsideDays="visible" [startDate]="fromDate!" tabindex="-1" />
                            <ng-template #t let-date let-currentMonth="currentMonth" let-selected="selected"
                              let-focused="focused">
                              <span class="custom-day" [class.focused]="focused"
                                [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled"
                                [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)"
                                (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                {{ date.day }}


                              </span>

                            </ng-template>

                          </div>
                        </div>
                        <div class="form-group">
                          <div class="input-group">
                            <input style="display: none;" #dpFromDate class="form-control" placeholder="yyyy-mm-dd"
                              name="dpFromDate" [value]="formatter.format(fromDate)"
                              (input)="fromDate = validateInput(fromDate, dpFromDate.value)" disabled />
                            <div class="input-group fecha">
                              <input class="fecha" type="text" [value]="desdehasta" name="" id="" disabled>
                            </div>
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary feather icon-calendar"
                                (click)="datepicker.toggle()" type="button" rippleEffect></button>

                            </div>
                          </div>
                        </div>
                        <div class="form-group ml-sm-2" style="display: none;">
                          <div class="input-group">
                            <input #dpToDate class="form-control" placeholder="yyyy-mm-dd" name="dpToDate"
                              [value]="formatter.format(toDate)" disabled />
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary feather " (click)="datepicker.toggle()"
                                type="button" rippleEffect disabled></button>
                            </div>
                          </div>
                        </div>
                      </form>



                      <div class="row">
                        <div class="columnbut">
                          <button class="btn btn-outline-primary btn-sm m-2 float-left" (click)="seleccionarAnterior()"
                            rippleEffect>
                            Seleccionar Mes Anterior
                          </button>
                        </div>
                        <div class="columnbut">
                          <button class="btn btn-outline-primary btn-sm m-2 float-left" (click)="seleccionarMesActual()"
                            rippleEffect>
                            Seleccionar Mes Actual
                          </button>
                        </div>
                        <div class="columnbut">

                          <button class="btn btn-outline-primary btn-sm m-2 float-left" (click)="seleccionarHoy()"
                            rippleEffect>
                            Seleccionar Hoy</button>

                        </div>

                        <div class="columnbut">

                          <button class="btn btn-outline-primary btn-sm m-2 float-left" (click)="seleccionarAyer()"
                            rippleEffect>
                            Seleccionar Ayer</button>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="display: inline-flex;">

                <div class="column" style="width:20% ">
                  <div class="row">
                    <span style="font-weight: bolder;">Agencia</span>
                    <div *ngIf="mostrarfiltros" class="columy" style="padding-left: unset;padding-right: unset;">


                      <ng-select [clearSearchOnAdd]="true" [clearable]="false" (blur)="filterAge($event)"
                        [disabled]="deshabilitado" [items]="agencias" [multiple]="true" [closeOnSelect]="false"
                        [searchable]="true" bindLabel="nombreAgencia" placeholder="Seleccione Agencias"
                        [(ngModel)]="agenciaTienda">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label" [value]="item.codigoAgencia"> {{item.nombreAgencia}}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>

                    </div>
                    <div *ngIf="mostrarfiltros" class="columx" style="padding-left: unset;padding-right: unset;">
                      <span *ngIf="!deshabilitado"><button *ngIf="mostrarCampo" type="button"
                          class="btn btn-flat-danger" rippleEffect (click)="limpiarFiltAgen()"
                          title="limpiar filtro">X</button></span>
                    </div>
                  </div>
                </div>
                <div class="column" style="width:20% ">
                  <div class="row">
                    <span style="font-weight: bolder;">Formato</span>
                    <div *ngIf="mostrarfiltros" class="columy" style="padding-left: unset;padding-right: unset;">

                      <ng-select [clearSearchOnAdd]="true" [clearable]="false" (blur)="filterFormat($event)"
                        [items]="formatos" [multiple]="true" [closeOnSelect]="false" [searchable]="true"
                        bindLabel="item" placeholder="Seleccione formatos" [(ngModel)]="formatoTienda">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label" [value]="item"> {{ item }}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>

                    </div>
                    <div *ngIf="mostrarfiltros" class="columx" style="padding-left: unset;padding-right: unset;">
                      <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger" rippleEffect
                        (click)="limpiarFiltFor()" title="limpiar filtro">X</button>
                    </div>
                  </div>
                </div>
                <div class="column" style="width:20% ">
                  <div class="row">
                    <span style="font-weight: bolder;">Ciudad</span>
                    <div *ngIf="mostrarfiltros" class="columy" style="padding-left: unset;padding-right: unset;">

                      <ng-select [clearSearchOnAdd]="true" [clearable]="false" (blur)="filterPV($event)"
                        [items]="ciudades" [multiple]="true" [closeOnSelect]="false" [searchable]="true"
                        bindLabel="item" placeholder="Seleccione ciudad" [(ngModel)]="ciudad">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label" [value]="item"> {{ item }}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>

                    </div>
                    <div *ngIf="mostrarfiltros" class="columx" style="padding-left: unset;padding-right: unset;">
                      <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger" rippleEffect
                        (click)="limpiarFiltCiu()" title="limpiar filtro">X</button>
                    </div>
                  </div>
                </div>
                <div class="column" style="width:40% ">
                  <div class="row">

                    <div *ngIf="mostrarfiltros" class="columy" style="padding-left: unset;padding-right: unset;">
                      <span style="font-weight: bolder;">Punto Venta</span>
                      <!-- <ng-select placeholder="P V" [(ngModel)]="codigoTienda" (ngModelChange)="filterAge($event)">
                        <ng-option selected value="null">P V</ng-option>
                        <ng-option *ngFor="let item of tiendas" [value]="item.Codigo">{{item.Nombre}}
                        </ng-option>


                      </ng-select> -->
                      <!-- filterTiend($event) -->
                      <ng-select [clearSearchOnAdd]="true" [clearable]="false" [items]="tiendas" [multiple]="true"
                        [closeOnSelect]="false" [searchable]="true" bindLabel="Nombre" placeholder="Seleccione PV"
                        [(ngModel)]="codigoTienda">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label" [value]="item.Codigo"> {{ item.Nombre }}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>


                    </div>
                    <div *ngIf="mostrarfiltros" class="columx" style="padding-left: unset;padding-right: unset;">
                      <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger" rippleEffect
                        (click)="limpiarFiltPv()" title="limpiar filtro">X</button>
                    </div>
                  </div>
                </div>
              </div>



              <button style="margin:1rem" [disabled]="loading" class="btn btn-relief-primary" tabindex="4" rippleEffect
                (click)="consultar()">
                <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>Consultar
              </button>

            </div>

          </div>

        </div>
        <div class="row" style="margin-top:-2.5rem ;">
          <div class="col-md-6">
            <h6 class="mt-2" style="font-size: 10px;">Ultima sincronizacion ventas:
              {{fechaSincronizacion | date:'MMM d, y, h:mm a'}}</h6>
          </div>

        </div>
        <div class="row" style="margin-top:-1.5rem ;">
          <div class="col-md-6">
            <h6 class="mt-2" style="font-size: 10px;">Ultima sincronizacion Conteo:
              {{fechaSincronizacionCam | date:'MMM d, y, h:mm a'}}</h6>
          </div>
        </div>


      </section>
      <div class="row">
        <div class="col-12">

          <div class="card sdiv">
            <!--/ Basic Date Picker -->
            <div class="card-header">
              <div class="col-lg-12 col-12">
                <div class="card card-company-table">
                  <div class="card-body p-0">
                    <div class="table-responsive">
                      <table class="table table-hover-animation">
                        <thead>
                          <tr>
                            <th>Nombre Pv</th>
                            <th>Dia </th>
                            <th>Fecha</th>
                            <th>venta fisica</th>
                            <th>venta digital</th>
                            <th>venta total</th>
                            <th>part digital</th>
                            <th>ppto fisico</th>
                            <th style="width: 150px;">ppto digital</th>
                            <th style="width: 20px;">ppto total</th>
                            <th style="width: 20px;">Cump fisico</th>
                            <th style="width: 20px;">Cump digital</th>
                            <th style="width: 20px;">Cump total</th>
                            <th style="width: 20px;">Unid</th>
                            <th style="width: 20px;">Trans</th>
                            <th style="width: 20px;">Ppto Trans</th>
                            <th style="width: 20px;">Cump Trans</th>
                            <th style="width: 20px;">VPT</th>
                            <th style="width: 20px;">UPT</th>
                            <th style="width: 20px;">Entradas</th>
                            <th style="width: 20px;">Tc</th>
                            <th style="width: 20px;">Venta Credito</th>
                            <th style="width: 20px;">ppto Credito</th>
                            <!-- <th style="width: 20px;">#Credito</th>
                            <th style="width: 20px;">ppto #Credito</th> -->




                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of resultado | slice: (pageBasic-1) * pageSize : pageBasic * pageSize">
                            <td>
                              <div>
                                <div style="font-size: 12px;white-space: nowrap">

                                  {{item.Nombre}}

                                </div>

                                <div>
                                  <div class="font-weight-bolder"></div>
                                  <div class="font-small-2 text-muted"></div>
                                </div>
                              </div>
                            </td>


                            <td>

                              {{item.nombreDelDiaSemanaAnio }}

                            </td>
                            <td>

                              {{item.fecha }}

                            </td>

                            <td>{{item.totalVentasFisicass| currency : '$' : 'symbol' :'1.0-0'}}
                            </td>
                            <td>{{item.totalVentasVirtuales | currency : '$' :
                              'symbol':'1.0-0'}}</td>
                            <td>{{item.totalVentas | currency : '$' :
                              'symbol':'1.0-0'}}</td>
                              <td>{{item.partDig | number:'1.0-0'}}%</td>
                            <td>{{item.presupuestoFisico | currency : '$' :
                              'symbol':'1.0-0'}}</td>
                            <td>{{item.presupuestoDigital | currency : '$' :
                              'symbol':'1.0-0'}}</td>
                            <td>{{item.presupuestoTotal | currency : '$' :
                              'symbol':'1.0-0'}}</td>
                            <td>{{item.cumplimientoFisico | number:'1.0-0'}}%</td>
                            <td>{{item.cumplimientoDigital | number:'1.0-0'}}%</td>
                            <td>{{item.cumplimientoTotal | number:'1.0-0'}}%</td>
                            <td>{{item.totalUnidades | number:'1.0-0'}}</td>
                            <td>{{item.numeroTransaccionesMes | number:'1.0-0'}}</td>
                            <td>{{item.PptoTrans | number:'1.2-2'}}</td>
                            <td>{{item.cumplimientoTransacciones | number:'1.0-0'}}%</td>
                            <td>{{item.vpt | currency : '$' : 'symbol' : '1.0-0'}}</td>
                            <td>{{item.upt | number:'1.2-2'}}</td>
                            <td>{{item.numeroEntradasMes | number:'1.0-0'}}</td>
                            <td>{{item.tC | number:'1.1-1'}}%</td>
                            <td>{{item.totalCreditoKaiowa | currency : '$' :
                              'symbol':'1.0-0'}}</td>
                            <td>{{item.presupuestoKaiowa | currency : '$' :
                              'symbol':'1.0-0'}}</td>
                            <!-- <td>{{item.cantidadTransaccionesCreditos | number:'1.1-1'}}</td>
                              <td>{{item.PptoCreditoNro | number:'1.1-1'}}</td> -->



                            <!-- Modal -->
                            <ng-template #modalBasic let-modal>
                              <div class="modal-header">
                                <h4 class="modal-title" id="myModalLabel1">Horario</h4>
                                <button type="button" class="close" (click)="modal.dismiss('Cross click')"
                                  aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body" tabindex="0" ngbAutofocus>
                                <div class="table-responsive">
                                  <table class="table table-hover-animation">
                                    <thead>
                                      <tr>
                                        <th>Evento</th>
                                        <th>Hora</th>
                                        <th>Id Terminal</th>
                                        <th>Nombre Terminal</th>

                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let item of resulta">
                                        <td>{{item.evento}}</td>
                                        <td>{{item.hora}}</td>
                                        <td>{{item.idTerminal}}</td>
                                        <td>{{item.nombreTerminal}}</td>
                                      </tr>

                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')"
                                  rippleEffect>
                                  Aceptar
                                </button>
                              </div>
                            </ng-template>

                          </tr>
                          <tr *ngIf="aparecetotal" style="font-size: 14px;font-weight: bolder;">
                            <td>Total</td>
                            <td></td>
                            <td></td>
                            <td>{{sumaVentasFisicas | currency : '$' : 'symbol' :'1.0-0'}}</td>
                            <td>{{sumaVentasDigitales | currency : '$' : 'symbol' :'1.0-0'}}</td>
                            <td>{{sumaVentasTotales | currency : '$' : 'symbol' :'1.0-0'}}</td>
                            <td>{{partDigitalTotal | number:'1.0-0'}}%</td>
                            <td>{{sumaPptoFisico | currency : '$' : 'symbol' :'1.0-0'}}</td>
                            <td>{{sumaPptoDigital | currency : '$' : 'symbol' :'1.0-0'}}</td>
                            <td>{{sumaPptoTotal | currency : '$' : 'symbol' :'1.0-0'}}</td>
                            <td>{{sumaCumplimientoFisico | number:'1.0-0'}}%</td>
                            <td>{{sumaCumplimientoDigital | number:'1.0-0'}}%</td>
                            <td>{{sumaCumplimientoTotal | number:'1.0-0'}}%</td>
                            <td>{{sumaUnidades | number:'1.0-0'}}</td>
                            <td>{{sumaTransacciones | number:'1.0-0'}}</td>
                            <td>{{sumaPptoTransacciones | number:'1.0-0'}}</td>
                            <td>{{sumaCumpTransacciones | number:'1.0-0'}}%</td>
                            <td>{{sumaVPT | currency : '$' : 'symbol' :'1.0-0'}}</td>
                            <td>{{sumaUPT | number:'1.0-0'}}</td>
                            <td>{{sumaEntradas | number:'1.0-0'}}</td>
                            <td>{{sumaTC | number:'1.0-0'}}%</td>
                            <td>{{sumaCK | currency : '$' : 'symbol' :'1.0-0'}}</td>
                            <td>{{sumaPptocK | currency : '$' : 'symbol' :'1.0-0'}}</td>
                            <!-- <td>{{sumanumcr | number:'1.1-1'}}</td>
                            <td>{{sumapptonumcr | number:'1.1-1'}}</td> -->


                          </tr>


                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" class="btn btn-flat-success" rippleEffect (click)="enviarexceventas()"><i
                  data-feather='file-text'></i>Excel</button>
              <button *ngIf="coordinador=='Luis Guillermo Maya Arbelaez'" type="button" class="btn btn-flat-success"
                rippleEffect (click)="enviarexceventasPedido()"><i data-feather='file-text'></i>Excel Coordinadores
                Comerciales</button>

            </div>

          </div>

        </div>
      </div>


    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
<div>

</div>
<div class="row">
  <div class="col-12">
    <div class="card ndiv">
      <!--/ Basic Date Picker -->
      <div class="card-header">
        <div class="col-lg-12 col-12">
          <div class="card card-company-table">
            <div class="card-body p-0">
              <div class="table-responsive">
                <table class="table table-hover-animation">
                  <thead>
                    <tr>
                      <th>Nombre Pv</th>
                      <th>Fecha</th>
                      <th>Total Venta</th>


                      <!-- <th class="sdiv" style="width: 20px;">Horario</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of resultado | slice: (page-1) * pageSize1 : page * pageSize1">
                      <td><button type="button" class="btn btn-primary" rippleEffect
                          (click)="modalOpen(modalBasic,item)" style="width:170px">
                          <div>
                            <div style="font-size: 12px;">

                              {{item.Nombre}}

                            </div>

                            <div>
                              <div class="font-weight-bolder"></div>
                              <div class="font-small-2 text-muted"></div>
                            </div>
                          </div>
                        </button>
                      </td>
                      <td>{{item.fecha }}</td>
                      <td>{{item.totalVentas | currency : '$' :
                        'symbol':'1.0-0'}}</td>

                      <!-- <td><button type="button" class="btn btn-flat-success" rippleEffect
                          (click)="modalOpen(modalBasic,item)"><i data-feather='watch'></i></button></td> -->

                      <!-- Modal -->
                      <ng-template #modalBasic let-modal>
                        <div class="modal-header">
                          <h4 class="modal-title" id="myModalLabel1">Detalle</h4>
                          <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body" tabindex="0" ngbAutofocus>
                          <div class="table-responsive">
                            <div class="card" style="font-weight: bolder;">
                              {{item.Nombre}}
                            </div>
                            <div class="card" style="margin-bottom: 0.5rem !important;">
                              <div class="row">
                                <div class="col-6" style="font-weight: bolder;"> Fechas: </div>
                                <div class="col-6">{{item.fecha}}</div>
                              </div>

                            </div>
                            <div class="card" style="margin-bottom: 0.5rem !important;">
                              <div class="row">
                                <div class="col-6" style="font-weight: bolder;"> Dia: </div>
                                <div class="col-6">{{item.nombreDelDiaSemanaAnio}}</div>
                              </div>

                            </div>
                            <div class="card" style="margin-bottom: 0.5rem !important;">
                              <div class="row">
                                <div class="col-6" style="font-weight: bolder;">Ventas Fisicas: </div>
                                <div class="col-6">{{item.totalVentasFisicass | currency : '$' :
                                  'symbol':'1.0-0'}}</div>
                              </div>

                            </div>
                            <div class="card" style="margin-bottom: 0.5rem !important;">
                              <div class="row">
                                <div class="col-6" style="font-weight: bolder;">Ventas Digitales:</div>
                                <div class="col-6">{{item.totalVentasVirtuales | currency : '$' :
                                  'symbol':'1.0-0'}}</div>
                              </div>

                            </div>
                            <div class="card" style="margin-bottom: 0.5rem !important;">
                              <div class="row">
                                <div class="col-6" style="font-weight: bolder;">Total Ventas:</div>
                                <div class="col-6">{{item.totalVentas | currency : '$' :
                                  'symbol':'1.0-0'}}</div>
                              </div>

                            </div>
                            <div class="card" style="margin-bottom: 0.5rem !important;">
                              <div class="row">
                                <div class="col-6" style="font-weight: bolder;">Ppto Fisico:</div>
                                <div class="col-6">{{item.presupuestoFisico | currency : '$' :
                                  'symbol':'1.0-0'}}</div>
                              </div>

                            </div>
                            <div class="card" style="margin-bottom: 0.5rem !important;">
                              <div class="row">
                                <div class="col-6" style="font-weight: bolder;">Ppto Digital:</div>
                                <div class="col-6">{{item.presupuestoDigital | currency : '$' :
                                  'symbol':'1.0-0'}}</div>
                              </div>
                            </div>
                            <div class="card" style="margin-bottom: 0.5rem !important;">
                              <div class="row">
                                <div class="col-6" style="font-weight: bolder;">Ppto Total: </div>
                                <div class="col-6">{{item.presupuestoTotal | currency : '$' :
                                  'symbol':'1.0-0'}}</div>
                              </div>

                            </div>
                            <div class="card" style="margin-bottom: 0.5rem !important;">
                              <div class="row">
                                <div class="col-6" style="font-weight: bolder;">Cump Fisico: </div>
                                <div class="col-6">{{item.cumplimientoFisico | number:'1.0-0'}}%</div>
                              </div>

                            </div>
                            <div class="card" style="margin-bottom: 0.5rem !important;">
                              <div class="row">
                                <div class="col-6" style="font-weight: bolder;">Cum Digital: </div>
                                <div class="col-6">{{item.cumplimientoDigital | number:'1.0-0'}}%</div>
                              </div>

                            </div>
                            <div class="card" style="margin-bottom: 0.5rem !important;">
                              <div class="row">
                                <div class="col-6" style="font-weight: bolder;">Cump Total:</div>
                                <div class="col-6">{{item.cumplimientoTotal | number:'1.0-0'}}%</div>
                              </div>

                            </div>
                            <div class="card" style="margin-bottom: 0.5rem !important;">
                              <div class="row">
                                <div class="col-6" style="font-weight: bolder;">Total Unid:</div>
                                <div class="col-6">{{item.totalUnidades | number:'1.0-0'}}</div>
                              </div>

                            </div>
                            <div class="card" style="margin-bottom: 0.5rem !important;">
                              <div class="row">
                                <div class="col-6" style="font-weight: bolder;">Transacciones:</div>
                                <div class="col-6">{{item.numeroTransaccionesMes | number:'1.0-0'}}</div>
                              </div>

                            </div>
                            <div class="card" style="margin-bottom: 0.5rem !important;">
                              <div class="row">
                                <div class="col-6" style="font-weight: bolder;">Vpt:</div>
                                <div class="col-6">{{item.vpt | currency : '$' : 'symbol' : '1.0-0'}}</div>
                              </div>

                            </div>
                            <div class="card" style="margin-bottom: 0.5rem !important;">
                              <div class="row">
                                <div class="col-6" style="font-weight: bolder;">Upt:</div>
                                <div class="col-6">{{item.upt | number:'1.2-2'}}</div>
                              </div>

                            </div>
                            <div class="card" style="margin-bottom: 0.5rem !important;">
                              <div class="row">
                                <div class="col-6" style="font-weight: bolder;">Entradas:</div>
                                <div class="col-6">{{item.numeroEntradasMes | number:'1.0-0'}}</div>
                              </div>

                            </div>
                            <div class="card" style="margin-bottom: 0.5rem !important;">
                              <div class="row">
                                <div class="col-6" style="font-weight: bolder;">Tc:</div>
                                <div class="col-6">{{item.tC | number:'1.1-1'}}%</div>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')"
                            rippleEffect>
                            Aceptar
                          </button>
                        </div>
                      </ng-template>

                    </tr>
                    <!-- <tr *ngIf="aparecetotal" style="font-size: 14px;font-weight: bolder;">
                      <td>Total</td>
                      <td></td>
                      <td></td>
                      <td>{{sumaVentasFisicas | currency : '$' : 'symbol' :'1.0-0'}}</td>
                      <td>{{sumaVentasDigitales | currency : '$' : 'symbol' :'1.0-0'}}</td>
                      <td>{{sumaVentasTotales | currency : '$' : 'symbol' :'1.0-0'}}</td>
                      <td>{{sumaPptoFisico | currency : '$' : 'symbol' :'1.0-0'}}</td>
                      <td>{{sumaPptoDigital | currency : '$' : 'symbol' :'1.0-0'}}</td>
                      <td>{{sumaPptoTotal | currency : '$' : 'symbol' :'1.0-0'}}</td>
                      <td>{{sumaCumplimientoFisico | number:'1.0-0'}}%</td>
                      <td>{{sumaCumplimientoDigital | number:'1.0-0'}}%</td>
                      <td>{{sumaCumplimientoTotal | number:'1.0-0'}}%</td>
                      <td>{{sumaUnidades | number:'1.0-0'}}</td>
                      <td>{{sumaTransacciones | number:'1.0-0'}}</td>
                      <td>{{sumaVPT | currency : '$' : 'symbol' :'1.0-0'}}</td>
                      <td>{{sumaUPT | number:'1.0-0'}}</td>
                      <td>{{sumaEntradas | number:'1.0-0'}}</td>
                      <td>{{sumaTC | number:'1.0-0'}}%</td>


                    </tr> -->


                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-flat-success" rippleEffect (click)="enviarexceventas()"><i
            data-feather='file-text'></i>Excel</button>

      </div>

    </div>
  </div>
</div>
<div *ngIf="mostrarfiltros" class="row sdiv" style="margin-left: 1rem;">
  <ngb-pagination [collectionSize]="longitud" [(page)]="pageBasic" [pageSize]="pageSize" [maxSize]="20"
    [boundaryLinks]="true" [rotate]="true" [directionLinks]="false" aria-label="Default pagination"></ngb-pagination>
</div>
<div *ngIf="mostrarfiltros" class="row ndiv" style="margin-left: 1rem;">
  <ngb-pagination [collectionSize]="longitud1" [(page)]="page" [pageSize]="pageSize1" [maxSize]="3"
    [boundaryLinks]="true" [rotate]="true" [directionLinks]="true" aria-label="Default pagination"></ngb-pagination>
</div>