import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ServiceService } from 'app/services/service.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss'
import { swalProviderToken } from '@sweetalert2/ngx-sweetalert2/lib/di';

@Component({
  selector: 'app-subir-factura',
  templateUrl: './subir-factura.component.html',
  styleUrls: ['./subir-factura.component.scss']
})
export class SubirFacturaComponent implements OnInit {
  contentHeader: { headerTitle: string; actionButton: boolean; breadcrumb: { type: string; links: ({ name: string; isLink: boolean; link: string; } | { name: string; isLink: boolean; link?: undefined; })[]; }; };
  @ViewChild('fechaInputIni') fechaInputIni: ElementRef;
  @ViewChild('fechaInputFin') fechaInputFin: ElementRef;
  @ViewChild('comentario') comentario: ElementRef;
  
  fechaActual: string
  base64: any;
  base64Data: string;
  loading: boolean = false;
  factura: any = [];
  videoStream: MediaStream;
  videoRef: any;
  images: any[];
  foto = '';
  tomafoto = false;
  diferencia: number = 0;
  pedirComentario: boolean;
  comentarioDetalle=""
  mostrarGuardar: boolean;
  base64String: string;
  imagesWData: any[];
  reducedImage: string;
  canvas: any;
  ctx: any;
  modelRadio: number;
  datosComparacion: { idAlmacen: string; fechaInicial: any; fechaFinal: any; };
  verCanvas: boolean=false;
  suma: any=0;
  horaLeida: string;
  constructor(private service: ServiceService) { }

  ngOnInit(): void {
    this.modelRadio = 1
    this.videoRef = document.getElementById('video');
    this.canvas = document.getElementById('canvas')
    this.ctx = this.canvas.getContext("2d");
    this.contentHeader = {
      headerTitle: 'Home',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'Factura',
            isLink: false
          }
        ]
      }
    }






  }
  mostrarCanvas(){
    if(this.verCanvas==true){
      this.verCanvas=false
    }else{
      this.verCanvas=true
    }
    
  }
  ngAfterViewInit() {
    const fecha = new Date();
    this.fechaActual = fecha.toISOString().slice(0, 10);
    this.fechaInputIni.nativeElement.value = this.fechaActual;
    this.fechaInputFin.nativeElement.value = this.fechaActual;
    
  }
  onFileSelected(event: any) {
    this.service.tokenServices().then(result => {


      sessionStorage.setItem('brtkOcr', result.access_token)



    })
    const files: FileList = event.target.files;
    this.images = [];
    this.imagesWData = []
    // Comprobar que el archivo sea una imagen
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      const file = files[i];

      reader.readAsDataURL(file);

      reader.onload = () => {
        this.base64String = reader.result as string;
        const base64 = this.base64String.split(',')[1];

        this.imagesWData.push({
          id: (i + 1).toString(),
          base64: this.base64String
        });
        this.images.push({
          id: (i + 1).toString(),
          base64: base64
        });
      };
    }
    console.log(this.images);
  }

  procesar() {

    let cont = 0
    this.factura = []

    this.images.map(x => {
      this.loading = true
      this.service.ocrService(x).then(result => {
        // if(this.foto!="")
        if (result) {
          console.log(result)
          this.factura.push(result)
          cont = cont + 1
          if (cont == this.images.length) {
            this.consolidado()
          }

          this.loading = false
        } else {

          Swal.fire(
            'Error Al leer',
            'No pudimos leer correctamente la informacion de tu factura. Recuerda que la imagen debe ser exclusivamente de los datos de factura.',
            'error'
          )
          this.loading = false
        }

      })

    })


  }
  configuracionCamara() {
    navigator.mediaDevices.getUserMedia({
      video: { width: 300, height: 300 },
      audio: false
    }).then(stream => {
      console.log(stream);
      this.videoStream = this.videoRef.srcObject = stream;
    })
  }

  iniciarcamara() {
    this.configuracionCamara();
  }
  tomarFoto() {
    this.images = [];
    this.verCanvas=true
    this.tomafoto = true;

    this.iniciarcamara();

    this.ctx.drawImage(this.videoRef, 0, 0, 512, 512)
    this.base64 = this.canvas.toDataURL()
    
    this.images.push({
      id:"1",
      base64: this.base64
    
    })
    this.apagarcamara();

    this.foto = this.base64;

    this.tomafoto = false;
  }
  apagarcamara() {
    var tracks = this.videoStream.getTracks();
    tracks[0].stop();
    tracks.forEach(track => track.stop())
    this.videoRef.pause();
    this.videoRef.srcObject = null;
  }
  consolidado() {
    
    let sumaFact = 0
    this.suma=0
    this.diferencia=0
    let fechaInvalidad=0

    let data = {
      idAlmacen: sessionStorage.getItem('tienda').toString(),
      fechaInicial: this.fechaInputIni.nativeElement.value.replace(/-/g, ''),
      fechaFinal: this.fechaInputFin.nativeElement.value.replace(/-/g, '')
    }

  


    this.datosComparacion=data

    this.service.getConsolidado(data).then(res => {
      console.log(res)
      if (res.codigo != "CCE002") {
        res.consolidadoFechas.map(x => {
          this.suma += x.valorVenta
        })
        this.factura.map(x => {
          const anio = parseInt(x.response.FECHA.replace(/-/g, '').substring(0, 4)); // obtener año como número
            const mes = parseInt(x.response.FECHA.replace(/-/g, '').substring(4, 6)); // obtener mes como número
            const dia = parseInt(x.response.FECHA.replace(/-/g, '').substring(6, 8)); // obtener día como número

            // Crear un objeto Date con los valores de día, mes y año
            const fecha = new Date(anio, mes - 1, dia);

            // Verificar si la fecha es válida comparando los valores de día, mes y año
            if (fecha.getFullYear() !== anio || fecha.getMonth() !== mes - 1 || fecha.getDate() !== dia) {
              // La fecha es inválida, notificar al usuario del error y evitar continuar con el procesamiento de los datos
              
              Swal.fire('Error','La fecha leida para el voucher es incorrecta, porfavor vuelva a tomar la foto','error')
              fechaInvalidad=1
              return;
            }

            // La fecha es válida, continuar con el procesamiento de los datos
            console.log('La fecha ingresada es válida.');
            // Aquí puedes continuar con el procesamiento de los datos sabiendo que la
          sumaFact += this.parseCurrencyString(x.response.VALOR)
        })
        if(fechaInvalidad==0){
          this.diferencia = this.suma - sumaFact
          this.mostrarGuardar = true
          if (this.diferencia > 90 || this.diferencia < -90) {
            Swal.fire('Diferencia Encontrada', 'La consignacion presenta una diferencia de ' + this.diferencia + ' respecto a lo guardado en el sistema. Verifique nuevamente la consignacion o indique el por que de la diferencia', 'warning')
            this.pedirComentario = true
  
          }
        }else{
          return
        }
        
      } else {
        Swal.fire('Error', 'No hay datos de ventas para este dia. Si trabajaste en contingencia, Porfavor Consolida Antes de Subir el Comprobante de Consignacion', 'error')

      }
    })
  }
  parseCurrencyString(currencyString: string): number {
    // Reemplazar caracteres que no sean números ni puntos decimales ni comas
    const cleanedString = currencyString.replace(/[^\d.,]/g, '');
  
    // Contar cuántos puntos decimales y comas hay
    const dotCount = (cleanedString.match(/\./g) || []).length;
    const commaCount = (cleanedString.match(/,/g) || []).length;
  
    // Si hay más comas que puntos, se asume que las comas son separadores de miles
    if (commaCount >= dotCount) {
      return parseInt(cleanedString.replace(/,/g, ''), 10);
    }
  
    // Si hay más puntos que comas, se asume que los puntos son separadores de miles
    if (dotCount > commaCount) {
      return parseInt(cleanedString.replace(/\./g, ''), 10);
    }
  
    // Si no hay puntos ni comas, convierte directamente a entero
    return parseInt(cleanedString, 10);
  }
  guardar() {
    Swal.fire({
      title: '¿Estás seguro que deseas guardar esta consignacion?',
      text: 'La diferencia presentada es de ' + this.diferencia.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }) + ' Una vez realizada no podra cambiarse',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        this.factura.map(w => {
          const imagen = this.imagesWData.find(x => x.id == w.response.file_id)
          const img = new Image()
          img.src = imagen.base64
          img.onload = () => {
            console.log(img.width, img.height);
            // Reducir la imagen
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = 512; // Ancho deseado
            canvas.height = 512; // Alto deseado
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            this.reducedImage = canvas.toDataURL();
            console.log(this.reducedImage)
            if (w.response.HORA==null){
              this.horaLeida="00:00"
            }else{
              this.horaLeida=w.response.HORA
            }
            const dataf = {

              idPais: "169",
              idAlmacen: sessionStorage.getItem('tienda').toString(),
              fecha: w.response.FECHA.replace(/-/g, ''),
              hora: w.response.FECHA.replace(/-/g, '') + ' ' + this.horaLeida,
              usuario: sessionStorage.getItem('responsable').toString(),
              valor: w.response.VALOR.replace(/\$|,/g, ''),
              referencia: w.response.REFERENCIA,
              banco: w.response.BANCO,
              codigo: w.response.CODIGO,
              convenio: w.response.CONVENIO,
              comprobante: this.reducedImage,
              diferencia: this.diferencia,
              observaciones: this.comentarioDetalle,
              fechaComparacionInicial:this.datosComparacion.fechaInicial,
              fechaComparacionFinal:this.datosComparacion.fechaFinal,
              ValorPOS:this.suma
            }
            if (this.comentarioDetalle.trim() == "" ) {
              if( this.diferencia >= -90 && this.diferencia <= 90){
                this.service.saveConsignacion(dataf).then(res => {
                  console.log(res)
  
  
                  if (res.codigo == "0") {
                    Swal.fire('Guardado Con Exito', 'Se guardo con exito la consignacion', 'success')
                    this.pedirComentario = false
                    this.mostrarGuardar = false
                  } else {
                    Swal.fire('Error', res.description + '. ' + 'Error ' + res.codigo, 'error')
                  }
                })
              }else{
                Swal.fire('Comentario No Valido', 'Porfavor Escriba Un Comentario Valido', 'error')
              }
              
            }else{
              this.service.saveConsignacion(dataf).then(res => {
                console.log(res)


                if (res.codigo == "0") {
                  Swal.fire('Guardado Con Exito', 'Se guardo con exito la consignacion', 'success')
                  this.pedirComentario = false
                  this.mostrarGuardar = false
                } else {
                  Swal.fire('Error', res.description + '. ' + 'Error ' + res.codigo, 'error')
                }
              })
            }
          };




        })


      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // El usuario hizo clic en "No, cancelar"
      }
    });
  }
}



