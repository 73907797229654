import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router';
import { InfoTiendaData } from '../../mock/tiendas'
import { ExcelService } from 'app/services/excelservice';
import { ServiceService } from 'app/services/service.service';
import { locale as en } from './i18n/en'
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { NgbDateStruct, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { CoreTranslationService } from '@core/services/translation.service'
import { DiasMes } from "../../mock/diasmes";


@Component({
  selector: 'app-sample',
  templateUrl: './sample.component.html',
  styleUrls: ['./sample.component.scss']
})
export class SampleComponent implements OnInit {
  public fromDate: any;
  public toDate: any;
  public contentHeader: object
  public selectMulti: Observable<any[]>;
  public hoveredDate: NgbDate | null = null;
  public loading = false;
  public pageBasic = 1;
  public page = 1;
  public pageSize = 15;
  public pageSize1 = 15;
  presupuestomes: any = 0;
  model: NgbDateStruct;
  ventastotaldia: number = 0;
  ventastotalmes: any = 0;
  porcentajedia: number = 0;
  porcentajemes: number = 0;
  show: number = 0;
  show1: number = 0;
  porcentajefaltantedia: number = 0;
  porcentajefaltantemes: number = 0;

  presupuestomenosventasdia: number = 0;
  presupuestomenosventasmes: number = 0;
  mesames: any;
  nombretienda: string;
  cumplimientohistorico: any;
  cantidadVentasDia: any = 0;
  ventasFisicasActualDia: any = 0;
  ventasVirtualesActualDia: number = 0;
  cantidadVentasMesActual: any = 0;
  ventasFisicasMesActual: any = 0;
  ventasVirtualesMesActual: any = 0;
  devoluciones: any;
  ventasFisicasMesActual1: number = 0;
  ventasFisicasActualDia1: number = 0;
  devolucionesActualDia: number = 0;
  devolucionesMesActual: number = 0;
  cantidadVentasDigitaldia: number = 0;
  cantidadVentasDigitalMes: number = 0;
  presupuestoActualDigitalDia: number = 0;
  presupuestoDigitalMesActual: number = 0;
  porcentajeDigitaldia: number = 0;
  porcentajeDigitalmes: number = 0;
  cantidadArticulosTotalesDia: number = 0;
  cantidadArticulosTotalesMesActual: any = 0;
  cantidadTransaccionesMesActual: number = 0;
  cantidadTransaccionesDia: number = 0;
  ticketpromediodia: number = 0;
  ticketpromediomes: number = 0;
  articulosPorTicketDia: number = 0;
  articulosPorTicketMes: number = 0;
  totalCreditoKaiowaMesActual: number = 0;
  totalCreditoKaiowaDiaActual: number = 0;
  presupuestoCreditoKaiowaDia: number = 0;
  presupuestoKaiowaMesActual: number = 0;
  porcentajeDiaKaiowa: number = 0;
  porcentajeMesKaiowa: number = 0;

  formatos = [];
  tiendas = [];
  agencias = [];
  ciudades = [];
  responsables: any[];
  agencia: any = {};
  pendientekaiowa: number;
  pendientedigital: number;
  vpt: number = 0;
  upt: number = 0;
  pp: number = 0;
  vptsupt: number = 0;
  fechainicioStr: string;
  idTienda: number;
  fechainicio: Date;
  fechainidash: string;
  diadia: any;
  totalventas: number = 0;
  totalpresup: number = 0;
  totalporcent: number = 0;
  totalpendiente: number = 0;
  fechafin: Date;
  mostrarfiltros: boolean = true;
  fechafinStr: string;
  fechafindash: string;
  prespuestoentrefechas: number = 0;
  totalventasentrefechas: number = 0;
  porcentajeentrefechas: number = 0;
  porcentajefaltanteentrefechas: number = 0;
  presupuestomenosventasentrefechas: number = 0;
  mostrarfiltrosi: boolean = false;

  ventadigital: number = 0;
  ventafisica: number = 0;

  nombre: string = 'Faltante';
  totalDevoluciones: number = 0;
  ventafisica1: number = 0;
  cantidadVentas: number = 0;
  cantidadTransacciones: number = 0;
  cantidadArticulosTotales: number = 0;
  ticketpromedio: number = 0;
  articulosporticket: number = 0;
  totalCreditoKaiowa: number = 0;
  presupuestoCreditoKaiowa: number = 0;
  cumplimientoKaiowa: number = 0;
  presupuestoDigital: number = 0;
  cumplimientoRdl: number = 0;
  totaltotal: number;
  totaltotalventas: number = 0;
  totalpresupuesto: number = 0;
  totalcumplimiento: number = 0;
  totalpendiente1: number = 0;
  presupuestoFisico: number = 0;
  pendientedig: number = 0;
  ventassobretransacciones: number = 0;
  cumplimientovpt: number = 0;
  articulossobretransacciones: number = 0;
  precioprom: number = 0;
  vptsobreupt: number = 0;
  pendientecrkaiowa: number = 0;
  participacionfisico: number = 0;
  participaciondigital: number = 0;
  participaciontotal: number = 0;
  diar: any;
  codigoTienda: any = null;
  agenciaTienda: any;
  formatoTienda: any = null;
  vista: any = "Ar";
  ciudad: any = null;
  difecha: any;
  dif: number;
  // arregloAgencia: void;
  color: string;
  colorPf: boolean;
  colorCf: boolean;
  colorPd: boolean;
  colorPt: boolean;
  colorCd: boolean;
  colorCt: string;
  colorPvpt: boolean;
  colorPupt: boolean;
  colorPpp: boolean;
  colorPtc: boolean;

  colorPent: boolean;
  colorPkai: boolean;
  colorCvpt: boolean;
  colorCupt: boolean;
  colorCpp: boolean;
  colorCtc: boolean;
  colorCent: boolean;
  colorCkai: boolean;

  infoTiendaFiltrada: any;
  rol: string;
  mostrarCampo: boolean = true;

  coordinador: string;
  infoTiendaFiltradaAge: any;

  filtroAplicados: string[] = [];
  colorPfi: boolean;
  colorPdi: boolean;
  colorPtot: boolean;
  colorTrans: boolean;
  mostrarsincronizacion: boolean;
  mostrarVentas: boolean;
  mostrarIndicadores: boolean;
  mostrarPotenciales: boolean;
  colorTransTotal: string;
  colorTransDia: string;
  colorTransss: string;
  mostrarDetalleVentasFisicos: boolean = false;
  mostrarDetalleVentasDigitaless: boolean = false;
  mostrarDetalleVentasTotal: boolean = false;
  mostrarDetalleVentasCreditos: boolean = false;
  mostrarDetallesVPTs: boolean = false;
  mostrarDetallesUPTs: boolean = false;
  mostrarDetallesPPs: boolean = false;
  mostrarDetallesTCs: boolean = false;
  mostrarDetallesEntrada: boolean = false;
  flecharoja: boolean;
  flechaRojacDig: boolean;
  flecharojacfis: boolean;
  flechaRojaPdig: boolean;
  flecharojaPt: boolean;
  flechaRojaPfi: boolean;
  flechaRojaPdi: boolean;
  flechaRojaPvpt: boolean;
  flechaRojaCvpt: boolean;
  flechaRojaPupt: boolean;
  flechaRojaCupt: boolean;
  flechaRojaPpp: boolean;
  flechaRojaCpp: boolean;
  flechaRojaPtc: boolean;
  flechaRojaCtc: boolean;
  flechaRojaPent: boolean;
  flechaRojaCent: boolean;
  flechaRojaPkai: boolean;
  flechaRojaCkai: boolean;
  flecaRojaPtot: boolean;
  deshabilitado: boolean = false;
  flechaRojaTra: boolean;
  colorcTrans: boolean;
  flechaRojacTrans: boolean;
  desdehasta: string;
  idpv: any;
  resultado: any;
  metas: any;
  longitud: any;
  sumaVentasFisicas: number;
  sumaVentasDigitales: number;
  sumaUnidades: number;
  sumaTransacciones: number;
  sumaEntradas: number;
  sumaVentasTotales: number;
  aparecetotal=false;
  sumaPptoFisico: number;
  sumaPptoDigital: number;
  sumaPptoTotal: number;
  sumaCumplimientoFisico: number;
  sumaCumplimientoTotal: number;
  sumaCumplimientoDigital: number;
  sumaVPT: number;
  sumaUPT: number;
  sumaTC: number;
  ultSincro: any;
  fechaSincronizacion: any;
  datosConsulta: any;
  fechainicioStr1: string;
  fechafinStr1: string;
  key: string;
  resulta: any;
  evento: any;
  apertura: any;
  cierre: any;
  hora: any;
  idTerminal: any;
  nombreTerminal: any;
  idTerminall: any;
  nombreTerminall: any;
  seleccionada: any;
  longitud1: any;
  fechaSincronizacionCam: any;
  sumaPptocK: number;
  sumaCK: number;
  excepciones: string[];
  sumanumcr: number;
  sumapptonumcr: number;
  sumaPptoTransacciones: number;
  sumaCumpTransacciones: number;
  partDigitalTotal: number;
  /**
   *
   * @param {CoreTranslationService} _coreTranslationService
   * @param {NgbModal} modalService
   */
  constructor(private diamess:DiasMes,private modalService:NgbModal,private _coreTranslationService: CoreTranslationService, private calendar: NgbCalendar, public excelService: ExcelService, private router: Router, public formatter: NgbDateParserFormatter, private inforTiendas: InfoTiendaData, private service: ServiceService) {
    this._coreTranslationService.translate(en)
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
   modalOpen(modalBasic,item) {
    
    this.modalService.open(modalBasic, {
      windowClass: 'modal'
    });
  }
  onDateSelection(date: NgbDate, datepicker) {

    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate) {
      this.toDate = date;
      datepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
    this.fechainicio = new Date(this.fechainicioStr + 'T00:00');
    this.fechafin = new Date(this.fechafinStr + 'T00:00');
    if (this.fechafin.getTime() >= this.fechainicio.getTime()) {
      this.difecha = (this.fechainicio.getTime() - this.fechafin.getTime()) + (1);
      this.dif = (this.difecha / (1000 * 3600 * 24)) * (-1);
      console.log("evento:" + event);
      if (this.dif <= 0) {
        this.vista = 'Ar'
      } else {
        this.vista = 'Ac'
      }
      return this.vista;
    } else {

      window.alert("seleccione un rango de fechas valido");

    }
  }
  seleccionarHoy() {
    this.fromDate = this.calendar.getToday()
    this.toDate = this.calendar.getToday()
    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
  }
  seleccionarMesActual(){
    var ano:any
    var mes:any
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    var mesActual=this.calendar.getToday().month
    this.diamess.mes.map(x=>{
      if (x.Mes==mesActual.toString().padStart(2, "0")){
        this.fechainicioStr =this.fromDate.year.toString() +"-" +this.fromDate.month.toString().padStart(2, "0") +"-" +x.diaInicio;
        this.fechafinStr =this.fromDate.year.toString() +"-" +this.fromDate.month.toString().padStart(2, "0") +"-" +x.diaFin;
        ano=(this.fromDate.year).toString()
          mes=(mesActual).toString().padStart(2, "0")
          this.fromDate={"day":parseInt(x.diaInicio),"month":parseInt(mes),"year":parseInt(ano)}
          this.toDate={"day":parseInt(x.diaFin),"month":parseInt(mes),"year":parseInt(ano)}
      }
    })
    this.desdehasta =" Desde " + this.fechainicioStr + " hasta " + this.fechafinStr;
  }
  seleccionarAnterior(){
   
    var ano:any
    var mes:any
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    var mesActual=this.calendar.getToday().month
    if (mesActual.toString().padStart(2, "0")=="01"){
      mesActual=13
    }
    this.diamess.mes.map(x=>{
      if (x.Mes==(mesActual-1).toString().padStart(2, "0")){
        if (mesActual==13){
          this.fechainicioStr =(this.fromDate.year-1).toString() +"-" +(mesActual-1).toString().padStart(2, "0") +"-" +x.diaInicio;
          this.fechafinStr =(this.fromDate.year-1).toString() +"-" +(mesActual-1).toString().padStart(2, "0") +"-" +x.diaFin;
          ano=(this.fromDate.year-1).toString()
          mes=(mesActual-1).toString().padStart(2, "0")
          this.fromDate={"day":parseInt(x.diaInicio),"month":parseInt(mes),"year":parseInt(ano)}
          this.toDate={"day":parseInt(x.diaFin),"month":parseInt(mes),"year":parseInt(ano)}

        }else{
          this.fechainicioStr =this.fromDate.year.toString() +"-" +(mesActual-1).toString().padStart(2, "0") +"-" +x.diaInicio;
          this.fechafinStr =this.toDate.year.toString() +"-" +(mesActual-1).toString().padStart(2, "0") +"-" +x.diaFin;
          ano=this.toDate.year.toString()
          mes=(mesActual-1).toString().padStart(2, "0")
          this.fromDate={"day":parseInt(x.diaInicio),"month":parseInt(mes),"year":parseInt(ano)}
          this.toDate={"day":parseInt(x.diaFin),"month":parseInt(mes),"year":parseInt(ano)}
          
        }
      
      }
    })
    
    this.desdehasta =" Desde " + this.fechainicioStr + " hasta " + this.fechafinStr;
  }
  seleccionarAyer() {
    this.fromDate = this.calendar.getNext(this.calendar.getToday(), 'd', -1);
    this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', -1);
    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
  }

  mostrarVentass() {
    this.mostrarVentas = true
    this.mostrarIndicadores = false
    this.mostrarPotenciales = false
  }
  mostrarIndicadoress() {
    this.mostrarVentas = false
    this.mostrarIndicadores = true
    this.mostrarPotenciales = false
  }
  mostrarPotencialess() {
    this.mostrarVentas = false
    this.mostrarIndicadores = false
    this.mostrarPotenciales = true
  }

  consultar() {
    this.loading = true;
    if (this.codigoTienda != null) {
      var dataEnviar = {
        fechaInicio: this.fechainicioStr,
        fechaFin: this.fechafinStr,
        tiendas: this.codigoTienda.map(r => r.Codigo)
      }
    } else {
      var dataEnviar = {
        fechaInicio: this.fechainicioStr,
        fechaFin: this.fechafinStr,
        tiendas: this.infoTiendaFiltrada.map(r => r.CodigoWinPOS)
      }

    }
    console.log(this.codigoTienda);


    this.service.ObtenerTasaDeConversionAcumuladaDiaria(dataEnviar).then(result => {
      console.log(result)
      this.resultado = result.map(x => {
        this.aparecetotal=true
        this.loading = false;
        // this.longitud = this.resultado.length;
        this.fechaSincronizacion=x.fechaSincronizacion
        if (x.numeroEntradasMes == null) {
          x.numeroEntradasMes = 0
        }
        x.idAlmacen=x.idAlmacen.toString()
        if(x.idAlmacen.length==3){
          x.idAlmacen="0"+x.idAlmacen
        }
        this.fechaSincronizacionCam=x.fechaSincronizacionCam
        if((x.idAlmacen=="0678") || (x.idAlmacen=="0679")){
          x.agencia="51"
        }
        
        x['fecha'] = x.dia.toString() + '/' + x.mes.toString() + '/' + x.anio.toString();
        x["totalVentasFisicass"] = parseFloat(x.totalVentasFisicas) - parseFloat(x.totalDevoluciones);
        x["totalVentas"] = parseFloat(x["totalVentasFisicass"]) + parseFloat(x.totalVentasVirtuales);
        x["partDig"] = (parseFloat(x.totalVentasVirtuales)*100)/parseFloat(x["totalVentas"]) ;
        x['presupuestoTotal'] = parseFloat(x.presupuestoFisico) + parseFloat(x.presupuestoDigital);
        x['cumplimientoFisico'] = parseFloat(x.totalVentasFisicas) * 100 / parseFloat(x.presupuestoFisico);
        x['cumplimientoDigital'] = parseFloat(x.totalVentasVirtuales) * 100 / parseFloat(x.presupuestoDigital);
        x['cumplimientoTotal'] = parseFloat(x["totalVentas"]) * 100 / parseFloat(x['presupuestoTotal']);
        x['totalUnidades'] = parseFloat(x.cantidadArticulosFisicos) + parseFloat(x.cantidadArticulosDigitales);
        x['vpt'] = parseFloat(x["totalVentas"]) / parseFloat(x.numeroTransaccionesMes);
        x['upt'] = parseFloat(x['totalUnidades']) / parseFloat(x.numeroTransaccionesMes);
        x["tC"] = (parseFloat(x.numeroTransaccionesMes) * 100) / (parseFloat(x.numeroEntradasMes));
        if (x["tC"] == Infinity) {
          x["tC"] = 0
        }
        x['cumplimientoTransacciones']=parseFloat(x.numeroTransaccionesMes)*100/parseFloat(x.PptoTrans)
        


        return x;
      })
      this.longitud = this.resultado.length;
      this.longitud1=this.resultado.length;
      this.getSum()
    })

  }

  

  ngOnInit() {
    this.excepciones = ["Julian Alberto Navarro Garcia","Leydi Milena Peña Sepulveda","Mariana Ossa Sanchez", "Juan Carlos Gallego Elejalde", "Melissa Castrillon Cano", "Jose Mario Tamayo Lopera", "Juan Carlos Maya Arbelaez","Carlos Ignacio Urrea", "Mauricio Andres Cardona Suarez", "Ana Lucia Hernandez Santa", "Alejandro Gonzalez Vasquez", "Maria Camila Cardona Arroyave", "Maria Cristina Londoño Cruz", "David  Gomez Patiño", "Soraya  Bedoya Mejia", "Margarita Maria Mazo Franco", "Luis Guillermo Maya Arbelaez","Sergio Alexander Villa Cano"];
    this.aparecetotal=false
    this.contentHeader = {
      headerTitle: 'Home',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'Dia',
            isLink: false
          }
        ]
      }
    }
    this.rol = sessionStorage.getItem('rol');
    // var d = new Date();
    // var year = d.getFullYear();
    // var month = d.getMonth() + 1
    // var day = d.getDate();
    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    this.fechainicio = new Date(this.fechainicioStr + 'T00:00');
    this.fechafin = new Date(this.fechafinStr + 'T00:00');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
    this.coordinador = sessionStorage.getItem('responsable');
    this.mostrarVentas = false
    this.mostrarIndicadores = false
    this.mostrarPotenciales = false
    this.codigoTienda = null;
    this.agenciaTienda = null;
    this.formatoTienda = null;
    this.ciudad = null;
    if (this.rol == 'null' || this.rol == undefined) {
      this.codigoTienda = parseInt(sessionStorage.getItem('idTienda'));
      this.mostrarCampo = false;
      this.mostrarfiltros = false;

    }
    this.nombretienda = sessionStorage.getItem('tienda');
    if (this.nombretienda == null) {
      this.router.navigate(['../pages/authentication/auth-login-v2/auth-login-v2.component.html'])
      return
    }
    
    if (this.rol == "COORDINADOR" && !this.excepciones.join(',').includes(this.coordinador)) {
      this.inforTiendas.infoTienda = this.inforTiendas.infoTiendaAll.filter(x => x.Responsable == this.coordinador);
      this.agenciaTienda = this.inforTiendas.infoTienda[0].Regional;
      if(this.agenciaTienda!='02'){
        this.deshabilitado = true;
      }

    }
    else if (this.rol == "GERENTE" && !this.excepciones.join(',').includes(this.coordinador)) {
      this.inforTiendas.infoTienda = this.inforTiendas.infoTiendaAll.filter(x => x.Gerente == this.coordinador);
      this.agenciaTienda = this.inforTiendas.infoTienda[0].Regional;
      if(this.agenciaTienda!='02'){
        this.deshabilitado = true;
      }

    }
    else
      this.inforTiendas.infoTienda = this.inforTiendas.infoTiendaAll;
    
    if (this.rol == "GERENTE" && !this.excepciones.join(',').includes(this.coordinador)) {
      this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
      this.filterGer()
    }
    else
      if (this.rol == "COORDINADOR" && !this.excepciones.join(',').includes(this.coordinador)) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        this.filterResp()

      } else {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        this.fillFilter(this.inforTiendas.infoTienda);
      }
  }
  private fillFilter(infotienda: any) {


    var agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia }; });
    var arregloAgencia = [];
    agencia.map((a) => {
      if (arregloAgencia.filter(p => p.codigoAgencia == a.codigoAgencia).length == 0)

        arregloAgencia.push(a);
    });
    console.log(arregloAgencia);


    this.agencias = [...new Set(arregloAgencia)];
    this.formatos = [...new Set(infotienda.map(x => x.Formato))];
    this.ciudades = [...new Set(infotienda.map(x => x.CIUDAD))];
    this.responsables = [...new Set(infotienda.map(x => x.Responsable))];
    this.tiendas = [...new Set(infotienda.map(x => { return { Nombre: x.NOMBRE, Codigo: x.CodigoWinPOS }; }))];
    this.vista = this.vista;

  }
  filterResp() {
    this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
      return p.Responsable.toString().toLowerCase() == this.coordinador.toLowerCase();
    })
    this.fillFilter(this.infoTiendaFiltrada);
  }

  filterGer() {
    this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
      return p.Gerente == this.coordinador;
    })
    this.fillFilter(this.infoTiendaFiltrada);
  }
  filterPV(event) {
    console.log("evento:" + event);
    if (this.ciudad != "null") {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return this.ciudad.filter(d => p.CIUDAD == d).length > 0;
      })
    } else {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return p.CIUDAD != '';
      })
    }

    this.fillFilter(this.infoTiendaFiltrada);
    this.filtroAplicados.push("ciudad");
  }
  onChange(event) {

    this.fechainicio = new Date(this.fechainicioStr + 'T00:00');
    this.fechafin = new Date(this.fechafinStr + 'T00:00');
    if (this.fechafin.getTime() >= this.fechainicio.getTime()) {
      this.difecha = (this.fechainicio.getTime() - this.fechafin.getTime()) + (1);
      this.dif = (this.difecha / (1000 * 3600 * 24)) * (-1);
      console.log("evento:" + event);
      if (this.dif <= 0) {
        this.vista = 'Ar'
      } else {
        this.vista = 'Ac'
      }
      return this.vista;
    } else {

      window.alert("seleccione un rango de fechas valido");

    }
  }
  filterTiend(event) {
    if (this.codigoTienda != "null") {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return this.codigoTienda.filter(d => p.codigo == d).length > 0;
      })
    } else {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return p.Regional != '';
      })
    }
    this.fillFilter(this.infoTiendaFiltrada);
  }

  filterAge(event) {
    this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
    console.log("evento:" + event);
    if (this.agenciaTienda != "null") {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia).length > 0;
      })
    } else {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return p.Regional != '';
      })
    }

    this.fillFilter(this.infoTiendaFiltrada);
    this.filtroAplicados.push("Agencia");
  }



  filterFormat(event) {

    console.log("evento:" + event);
    if (this.formatoTienda != "null") {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return this.formatoTienda.filter(d => p.Formato == d).length > 0;
      });
    } else {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return p.Formato != '';
      })
    }
    this.fillFilter(this.infoTiendaFiltrada);
    this.filtroAplicados.push("Formato");

  }
  limpiarFiltFor(infotienda: any = this.inforTiendas.infoTienda) {
    
    var arregloAgencia = [];
    if (this.rol == "GERENTE") {
      var agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia,Gerente:x.Gerente }; });

      agencia.map((a) => {
        if (arregloAgencia.filter(p => p.codigoAgencia == a.codigoAgencia && p.Gerente == this.coordinador).length == 0)
          arregloAgencia.push(a);
      });
      console.log(arregloAgencia);
      this.filtroAplicados = this.filtroAplicados.filter(p => p != "Formato");
      this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
      if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {
        this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia && p.Gerente == this.coordinador).length > 0} );
        this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
        this.formatoTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }

      if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

        this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {return this.ciudad.filter(d => p.CIUDAD == d && p.Gerente == this.coordinador).length > 0 });
        this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
        this.formatoTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }
      if (this.filtroAplicados.length == 0) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        this.formatos = [...new Set(infotienda.map(x => x.Formato))];
        this.formatoTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }
    } else {
      if (this.rol == "COORDINADOR" && !this.excepciones.join(',').includes(this.coordinador)) {
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "Formato");
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {
          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia && p.Responsable == this.coordinador).length > 0});
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {return this.ciudad.filter(d => p.CIUDAD == d && p.Responsable == this.coordinador).length > 0 } );
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
          this.formatos = [...new Set(infotienda.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
      } else {
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "Formato");
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {
          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia).length > 0;});
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {return this.ciudad.filter(d => p.CIUDAD == d).length > 0});
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
          this.formatos = [...new Set(infotienda.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
      }

    }
  }

  mostrarFiltross() {
    if (this.mostrarfiltros == true && this.mostrarfiltrosi == false) {
      this.mostrarfiltros = false
      this.mostrarfiltrosi = true
    } else {
      this.mostrarfiltros = true
      this.mostrarfiltrosi = false
    }

  }
  limpiarFiltAgen(infotienda: any = this.inforTiendas.infoTienda) {
    
    let arregloAgencia = [];

    if (this.rol == "GERENTE") {
      let agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia }; });


      agencia.forEach(a => {
        if (arregloAgencia.filter(b => b.codigoAgencia.trim() == a.codigoAgencia.trim()).length < 1) {
          arregloAgencia.push(a);
        }
      });

      console.log(arregloAgencia);
      this.filtroAplicados = this.filtroAplicados.filter(p => p != "Agencia");
      if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

        this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => p.CIUDAD == this.ciudad && p.Gerente == this.coordinador);
        this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
        this.fillFilter(this.infoTiendaFiltrada);
      }

      if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

        this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => p.Formato == this.formatoTienda && p.Gerente == this.coordinador);
        this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
        this.fillFilter(this.infoTiendaFiltrada);
      }
      if (this.filtroAplicados.length == 0) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda
        this.agenciaTienda = null;
        this.agencias = [...new Set(arregloAgencia)];
        this.fillFilter(this.infoTiendaFiltrada);
      }
    } else {
      if (this.rol == "COORDINADOR" && !this.excepciones.join(',').includes(this.coordinador)) {
        let agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia }; });


        agencia.forEach(a => {
          if (arregloAgencia.filter(b => b.codigoAgencia.trim() == a.codigoAgencia.trim()).length < 1) {
            arregloAgencia.push(a);
          }
        });
        console.log(arregloAgencia);
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "Agencia");
        if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

          this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => p.CIUDAD == this.ciudad && p.Responsable == this.coordinador);
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

          this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => p.Formato == this.formatoTienda && p.Responsable == this.coordinador);
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda
          this.agenciaTienda = null;
          this.agencias = [...new Set(arregloAgencia)];
          this.fillFilter(this.infoTiendaFiltrada);
        }
      } else {
        var agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia }; });

        agencia.map((a) => {
          if (arregloAgencia.filter(p => p.codigoAgencia == a.codigoAgencia).length == 0)
            arregloAgencia.push(a);
        });
        console.log(arregloAgencia);
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "Agencia");
        if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

          this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => {return this.ciudad.filter(d => p.CIUDAD == d).length > 0});
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

          this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => {return this.formatoTienda.filter(d => p.Formato == d).length > 0});
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if(this,this.filtroAplicados.length==0){
          this.infoTiendaFiltrada=this.inforTiendas.infoTienda
          this.agenciaTienda = null;
          this.agencias = [...new Set(arregloAgencia)];
          this.fillFilter(this.infoTiendaFiltrada);
        }
      }
    }
  }

  limpiarFiltCiu(infotienda: any = this.inforTiendas.infoTienda) {
    
    if (this.rol == "GERENTE") {
      var agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia, Gerente:x.Gerente}; });
      var arregloAgencia = [];
      agencia.map((a) => {
        if (arregloAgencia.filter(p => p.codigoAgencia == a.codigoAgencia && p.Gerente == this.coordinador).length == 0)
          arregloAgencia.push(a);
      });
      console.log(arregloAgencia);
      this.filtroAplicados = this.filtroAplicados.filter(p => p != "ciudad");
      this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
      if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {

        this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia && p.Gerente == this.coordinador).length > 0});
        this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
        this.ciudad = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }

      if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

        this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {return this.formatoTienda.filter(d => p.Formato == d && p.Gerente == this.coordinador).length > 0} );
        this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
        this.ciudad = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }
      if (this.filtroAplicados.length == 0) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda
        this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
        this.ciudad = null;
        this.fillFilter(this.infoTiendaFiltrada);

      }
    } else {
      if (this.rol == "COORDINADOR" && !this.excepciones.join(',').includes(this.coordinador)) {
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "ciudad");
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia  && p.Responsable == this.coordinador).length > 0});
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {return this.formatoTienda.filter(d => p.Formato == d && p.Responsable == this.coordinador).length > 0});
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);

        }
      } else {
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "ciudad");
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia).length > 0;});
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {return this.formatoTienda.filter(d => p.Formato == d).length > 0});
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);

        }
      }
    }
  }
  limpiarFiltPv() {
    this.codigoTienda = null;
  }

  mostrarDetallesdigital() {
    if (this.show1 == 0) {
      this.show1 = 1;
    } else {
      this.show1 = 0;
    }
  }
  mostrarDetalleVentasFisico() {
    if (this.mostrarDetalleVentasFisicos == false) {
      this.mostrarDetalleVentasFisicos = true
      this.mostrarDetalleVentasDigitaless = false
      this.mostrarDetalleVentasTotal = false
      this.mostrarDetalleVentasCreditos = false
    } else {
      this.mostrarDetalleVentasFisicos = false
    }
  }
  mostrarDetalleVentasDigitales() {
    if (this.mostrarDetalleVentasDigitaless == false) {
      this.mostrarDetalleVentasFisicos = false
      this.mostrarDetalleVentasDigitaless = true
      this.mostrarDetalleVentasTotal = false
      this.mostrarDetalleVentasCreditos = false
    } else {
      this.mostrarDetalleVentasDigitaless = false
    }
  }
  mostrarDetalleVentasTotales() {
    if (this.mostrarDetalleVentasTotal == false) {
      this.mostrarDetalleVentasTotal = true
      this.mostrarDetalleVentasFisicos = false
      this.mostrarDetalleVentasDigitaless = false
      this.mostrarDetalleVentasCreditos = false
    } else {
      this.mostrarDetalleVentasTotal = false
    }
  }
  mostrarDetalleVentasCredito() {
    if (this.mostrarDetalleVentasCreditos == false) {
      this.mostrarDetalleVentasCreditos = true
      this.mostrarDetalleVentasFisicos = false
      this.mostrarDetalleVentasDigitaless = false
      this.mostrarDetalleVentasTotal = false
    } else {
      this.mostrarDetalleVentasCreditos = false
    }
  }




  mostrarDetallesVPT() {
    if (this.mostrarDetallesVPTs == false) {
      this.mostrarDetallesVPTs = true
      this.mostrarDetallesUPTs = false
      this.mostrarDetallesPPs = false
      this.mostrarDetallesTCs = false
      this.mostrarDetallesEntrada == false
    } else {
      this.mostrarDetallesVPTs = false
    }
  }
  mostrarDetallesUPT() {
    if (this.mostrarDetallesUPTs == false) {
      this.mostrarDetallesUPTs = true
      this.mostrarDetallesVPTs = false
      this.mostrarDetallesPPs = false
      this.mostrarDetallesTCs = false
      this.mostrarDetallesEntrada == false
    } else {
      this.mostrarDetallesUPTs = false
    }
  }
  mostrarDetallesPP() {
    if (this.mostrarDetallesPPs == false) {
      this.mostrarDetallesPPs = true
      this.mostrarDetallesUPTs = false
      this.mostrarDetallesVPTs = false
      this.mostrarDetallesTCs = false
      this.mostrarDetallesEntrada == false
    } else {
      this.mostrarDetallesPPs = false
    }
  }
  mostrarDetallesTC() {
    if (this.mostrarDetallesTCs == false) {
      this.mostrarDetallesTCs = true
      this.mostrarDetallesVPTs = false
      this.mostrarDetallesUPTs = false
      this.mostrarDetallesPPs = false
      this.mostrarDetallesEntrada == false
    } else {
      this.mostrarDetallesTCs = false
    }
  }
  mostrarDetallesEntradas() {
    if (this.mostrarDetallesEntrada == false) {
      this.mostrarDetallesEntrada = true
      this.mostrarDetallesTCs = false
      this.mostrarDetallesVPTs = false
      this.mostrarDetallesUPTs = false
      this.mostrarDetallesPPs = false
    } else {
      this.mostrarDetallesEntrada = false
    }
  }
  /**
   * Is Hovered
   *
   * @param date
   */
  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  /**
   * Is Inside
   *
   * @param date
   */
  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  /**
   *  Is Range
   *
   * @param date
   */
  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }


  enviarexceventas() {
    const datos =this.resultado.map(element =>{ return {
      IdAlmacen:element.agencia.toString()+element.idAlmacen.toString(),
      Nombre:element.Nombre,
      Dia:element.nombreDelDiaSemanaAnio,
      Fecha:element.fecha,
      VentaFisica:element.totalVentasFisicass,
      VentaDigital:element.totalVentasVirtuales, 
      VentaTotal:element.totalVentas,
      PptoFisico:element.presupuestoFisico,
      PptoDigital:element.presupuestoDigital,
      PptoTotal:element.presupuestoTotal,
      CumplimientoFisico:element.cumplimientoFisico,
      CumplimientoDigital:element.cumplimientoDigital,
      CumplimientoTotal:element.cumplimientoTotal,
      Articulos:element.totalUnidades,
      Transacciones:element.numeroTransaccionesMes,
      PptoTransacciones:element.PptoTrans,
      CumpTransacciones:element.cumplimientoTransacciones,
      VPT:element.vpt,
      PptoVPT:element.PptoVPT,
      UPT:element.upt,
      PptoUPT:element.PptoUPT,
      Entradas:element.numeroEntradasMes,
      TasaConversion:element.tC,
      VentaCredito: element.totalCreditoKaiowa,
      pptoCredito:element.presupuestoKaiowa
      // NroCredito:element.cantidadTransaccionesCreditos,
      // PptoNroCredito:element.PptoCreditoNro

    }});
      
    
    this.excelService.exportAsExcelFile(datos, 'Ventas');

  }
  enviarexceventasPedido() {
    // Filtrar los registros específicos primero
    const registrosEspecificos = this.resultado
        .filter(element => {
            const codigo = element.idAlmacen.toString();
            return ["1846", "1842", "512", "1028","4254"].includes(codigo);
        })
        .map(element => {
            const tienda = this.inforTiendas.infoTiendaAll.find(x => {
                const codigoWinPOS = x.CodigoWinPOS.toString().padStart(4, '0');
                return codigoWinPOS == element.idAlmacen.toString();
            });

            return {
                IdAlmacen: element.agencia.toString() + element.idAlmacen.toString(),
                Nombre: element.Nombre,
                Dia: element.nombreDelDiaSemanaAnio,
                Fecha: element.fecha,
                Ciudad: tienda ? tienda.CIUDAD : "-",
                VentaFisica: element.totalVentasFisicass,
                VentaDigital: element.totalVentasVirtuales,
                VentaTotal: element.totalVentas,
                PptoFisico: element.presupuestoFisico,
                PptoDigital: element.presupuestoDigital,
                PptoTotal: element.presupuestoTotal,
                CumplimientoFisico: element.cumplimientoFisico,
                CumplimientoDigital: element.cumplimientoDigital,
                CumplimientoTotal: element.cumplimientoTotal,
                Articulos: element.totalUnidades,
                Transacciones: element.numeroTransaccionesMes,
                PptoTransacciones: element.PptoTrans,
                CumpTransacciones: element.cumplimientoTransacciones,
                VPT: element.vpt,
                PptoVPT: element.PptoVPT,
                UPT: element.upt,
                PptoUPT: element.PptoUPT,
                Entradas: element.numeroEntradasMes,
                TasaConversion: element.tC,
                VentaCredito: element.totalCreditoKaiowa,
                pptoCredito: element.presupuestoKaiowa,
                agencia: tienda ? tienda.NombreAgencia : "-", // Cambiado al final
                formato: tienda ? tienda.Formato : "-",       // Cambiado al final
                asesor: tienda ? tienda.Responsable : "-",
            };
        });

    // Filtrar la lista principal excluyendo los registros específicos
    const datos = this.resultado
        .filter(element => {
            const codigo = element.idAlmacen.toString();
            return !["1846", "1842", "5912", "1028"].includes(codigo);
        })
        .map(element => {
            const tienda = this.inforTiendas.infoTiendaAll.find(x => {
                const codigoWinPOS = x.CodigoWinPOS.toString().padStart(4, '0');
                return codigoWinPOS == element.idAlmacen.toString();
            });

            return {
                IdAlmacen: element.agencia.toString() + element.idAlmacen.toString(),
                Nombre: element.Nombre,
                Dia: element.nombreDelDiaSemanaAnio,
                Fecha: element.fecha,
                Ciudad: tienda ? tienda.CIUDAD : "-",
                VentaFisica: element.totalVentasFisicass,
                VentaDigital: element.totalVentasVirtuales,
                VentaTotal: element.totalVentas,
                PptoFisico: element.presupuestoFisico,
                PptoDigital: element.presupuestoDigital,
                PptoTotal: element.presupuestoTotal,
                CumplimientoFisico: element.cumplimientoFisico,
                CumplimientoDigital: element.cumplimientoDigital,
                CumplimientoTotal: element.cumplimientoTotal,
                Articulos: element.totalUnidades,
                Transacciones: element.numeroTransaccionesMes,
                PptoTransacciones: element.PptoTrans,
                CumpTransacciones: element.cumplimientoTransacciones,
                VPT: element.vpt,
                PptoVPT: element.PptoVPT,
                UPT: element.upt,
                PptoUPT: element.PptoUPT,
                Entradas: element.numeroEntradasMes,
                TasaConversion: element.tC,
                VentaCredito: element.totalCreditoKaiowa,
                pptoCredito: element.presupuestoKaiowa,
                agencia: tienda ? tienda.NombreAgencia : "-", // Cambiado al final
                formato: tienda ? tienda.Formato : "-",       // Cambiado al final
                asesor: tienda ? tienda.Responsable : "-",
            };
        });

    // Combinar datos y registros específicos
    const datosFinales = [...datos, ...registrosEspecificos];

    // Exportar a Excel
    this.excelService.exportAsExcelFile(datosFinales, 'Ventas');
}

  
  getSum(){
    this.sumaVentasFisicas=0
    this.sumaVentasDigitales=0
    this.sumaVentasTotales=0
    this.partDigitalTotal=0
    this.sumaUnidades=0
    this.sumaTransacciones=0
    this.sumaEntradas=0
    this.sumaPptoFisico=0
    this.sumaPptoDigital=0
    this.sumaPptoTotal=0
    this.sumaCumplimientoFisico=0
    this.sumaCumplimientoDigital=0
    this.sumaCumplimientoTotal=0
    this.sumaPptoTransacciones=0
    this.sumaCumpTransacciones=0
    this.sumaVPT=0
    this.sumaUPT=0
    this.sumaTC=0
    this.sumaCK=0
    this.sumaPptocK=0
    this.sumanumcr=0
    this.sumapptonumcr=0
    this.resultado.forEach(element=>{
      this.sumaVentasFisicas+=element.totalVentasFisicass,
      this.sumaVentasDigitales+=element.totalVentasVirtuales,
      this.sumaVentasTotales+=element.totalVentas,
      this.partDigitalTotal=(this.sumaVentasDigitales*100)/this.sumaVentasTotales,
      this.sumaUnidades+=element.totalUnidades,
      this.sumaTransacciones+=element.numeroTransaccionesMes,
      this.sumaEntradas+=element.numeroEntradasMes,
      this.sumaPptoFisico+=element.presupuestoFisico,
      this.sumaPptoDigital+=element.presupuestoDigital,
      this.sumaPptoTotal+=element.presupuestoTotal,
      this.sumaCumplimientoFisico=(this.sumaVentasFisicas*100)/this.sumaPptoFisico,
      this.sumaCumplimientoDigital=(this.sumaVentasDigitales*100)/this.sumaPptoDigital,
      this.sumaCumplimientoTotal=(this.sumaVentasTotales*100)/this.sumaPptoTotal,
      this.sumaPptoTransacciones+=element.PptoTrans,
      this.sumaCumpTransacciones=(this.sumaTransacciones*100)/this.sumaPptoTransacciones,
      this.sumaVPT=this.sumaVentasTotales/this.sumaTransacciones,
      this.sumaUPT=this.sumaUnidades/this.sumaTransacciones,
      this.sumaTC=(this.sumaTransacciones*100)/this.sumaEntradas
      this.sumaCK+=element.totalCreditoKaiowa,
      this.sumaPptocK+=element.presupuestoKaiowa,
      this.sumanumcr += element.cantidadTransaccionesCreditos,
      this.sumapptonumcr += element.PptoCreditoNro

    });

  }

}
