<div class="content-wrapper container-xxl p-0">

  <div class="content-body">
    <!-- content-header component -->

    <app-content-header [contentHeader]="contentHeader">
    </app-content-header>
    <section id="home-page">
      <section class="range-selection-DP">

        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="col-12">
                <span style="font-weight: bolder;">Fechas</span>
                <div class="row" style="margin-left:1rem;">
                  <form class="form-inline">
                    <div class="form-group">
                      <div class="input-group w-0 ">
                        <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                          [autoClose]="'outside'" [showWeekNumbers]="false"
                          (dateSelect)="onDateSelection($event,datepicker,modalBasic)" [displayMonths]="1"
                          [dayTemplate]="t" outsideDays="visible" [startDate]="fromDate!" tabindex="-1" />
                        <ng-template #t let-date let-currentMonth="currentMonth" let-selected="selected"
                          let-focused="focused">
                          <span class="custom-day" [class.focused]="focused"
                            [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled"
                            [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)"
                            (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                            {{ date.day }}


                          </span>

                        </ng-template>

                      </div>
                    </div>
                    <div class="form-group">
                      <div class="input-group">
                        <input style="display: none;" #dpFromDate class="form-control" placeholder="yyyy-mm-dd"
                          name="dpFromDate" [value]="formatter.format(fromDate)"
                          (input)="fromDate = validateInput(fromDate, dpFromDate.value)" disabled />
                        <div class="input-group fecha">
                          <input class="fecha" type="text" [value]="desdehasta" name="" id="" disabled>
                        </div>
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary feather icon-calendar" (click)="datepicker.toggle()"
                            type="button" rippleEffect></button>

                        </div>
                      </div>
                    </div>
                    <ng-template #modalBasic let-modal>
                      <div class="modal-header">
                        <h4 class="modal-title" id="myModalLabel1"><i data-feather='arrow-left'></i></h4>

                      </div>
                      <div class="modal-body" tabindex="0" ngbAutofocus>

                        <p>
                          Seleccione la fecha de inicio y la fecha fin en el mismo calendario
                        </p>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')"
                          rippleEffect>
                          Aceptar
                        </button>
                      </div>
                    </ng-template>
                    <div class="form-group ml-sm-2" style="display: none;">
                      <div class="input-group">
                        <input #dpToDate class="form-control" placeholder="yyyy-mm-dd" name="dpToDate"
                          [value]="formatter.format(toDate)" disabled />
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary feather " (click)="datepicker.toggle()" type="button"
                            rippleEffect disabled></button>
                        </div>
                      </div>
                    </div>

                  </form>



                  <div class="row">
                    <div class="columnbut">
                      <button class="btn btn-outline-primary btn-sm m-2 float-left" (click)="seleccionarAnterior()"
                        rippleEffect>
                        Seleccionar Mes Anterior
                      </button>
                    </div>
                    <div class="columnbut">
                      <button class="btn btn-outline-primary btn-sm m-2 float-left" (click)="seleccionarMesActual()"
                        rippleEffect>
                        Seleccionar Mes Actual
                      </button>
                    </div>
                    <div class="columnbut">
                      <button class="btn btn-outline-primary btn-sm m-2 float-left" (click)="seleccionarHoy()"
                        rippleEffect>
                        Seleccionar Hoy</button>
                    </div>

                    <div class="columnbut">

                      <button class="btn btn-outline-primary btn-sm m-2 float-left" (click)="seleccionarAyer()"
                        rippleEffect>
                        Seleccionar Ayer</button>

                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="display: inline-flex;">

                <div class="column" style="width:20% ">
                  <div class="row">
                    <span style="font-weight: bolder;">Agencia</span>
                    <div class="columy" style="padding-left: unset;padding-right: unset;">


                      <ng-select [clearSearchOnAdd]="true" [clearable]="false" (blur)="filterAge($event)"
                        [disabled]="deshabilitado" [items]="agencias" [multiple]="true" [closeOnSelect]="false"
                        [searchable]="true" bindLabel="nombreAgencia" placeholder="Seleccione Agencias"
                        [(ngModel)]="agenciaTienda">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label" [value]="item.codigoAgencia"> {{item.nombreAgencia}}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>

                    </div>
                    <div class="columx" style="padding-left: unset;padding-right: unset;">
                      <span *ngIf="!deshabilitado"><button *ngIf="mostrarCampo" type="button"
                          class="btn btn-flat-danger" rippleEffect (click)="limpiarFiltAgen()"
                          title="limpiar filtro">X</button></span>
                    </div>
                  </div>
                </div>
                <div class="column" style="width:20% ">
                  <div class="row">
                    <span style="font-weight: bolder;">Formato</span>
                    <div class="columy" style="padding-left: unset;padding-right: unset;">

                      <ng-select [clearSearchOnAdd]="true" [clearable]="false" (blur)="filterFormat($event)"
                        [items]="formatos" [multiple]="true" [closeOnSelect]="false" [searchable]="true"
                        bindLabel="item" placeholder="Seleccione formatos" [(ngModel)]="formatoTienda">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label" [value]="item"> {{ item }}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>

                    </div>
                    <div class="columx" style="padding-left: unset;padding-right: unset;">
                      <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger" rippleEffect
                        (click)="limpiarFiltFor()" title="limpiar filtro">X</button>
                    </div>
                  </div>
                </div>
                <div class="column" style="width:20% ">
                  <div class="row">
                    <span style="font-weight: bolder;">Ciudad</span>
                    <div class="columy" style="padding-left: unset;padding-right: unset;">

                      <ng-select [clearable]="false" (blur)="filterPV($event)" [items]="ciudades" [multiple]="true"
                        [closeOnSelect]="false" [searchable]="true" [clearSearchOnAdd]="true" bindLabel="item"
                        placeholder="Seleccione ciudad" [(ngModel)]="ciudad">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label" [value]="item"> {{ item }}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>

                    </div>
                    <div class="columx" style="padding-left: unset;padding-right: unset;">
                      <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger" rippleEffect
                        (click)="limpiarFiltCiu()" title="limpiar filtro">X</button>
                    </div>
                  </div>
                </div>
                <div class="column" style="width:40% ">
                  <div class="row">

                    <div class="columy" style="padding-left: unset;padding-right: unset;">
                      <span style="font-weight: bolder;">Punto Venta</span>

                      <ng-select [clearSearchOnAdd]="true" [items]="tiendas" [clearable]="false" [multiple]="true"
                        [closeOnSelect]="false" [searchable]="true" bindLabel="Nombre" placeholder="Seleccione PV"
                        [(ngModel)]="codigoTienda">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label" [value]="item.Codigo"> {{ item.Nombre }}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>


                    </div>
                    <div class="columx" style="padding-left: unset;padding-right: unset;">
                      <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger" rippleEffect
                        (click)="limpiarFiltPv()" title="limpiar filtro">X</button>
                    </div>
                  </div>
                </div>
              </div>
              <button [disabled]="loading" class="btn btn-relief-primary" tabindex="4" rippleEffect
                (click)="buscarDatos()">
                <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>Consultar
              </button>
            </div>
            <div class="card">
              <div class="card-header">
                <h4></h4>
              </div>
              <div class="card-body">
                <!-- app.component.html -->
                <!-- Select para las variables -->
                <select multiple (change)="selectVariable($event.target.value)" class="form-control">
                  <option value="NumeroEntradas">Número de Entradas</option>
                  <option value="numeroTransacciones">Número de Transacciones</option>
                  <option value="totalVentas">Total Ventas</option>
                  <option value="cantidadArticulos">Cantidad de Articulos</option>
                  <option value="AsesorasPorHora">Asesoras Por Hora</option>
                  <option value="numeroTransaccionesCarpa">Número de Transacciones Carpas</option>
                  <option value="tasaConver">Tasa de Conversion</option>
                  <!-- Añade más opciones aquí -->
                </select>

                <div class="row">

                  <div style="margin: 1rem;">
                    <div *ngFor="let item of selectedVariables">
                      <div class="col-sm-12">
                        <span class="badge bg-primary">{{item}}</span>
                      </div>


                    </div>

                  </div>



                </div>
                <div class="row">
                  <div class="col-sm-8">
                    <div class="table-container">
                      <table class="table">
                        <thead>
                          <tr>
                            <th class="fixed-column">Día</th>
                            <th *ngFor="let hora of matriz[0].Horario">{{ hora.Hora }}:00</th>

                          </tr>

                        </thead>
                        <tbody>
                          <ng-container *ngFor="let dia of matriz;let i = index">
                            <tr *ngFor="let variable of selectedVariables">
                              <td class="fixed-column">{{ dia.NombreDia }} - {{ variable }}</td>
                              <td *ngFor="let horario of dia.Horario" class="tooltip-cell" [class.tooltip-cell]="true"
                                [title]="variable === 'totalVentas' ? (variable + ': '+formatCurrency(horario[variable])) : (variable + ': '+horario[variable])"
                                [style.background-color]="getDynamicColor(horario[variable], maxValues[variable], variableColors[variable])">
                                <ng-container *ngIf="variable === 'totalVentas'; else notTotalVentas">{{
                                  formatCurrency(horario[variable]) }}</ng-container>
                                <ng-template #notTotalVentas>{{ horario[variable] }}</ng-template>

                              </td>
                              <!-- <td *ngFor="let variable of selectedVariables">
              {{ totalPorVariable[variable] }}
            </td> -->
                              <!-- <td class="tooltip-cell" [class.tooltip-cell]="true">{{ totalPorDiaYVariable[dia.NombreDia][variable] }}</td> -->
                            </tr>

                          </ng-container>
                          <tr>
                            <th class="fixed-column">Día</th>
                            <th *ngFor="let hora of matriz[0].Horario">{{ hora.Hora }}:00</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="table-container">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let dia of sumaPorDia;let i = index">
                        <tr *ngFor="let variable of selectedVariables">
                          <td *ngFor="let horario of dia.Horario" class="tooltip-cell" [class.tooltip-cell]="true" [title]="variable === 'totalVentas' ? (variable + ': '+formatCurrency(horario[variable])) : (variable + ': '+horario[variable])"
                          [style.background-color]="getDynamicColor(horario[variable], maxValues1[variable], variableColors[variable])">
                            <ng-container *ngIf="variable === 'totalVentas'; else notTotalVentas">{{
                              formatCurrency(horario[variable]) }}</ng-container>
                            <ng-template #notTotalVentas>{{ horario[variable] }}</ng-template>
                          </td>
                        </tr>
                      </ng-container>
                      </tbody>
                    </table>
                  </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="table-container">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Ppart</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let dia of sumaPorDia;let i = index">
                        <tr *ngFor="let variable of selectedVariables">
                          <td *ngFor="let horario of dia.Horario" 
                          [style.background-color]="getDynamicColor(horario[variable], maxValues1[variable], variableColors[variable])">
                            <ng-container *ngIf="variable === 'totalVentass'; else notTotalVentas">{{
                              formatCurrency((horario[variable]*100)/sumValues1[variable]) | number:'1.0-0'}}%</ng-container>
                            <ng-template #notTotalVentas>
                              <ng-container *ngIf="variable === 'tasaConver'; else otra">
                                <!-- {{((horario[variable]*100))/((sumValues1['numeroTransacciones']*100)/sumValues1['NumeroEntradas']) | number:'1.0-0'}}% -->
                                -
                              </ng-container>
                                <ng-template #otra>{{ (horario[variable]*100)/sumValues1[variable] | number:'1.0-0'}}%</ng-template>
                            </ng-template>
                          </td>
                        </tr>
                      </ng-container>
                      </tbody>
                    </table>
                  </div>
                  </div>
                </div>

                


              </div>
            </div>
          </div>
        </div>
      </section>
    </section>


  </div>
</div>
<ng-template #detalle let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Detalles de Fechas {{HoraSeleccionada}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">


      <table class="table ">
        <thead>
          <tr>
            <th>Hora</th>
            <th>Entradas</th>
            <th>Numero Asesoras</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of Horas">
            <td colspan="0">{{item.Hora}}:00</td>
            <td colspan="0">{{item.Entradas}}</td>
            <td colspan="0">{{item.AsesorasPorHora}}</td>

          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">

      <apx-chart [series]="chartSeries" [chart]="chartOptions"></apx-chart>


    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">
      Ok
    </button>
  </div>
</ng-template>