import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreCommonModule } from '@core/common.module';
import { FormsModule } from '@angular/forms';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { SampleComponent } from './sample.component';
import { HomeComponent } from './home.component';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { HoraingresoComponent } from './horaingreso/horaingreso.component';
import { AcumuladoTiendaComponent } from './acumulado-tienda/acumulado-tienda.component';
import { SubirFacturaComponent } from './subir-factura/subir-factura.component';
import { SubirpptoComponent } from './subirppto/subirppto.component';
import { FileUploadModule } from 'ng2-file-upload';
import { HeatmapComponent } from './heatmap/heatmap.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CoreThemeCustomizerComponent } from '@core/components/theme-customizer/theme-customizer.component';
import { VisualizarComprobantesComponent } from './visualizar-comprobantes/visualizar-comprobantes.component';
import { ConcesionesComponent } from './concesiones/concesiones.component';
import { OcupacionTiendasComponent } from './ocupacion-tiendas/ocupacion-tiendas.component';
import { CellColorDirective } from './ocupacion-tiendas/cell-color.directive';
import { PanamaComponent } from './panama/panama.component';
import { SubirDatosReposicionPedidoComponent } from './subir-datos-reposicion-pedido/subir-datos-reposicion-pedido.component';
import { ConsultarReposPedidosComponent } from './consultar-repos-pedidos/consultar-repos-pedidos.component';
import { PeruComponent } from './peru/peru.component';
import { ReferenciasEspecificasComponent } from './referencias-especificas/referencias-especificas.component';
import { ConfigVoluminizacionComponent } from './config-voluminizacion/config-voluminizacion.component';
import { RecalculoTransaccionesComponent } from './recalculo-transacciones/recalculo-transacciones.component';


const routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { animation: 'dashboard' }
  },
  {
    path: 'sample',
    component: SampleComponent,
    data: { animation: 'sample' }
  },
  {
    path: 'home',
    component: HomeComponent,
    data: { animation: 'home' }
  },
  {
    path: 'horaingreso',
    component: HoraingresoComponent,
    data: { animation: 'horaingreso' }
  },
  {
    path: 'acumuladoPt',
    component: AcumuladoTiendaComponent,
    data: { animation: 'acumuladoPt' }
  },
  {
    path: 'subirppto',
    component: SubirpptoComponent,
    data: { animation: 'subirppto' }
  },
  {
    path: 'heatmap',
    component: HeatmapComponent,
    data: { animation: 'heatmap' }
  },
  {
    path: 'subirFactura',
    component: SubirFacturaComponent,
    data: { animation: 'subirFactura' }
  },
  {
    path: 'consultaComprobante',
    component: VisualizarComprobantesComponent,
    data: { animation: 'consultaComprobante' }
  },
  {
    path: 'consultaConcesiones',
    component: ConcesionesComponent,
    data: { animation: 'consultaConcesiones' }
  },
  {
    path: 'ocupacionAsesoras',
    component: OcupacionTiendasComponent,
    data: { animation: 'ocupacionAsesoras' }
  },
  {
    path: 'panamaComponent',
    component: PanamaComponent,
    data: { animation: 'panamaComponent' }
  },
  {
    path: 'peruComponent',
    component: PeruComponent,
    data: { animation: 'peruComponent' }
  },
  {
    path: 'subirDataReposPedido',
    component: SubirDatosReposicionPedidoComponent,
    data: { animation: 'subirDataReposPedido' }
  },
  {
    path: 'consultarReposPedidos',
    component: ConsultarReposPedidosComponent,
    data: { animation: 'consultarReposPedidos' }
  },
  {
    path: 'referenciasEspecificas',
    component: ReferenciasEspecificasComponent,
    data: { animation: 'referenciasEspecificas' }
  },
  {
    path: 'configVoluminizacion',
    component: ConfigVoluminizacionComponent,
    data: { animation: 'configVoluminizacion' }
  },
  {
    path: 'RecalculoTransaccionesComponent',
    component: RecalculoTransaccionesComponent,
    data: { animation: 'RecalculoTransaccionesComponent' }
  }


];

@NgModule({
  declarations: [SampleComponent, HomeComponent, HoraingresoComponent, AcumuladoTiendaComponent, SubirpptoComponent,HeatmapComponent, DashboardComponent, SubirFacturaComponent, VisualizarComprobantesComponent, ConcesionesComponent, OcupacionTiendasComponent,CellColorDirective, PanamaComponent, SubirDatosReposicionPedidoComponent, ConsultarReposPedidosComponent, PeruComponent, ReferenciasEspecificasComponent, ConfigVoluminizacionComponent, RecalculoTransaccionesComponent],
  imports: [SweetAlert2Module.forRoot(),NgApexchartsModule,RouterModule.forChild(routes),FileUploadModule, ContentHeaderModule, TranslateModule, CoreCommonModule,NgbModule,NgSelectModule,FormsModule,Ng2FlatpickrModule],
  exports: [SampleComponent, HomeComponent,]
 
  
})
export class SampleModule {}
