import { Injectable } from "@angular/core"

@Injectable({
    providedIn:"root"
})
export class DiasMes{
    mes=[
        {
            "Mes":"01",
            "diaInicio":"01",
            "diaFin":"31"
        },
        {
            "Mes":"02",
            "diaInicio":"01",
            "diaFin":"28"
        },
        {
            "Mes":"03",
            "diaInicio":"01",
            "diaFin":"31"
        },
        {
            "Mes":"04",
            "diaInicio":"01",
            "diaFin":"30"
        },
        {
            "Mes":"05",
            "diaInicio":"01",
            "diaFin":"31"
        },
        {
            "Mes":"06",
            "diaInicio":"01",
            "diaFin":"30"
        },
        {
            "Mes":"07",
            "diaInicio":"01",
            "diaFin":"31"
        },
        {
            "Mes":"08",
            "diaInicio":"01",
            "diaFin":"31"
        },
        {
            "Mes":"09",
            "diaInicio":"01",
            "diaFin":"30"
        },
        {
            "Mes":"10",
            "diaInicio":"01",
            "diaFin":"31"
        },
        {
            "Mes":"11",
            "diaInicio":"01",
            "diaFin":"30"
        },
        {
            "Mes":"12",
            "diaInicio":"01",
            "diaFin":"31"
        },
    ]
}