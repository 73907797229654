import { Directive, ElementRef, Input, Renderer2, OnChanges } from '@angular/core';

@Directive({
  selector: '[appCellColor]'
})
export class CellColorDirective implements OnChanges {

  @Input() appCellColor: number;
  @Input() max: number;
  @Input() baseColor: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(): void {
    const opacity = (this.appCellColor / this.max);
    const color = `rgba(${this.baseColor}, ${opacity})`;
    this.renderer.setStyle(this.el.nativeElement, 'backgroundColor', color);
  }

}
