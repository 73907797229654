import { CoreMenu } from '@core/types'

export const menu3: 

CoreMenu[] = [

  
  {
    id: 'home',
    title: 'Metas',
    translate: 'MENU.HOME',
    type: 'item',
    icon: 'home',
    url: 'home'
  }
  ,
  
  {
    id: 'recalculoTransacciones',
    title: 'Seguimiento Transacciones',
    translate: 'MENU.RECALCULOTRANSACCIONES',
    type: 'item',
    icon: 'layers',
    url: 'RecalculoTransaccionesComponent'
  },
  {
    id: 'consultaConcesiones',
    title: 'Concesiones',
    translate: 'MENU.CONSULTACONCESIONES',
    type: 'item',
    icon: 'layers',
    url: 'consultaConcesiones'
  }
  ,
  {
    id: 'subirFactura',
    title: 'GPT 3.5 AI Consignacion ',
    translate: 'MENU.SUBIRFACTURA',
    type: 'item',
    icon: 'layers',
    url: 'subirFactura'
  },
  {
    id: 'ocupacionAsesoras',
    title: 'Ocupacion',
    translate: 'MENU.OCUPACIONASESORAS',
    type: 'item',
    icon: 'layers',
    url: 'ocupacionAsesoras'
  }
  ,
  {
    id: 'informePanama',
    title: 'Indicadores Panama',
    translate: 'MENU.PANAMA',
    type: 'item',
    icon: 'layers',
    url: 'panamaComponent'
  }
  ,
  {
    id: 'informePeru',
    title: 'Indicadores Peru',
    translate: 'MENU.PERU',
    type: 'item',
    icon: 'flag',
    url: 'peruComponent'
  },
  {
    id: 'referenciasEspecificas',
    title: 'Voluminizacion',
    translate: 'MENU.REFERENCIAS',
    type: 'item',
    icon: 'layers',
    url: 'referenciasEspecificas'
  },
  {
    id: 'configvoluminizacion',
    title: 'ConfigVoluminizacion',
    translate: 'MENU.CONFIGVOLUMINIZACION',
    type: 'item',
    icon: 'layers',
    url: 'configVoluminizacion'
  }
  ,
  {
    id: 'acumuladoPt',
    title: 'Detalle Acum',
    translate: 'MENU.CUMULADOPT',
    type: 'item',
    icon: 'bar-chart-2',
    url: 'acumuladoPt'
  }
  ,
  {
    id: 'sample',
    title: 'Detalle Diario',
    translate: 'MENU.SAMPLE',
    type: 'item',
    icon: 'bar-chart-2',
    url: 'sample'
  }
  ,
  {
    id: 'horaingreso',
    title: 'Apertura-Cierre',
    translate: 'MENU.HORAINGRESO',
    type: 'item',
    icon: 'watch',
    url: 'horaingreso'
  },
  {
    id: 'subirppto',
    title: 'Subir Presupuesto',
    translate: 'MENU.SUBIRPPTO',
    type: 'item',
    icon: 'share',
    url: 'subirppto'
  }
  ,
  {
    id: 'heatmap',
    title: 'Informe por Hora',
    translate: 'MENU.HEATMAP',
    type: 'item',
    icon: 'layers',
    url: 'heatmap'
  },
  
  {
    id: 'consultaComprobante',
    title: 'Ver-Comprobante',
    translate: 'MENU.CONSULTACOMPROBANTE',
    type: 'item',
    icon: 'layers',
    url: 'consultaComprobante'
  },
  
  {
    id: 'subirReposPedido',
    title: 'Subir Repos Pedido',
    translate: 'MENU.DATOSREPOSPEDIDO',
    type: 'item',
    icon: 'layers',
    url: 'subirDataReposPedido'
  }
 
  
]