import { Component, OnInit, ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { InfoTiendaData } from "../../../mock/tiendas";
import { DiasMes } from "../../../mock/diasmes";
import { ExcelService } from "app/services/excelservice";
import { ServiceService } from "app/services/service.service";
import {
  NgbDateStruct,
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbTimeStruct,
} from "@ng-bootstrap/ng-bootstrap";
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexChart,
  ApexResponsive,
  ApexTooltip,
  ApexPlotOptions,
} from "ng-apexcharts";
import { listLazyRoutes } from "@angular/compiler/src/aot/lazy_routes";
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from "constants";
import { i18nMetaToJSDoc } from "@angular/compiler/src/render3/view/i18n/meta";
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  colors: any;
  responsive: ApexResponsive;
  tooltip: ApexTooltip;
  plotOptions: ApexPlotOptions;
};

@Component({
  selector: "app-heatmap",
  templateUrl: "./heatmap.component.html",
  styleUrls: ["./heatmap.component.scss"],
})
export class HeatmapComponent {
  public fromDate: any | null;
  public toDate: any | null;
  // NgbDate
  public contentHeader: object;
  model: NgbDateStruct;

  public selectMulti: Observable<any[]>;
  public selectMultiSelected = [{}];

  public hoveredDate: NgbDate | null = null;
  public loading = false;
  presupuestomes: any = 0;

  ventastotaldia: number = 0;
  ventastotalmes: any = 0;
  porcentajedia: number = 0;
  porcentajemes: number = 0;
  show: number = 0;
  show1: number = 0;
  porcentajefaltantedia: number = 0;
  porcentajefaltantemes: number = 0;

  presupuestomenosventasdia: number = 0;
  presupuestomenosventasmes: number = 0;
  mesames: any;
  nombretienda: string;
  cumplimientohistorico: any;
  cantidadVentasDia: any = 0;
  ventasFisicasActualDia: any = 0;
  ventasVirtualesActualDia: number = 0;
  cantidadVentasMesActual: any = 0;
  ventasFisicasMesActual: any = 0;
  ventasVirtualesMesActual: any = 0;
  devoluciones: any;
  ventasFisicasMesActual1: number = 0;
  ventasFisicasActualDia1: number = 0;
  devolucionesActualDia: number = 0;
  devolucionesMesActual: number = 0;
  cantidadVentasDigitaldia: number = 0;
  cantidadVentasDigitalMes: number = 0;
  presupuestoActualDigitalDia: number = 0;
  presupuestoDigitalMesActual: number = 0;
  porcentajeDigitaldia: number = 0;
  porcentajeDigitalmes: number = 0;
  cantidadArticulosTotalesDia: number = 0;
  cantidadArticulosTotalesMesActual: any = 0;
  cantidadTransaccionesMesActual: number = 0;
  cantidadTransaccionesDia: number = 0;
  ticketpromediodia: number = 0;
  ticketpromediomes: number = 0;
  articulosPorTicketDia: number = 0;
  articulosPorTicketMes: number = 0;
  totalCreditoKaiowaMesActual: number = 0;
  totalCreditoKaiowaDiaActual: number = 0;
  presupuestoCreditoKaiowaDia: number = 0;
  presupuestoKaiowaMesActual: number = 0;
  porcentajeDiaKaiowa: number = 0;
  porcentajeMesKaiowa: number = 0;

  formatos = [];
  tiendas = [];
  agencias = [];
  ciudades = [];
  responsables: any[];
  agencia: any = {};
  pendientekaiowa: number;
  pendientedigital: number;
  vpt: number = 0;
  upt: number = 0;
  pp: number = 0;
  vptsupt: number = 0;
  fechainicioStr: string;
  idTienda: number;
  fechainicio: Date;
  fechainidash: string;
  diadia: any;
  totalventas: number = 0;
  totalpresup: number = 0;
  totalporcent: number = 0;
  totalpendiente: number = 0;
  fechafin: Date;
  mostrarfiltros: boolean = true;
  fechafinStr: string;
  fechafindash: string;
  prespuestoentrefechas: number = 0;
  totalventasentrefechas: number = 0;
  porcentajeentrefechas: number = 0;
  porcentajefaltanteentrefechas: number = 0;
  presupuestomenosventasentrefechas: number = 0;
  mostrarfiltrosi: boolean = false;

  ventadigital: number = 0;
  ventafisica: number = 0;

  nombre: string = "Faltante";
  totalDevoluciones: number = 0;
  ventafisica1: number = 0;
  cantidadVentas: number = 0;
  cantidadTransacciones: number = 0;
  cantidadArticulosTotales: number = 0;
  ticketpromedio: number = 0;
  articulosporticket: number = 0;
  totalCreditoKaiowa: number = 0;
  presupuestoCreditoKaiowa: number = 0;
  cumplimientoKaiowa: number = 0;
  presupuestoDigital: number = 0;
  cumplimientoRdl: number = 0;
  totaltotal: number;
  totaltotalventas: number = 0;
  totalpresupuesto: number = 0;
  totalcumplimiento: number = 0;
  totalpendiente1: number = 0;
  presupuestoFisico: number = 0;
  pendientedig: number = 0;
  ventassobretransacciones: number = 0;
  cumplimientovpt: number = 0;
  articulossobretransacciones: number = 0;
  precioprom: number = 0;
  vptsobreupt: number = 0;
  pendientecrkaiowa: number = 0;
  Participacionfisico: number = 0;
  Participaciondigital: number = 0;
  Participaciontotal: number = 0;
  diar: any;
  codigoTienda: any = null;
  agenciaTienda: any;
  formatoTienda: any = null;
  vista: any = "Ventas";
  ciudad: any = null;
  difecha: any;
  dif: number;
  // arregloAgencia: void;
  color: string;
  colorPf: boolean;
  colorCf: boolean;
  colorPd: boolean;
  colorPt: boolean;
  colorCd: boolean;
  colorCt: string;
  colorPvpt: boolean;
  colorPupt: boolean;
  colorPpp: boolean;
  colorPtc: boolean;

  colorPent: boolean;
  colorPkai: boolean;
  colorCvpt: boolean;
  colorCupt: boolean;
  colorCpp: boolean;
  colorCtc: boolean;
  colorCent: boolean;
  colorCkai: boolean;

  infoTiendaFiltrada: any;
  rol: string;
  mostrarCampo: boolean = true;

  coordinador: string;
  infoTiendaFiltradaAge: any;

  filtroAplicados: string[] = [];
  colorPfi: boolean;
  colorPdi: boolean;
  colorPtot: boolean;
  colorTrans: boolean;
  mostrarsincronizacion: boolean;
  mostrarVentas: boolean;
  mostrarIndicadores: boolean;
  mostrarPotenciales: boolean;
  colorTransTotal: string;
  colorTransDia: string;
  colorTransss: string;
  mostrarDetalleVentasFisicos: boolean = false;
  mostrarDetalleVentasDigitaless: boolean = false;
  mostrarDetalleVentasTotal: boolean = false;
  mostrarDetalleVentasCreditos: boolean = false;
  mostrarDetallesVPTs: boolean = false;
  mostrarDetallesUPTs: boolean = false;
  mostrarDetallesPPs: boolean = false;
  mostrarDetallesTCs: boolean = false;
  mostrarDetallesEntrada: boolean = false;
  flecharoja: boolean;
  flechaRojacDig: boolean;
  flecharojacfis: boolean;
  flechaRojaPdig: boolean;
  flecharojaPt: boolean;
  flechaRojaPfi: boolean;
  flechaRojaPdi: boolean;
  flechaRojaPvpt: boolean;
  flechaRojaCvpt: boolean;
  flechaRojaPupt: boolean;
  flechaRojaCupt: boolean;
  flechaRojaPpp: boolean;
  flechaRojaCpp: boolean;
  flechaRojaPtc: boolean;
  flechaRojaCtc: boolean;
  flechaRojaPent: boolean;
  flechaRojaCent: boolean;
  flechaRojaPkai: boolean;
  flechaRojaCkai: boolean;
  flecaRojaPtot: boolean;
  deshabilitado: boolean = false;
  flechaRojaTra: boolean;
  colorcTrans: boolean;
  flechaRojacTrans: boolean;
  desdehasta: string;
  codigoarray: any;
  mostrarDetalleTransacciones: boolean = false;
  mostrarDetallePartCreditos: boolean;
  fechaSincronizacionCam: any;
  intervalId: number;
  token: string;
  pTotal: any;
  pendTotal: any;
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public chartOptions2: Partial<ChartOptions>;
  public chartOptions3: Partial<ChartOptions>;
  public chartOptions4: Partial<ChartOptions>;
  public chartOptions5: Partial<ChartOptions>;
  public chartOptions6: Partial<ChartOptions>;
  public chartOptions7: Partial<ChartOptions>;
  public chartOptions8: Partial<ChartOptions>;
  resultadoHoras: any;
  lista: any[];
  listatotalx = [];
  series2: any[];
  aux: any[];
  contador: number;
  mostrarvariable: boolean = false;
  contador2: number;
  suma: any;
  serieauxiliar: { x: any; y: number; };
  excepciones: string[];
  listaTransacciones: any[];
  listaEntradas: any[];
  listaArticulos: any[];
  listaVentas: any[];
  listavpt: any[];
  listaupt: any[];
  listatc: any[];
  listaExcel: any[];
  contadorExcel: number;
  fechasincro: any;


  constructor(
    private calendar: NgbCalendar,
    public excelService: ExcelService,
    private router: Router,
    public formatter: NgbDateParserFormatter,
    private inforTiendas: InfoTiendaData,
    private diamess: DiasMes,
    private service: ServiceService
  ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();
    this.chartOptions8 = {
      series: [
        {
          name: "Part",
          data: this.generateData("Header", null)
        },
        {
          name: "Total",
          data: this.generateData("Header", null)
        }
      ],
      chart: {
        height: 200,
        type: "heatmap",
        toolbar: {
          show: false,
        },
      },

      dataLabels: {
        style: {
          colors: ["#000000", "#000000", "#000000"],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
        },
        enabled: false,
      },

      colors: ["#6300FB"],
      title: {
        text: "Informe por Hora",
      },
    };
    this.chartOptions7 = {
      series: [
        {
          name: "Domingo",
          data: this.generateData("Header", null)
        },
        {
          name: "Sábado",
          data: this.generateData("Header", null)
        },
        {
          name: "Viernes",
          data: this.generateData("Header", null)
        },
        {
          name: "Jueves",
          data: this.generateData("Header", null)
        },
        {
          name: "Miércoles",
          data: this.generateData("Header", null)
        },
        {
          name: "Martes",
          data: this.generateData("Header", null)
        },
        {
          name: "Lunes",
          data: this.generateData("Header", null)
        },
      ],
      chart: {

        height: 350,
        type: "heatmap",
        toolbar: {
          show: false,
        },
      },


      dataLabels: {
        style: {
          colors: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
        },
        enabled: false,
      },

      colors: ["#6300FB"],
      title: {
        text: "Informe por Hora",
      },
    };
    this.chartOptions6 = {
      series: [
        {
          name: "",
          data: this.generateData("Totalizado", 1),
        },
      ],
      chart: {
        height: 100,
        type: "heatmap",
        toolbar: {
          show: false,
        },
      },

      dataLabels: {
        style: {
          colors: ["#000000", "#000000", "#000000"],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
        },
        enabled: true,
      },

      colors: ["#6300FB"],
      title: {
        text: "Informe por Hora",
      },
    };
    this.chartOptions5 = {
      series: [
        {
          name: "",
          data: this.generateData("Total", 24),
        },
      ],
      chart: {
        height: 100,
        type: "heatmap",
        toolbar: {
          show: false,
        },
      },

      dataLabels: {
        style: {
          colors: ["#000000", "#000000", "#000000"],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
        },
        enabled: true,
      },

      colors: ["#6300FB"],
      title: {
        text: "Informe por Hora",
      },
    };
    this.chartOptions4 = {
      series: [
        {
          name: "",
          data: this.generateData("Part", 1),
        },
        {
          name: "",
          data: this.generateData("Part", 1),
        },
        {
          name: "",
          data: this.generateData("Part", 1),
        },
        {
          name: "",
          data: this.generateData("Part", 1),
        },
        {
          name: "",
          data: this.generateData("Part", 1),
        },
        {
          name: "",
          data: this.generateData("Part", 1),
        },
        {
          name: "",
          data: this.generateData("Part", 1),
        },
      ],
      chart: {
        height: 350,
        width: "105%",
        type: "heatmap",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#000000", "#000000", "#000000"],
        },
      },
      colors: ["#6300FB"],
      title: {
        text: "Informe por Hora",
      },
    };
    this.chartOptions3 = {
      series: [
        {
          name: "",
          data: this.generateData("7", 1),
        },
        {
          name: "",
          data: this.generateData("6", 1),
        },
        {
          name: "",
          data: this.generateData("5", 1),
        },
        {
          name: "",
          data: this.generateData("4", 1),
        },
        {
          name: "",
          data: this.generateData("3", 1),
        },
        {
          name: "",
          data: this.generateData("2", 1),
        },
        {
          name: "",
          data: this.generateData("1", 1),
        },
      ],
      chart: {
        height: 350,
        width: "100%",
        type: "heatmap",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#000000", "#000000", "#000000"],
        },
      },
      colors: ["#6300FB"],
      title: {
        text: "Informe por Hora",
      },
    };

    this.chartOptions2 = {
      series: [
        {
          name: "",
          data: this.generateData("Total", 24),
        },
      ],
      chart: {
        height: 100,
        type: "heatmap",
        toolbar: {
          show: false,
        },
      },

      dataLabels: {
        style: {
          colors: ["#000000", "#000000", "#000000"],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
        },
        enabled: true,
      },

      colors: ["#6300FB"],
      title: {
        text: "Informe por Hora",
      },
    };

    this.chartOptions = {
      tooltip: {
        enabled: true,
        followCursor: false,
        intersect: false,
        theme: "dark",
        style: {
          fontSize: '20px',
          fontFamily: undefined
        }
      },
      series: [

        {
          name: "",
          data: this.generateData("Domingo", 24),
        },
        {
          name: "",
          data: this.generateData("Sábado", 24),
        },
        {
          name: "",
          data: this.generateData("Viernes", 24),
        },
        {
          name: "",
          data: this.generateData("Jueves", 24),
        },
        {
          name: "",
          data: this.generateData("Miércoles", 24),
        },
        {
          name: "",
          data: this.generateData("Martes", 24),
        },
        {
          name: "",
          data: this.generateData("Lunes", 24),
        }
      ],
      chart: {

        height: 350,
        type: "heatmap",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#000000", "#000000", "#000000"],
        },
      },
      colors: ["#6300FB"],
      title: {
        text: "Informe por Hora",
      },
    };
  }

  ngOnInit() {
    
    this.excepciones = [
      "Julian Alberto Navarro Garcia",
      "Leydi Milena Peña Sepulveda",
  "Mariana Ossa Sanchez",
      "Sergio Alexander Villa Cano",
      "Juan Carlos Gallego Elejalde",
      "Melissa Castrillon Cano",
      "Jose Mario Tamayo Lopera",
      "Juan Carlos Maya Arbelaez",
      "Mauricio Andres Cardona Suarez",
      "Carlos Ignacio Urrea",
      "Ana Lucia Hernandez Santa",
      "Alejandro Gonzalez Vasquez",
      "Maria Camila Cardona Arroyave",
      "Maria Cristina Londoño Cruz",
      "David  Gomez Patiño",
      "Soraya  Bedoya Mejia",
      "Margarita Maria Mazo Franco",
      "Luis Guillermo Maya Arbelaez"
    ];
    this.vista = "Ventas";
    this.rol = sessionStorage.getItem("rol");

    this.fechainicioStr =
      this.fromDate.year.toString() +
      "-" +
      this.fromDate.month.toString().padStart(2, "0") +
      "-" +
      this.fromDate.day.toString().padStart(2, "0");
    this.fechafinStr =
      this.toDate.year.toString() +
      "-" +
      this.toDate.month.toString().padStart(2, "0") +
      "-" +
      this.toDate.day.toString().padStart(2, "0");

    this.desdehasta =
      " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr;
    this.coordinador = sessionStorage.getItem("responsable");
    this.mostrarVentas = false;
    this.mostrarIndicadores = false;
    this.mostrarPotenciales = false;
    this.codigoTienda = null;
    this.agenciaTienda = null;
    this.formatoTienda = null;
    this.ciudad = null;
    if (this.rol == "null" || this.rol == undefined) {
      this.codigoTienda = parseInt(sessionStorage.getItem("idTienda"));
      this.mostrarCampo = false;
      this.mostrarfiltros = false;
    }
    this.nombretienda = sessionStorage.getItem("tienda");
    if (this.nombretienda == null) {
      this.router.navigate([
        "../pages/authentication/auth-login-v2/auth-login-v2.component.html",
      ]);
      return;
    }

    if (
      this.rol == "COORDINADOR" &&
      !this.excepciones.join(",").includes(this.coordinador)
    ) {
      this.inforTiendas.infoTienda = this.inforTiendas.infoTiendaAll.filter(
        (x) => x.Responsable == this.coordinador
      );
      this.agenciaTienda = this.inforTiendas.infoTienda[0].Regional;
      if(this.agenciaTienda!='02'){
        this.deshabilitado = true;
      }
    } else if (
      this.rol == "GERENTE" &&
      !this.excepciones.join(",").includes(this.coordinador)
    ) {
      this.inforTiendas.infoTienda = this.inforTiendas.infoTiendaAll.filter(
        (x) => x.Gerente == this.coordinador
      );
      this.agenciaTienda = this.inforTiendas.infoTienda[0].Regional;
      if(this.agenciaTienda!='02'){
        this.deshabilitado = true;
      }
    } else this.inforTiendas.infoTienda = this.inforTiendas.infoTiendaAll;
    this.contentHeader = {
      headerTitle: "Informe por Hora",
      actionButton: true,
      breadcrumb: {
        type: "",
        links: [
          {
            name: "Hora",
            isLink: false,
            link: "/",
          },
          {
            name: "KPI por hora",
            isLink: false,
          },
        ],
      },
    };
    if (
      this.rol == "GERENTE" &&
      !this.excepciones.join(",").includes(this.coordinador)
    ) {
      this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
      this.filterGer();
    } else if (
      this.rol == "COORDINADOR" &&
      !this.excepciones.join(",").includes(this.coordinador)
    ) {
      this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
      this.filterResp();
    } else {
      this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
      this.fillFilter(this.inforTiendas.infoTienda);
    }
  }

  buscarDatos() {
    this.mostrarvariable = true
    this.loading = true;
    if (this.codigoTienda != null) {
      var dataEnviar = {
        fechaInicio: this.fechainicioStr,
        fechaFin: this.fechafinStr,
        tiendas: this.codigoTienda.map((r) => r.Codigo),
      };
    } else {
      var dataEnviar = {
        fechaInicio: this.fechainicioStr,

        fechaFin: this.fechafinStr,
        tiendas: this.infoTiendaFiltrada.map((r) => r.CodigoWinPOS),
      };
    }

    this.service.RDLIndicadoresAcumuladosPorHora(dataEnviar).then((result) => {
      
      this.resultadoHoras = result;
      this.loading = false;
      this.chartOptionss();
      this.fechasincro = result[0].fechaSincronizacion
    });
  }
  chartOptionss4() {
    this.chartOptions4 = {
      tooltip: {
        enabled: true,
        followCursor: false,
        intersect: false,
        theme: "light",



        style: {
          fontSize: '20px',
          fontFamily: undefined
        }
      },
      series: [
        {
          name: "",
          data: this.actualizarData("Part", 1),
        },
        {
          name: "",
          data: this.actualizarData("Part", 1),
        },
        {
          name: "",
          data: this.actualizarData("Part", 1),
        },
        {
          name: "",
          data: this.actualizarData("Part", 1),
        },
        {
          name: "",
          data: this.actualizarData("Part", 1),
        },
        {
          name: "",
          data: this.actualizarData("Part", 1),
        },
        {
          name: "",
          data: this.actualizarData("Part", 1),
        },
      ],
      chart: {
        height: 350,
        width: "105%",
        type: "heatmap",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#000000", "#000000", "#000000"],
        },
        formatter: function (value) {
          return value.toString() + "%";
        }
      },
      colors: ["#6300FB"],
      title: {
        text: "Informe por Hora",
      },
    };
  }
  chartOptionss2() {
    const options2 = {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    };
    const numberFormat2 = new Intl.NumberFormat("en-US", options2);
    if (this.vista == "Ventas" || this.vista == "VPT") {
      this.chartOptions2 = {
        tooltip: {
          enabled: true,
          followCursor: false,
          intersect: false,
          theme: "light",

          y: {
            formatter: function (value) {
              return numberFormat2.format(Math.round(value));
            }
          },

          style: {
            fontSize: '20px',
            fontFamily: undefined
          }
        },
        series: [
          {
            name: "",
            data: this.actualizarData("Total", 24),
          },
        ],
        chart: {
          height: 100,
          type: "heatmap",
          width: "300%",
          toolbar: {
            show: false,
          },
        },

        dataLabels: {
          style: {
            colors: ["#000000", "#000000", "#000000"],
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
          },
          enabled: true,
          formatter: function (value) {
            return numberFormat2.format(Math.round(value));
          },
        },
        colors: ["#6300FB"],
        title: {
          text: "Informe por Hora",
        },
      };
    } else {
      this.chartOptions2 = {
        tooltip: {
          enabled: true,
          followCursor: false,
          intersect: false,
          theme: "light",
          y: {
            formatter: function (value) {
              return parseFloat((value as any)).toLocaleString('en');
            },
          },
        },
        series: [
          {
            name: "",
            data: this.actualizarData("Total", 24),
          },
        ],
        chart: {
          height: 100,
          type: "heatmap",
          width: "300%",
          toolbar: {
            show: false,
          },
        },

        dataLabels: {
          style: {
            colors: ["#000000", "#000000", "#000000"],
            fontSize: "10px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
          },
          enabled: true,
          formatter: function (value) {
            return parseFloat((value as any)).toLocaleString('en');
          },
        },
        colors: ["#6300FB"],
        title: {
          text: "Informe por Hora",
        },
      };
    }
  }
  chartOptionss3() {
    const options2 = {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    };
    const numberFormat2 = new Intl.NumberFormat("en-US", options2);
    if (this.vista == "Ventas" || this.vista == "VPT") {
      this.chartOptions3 = {
        tooltip: {
          enabled: true,
          followCursor: false,
          intersect: false,
          theme: "light",
          y: {
            formatter: function (value) {
              return numberFormat2.format(Math.round(value));
            }
          },


          style: {
            fontSize: '20px',
            fontFamily: undefined
          }
        },
        series: [
          {
            name: "",
            data: this.actualizarData("7", 1),
          },
          {
            name: "",
            data: this.actualizarData("6", 1),
          },
          {
            name: "",
            data: this.actualizarData("5", 1),
          },
          {
            name: "",
            data: this.actualizarData("4", 1),
          },
          {
            name: "",
            data: this.actualizarData("3", 1),
          },
          {
            name: "",
            data: this.actualizarData("2", 1),
          },
          {
            name: "",
            data: this.actualizarData("1", 1),
          },
        ],
        chart: {
          height: 350,
          width: "200%",
          type: "heatmap",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#000000", "#000000", "#000000"],
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold"
          },
          formatter: function (value) {
            return numberFormat2.format(Math.round(value));
          }
        },
        colors: ["#6300FB"],
        title: {
          text: "Informe por Hora",
        },
      };
    } else {
      this.chartOptions3 = {
        tooltip: {
          enabled: true,
          followCursor: false,
          intersect: false,
          theme: "light",
          y: {
            formatter: function (value) {
              return parseFloat((value as any)).toLocaleString('en');
            },
          },
        },
        series: [
          {
            name: "",
            data: this.actualizarData("7", 1),
          },
          {
            name: "",
            data: this.actualizarData("6", 1),
          },
          {
            name: "",
            data: this.actualizarData("5", 1),
          },
          {
            name: "",
            data: this.actualizarData("4", 1),
          },
          {
            name: "",
            data: this.actualizarData("3", 1),
          },
          {
            name: "",
            data: this.actualizarData("2", 1),
          },
          {
            name: "",
            data: this.actualizarData("1", 1),
          },
        ],
        chart: {
          height: 350,
          width: "200%",
          type: "heatmap",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#000000", "#000000", "#000000"],
          },
          formatter: function (value) {
            return parseFloat((value as any)).toLocaleString('en');
          },
        },
        colors: ["#6300FB"],
        title: {
          text: "Informe por Hora",
        },
      };
    }
  }
  chartOptionss() {
    this.listavpt = []
    this.listaupt = []
    this.listatc = []
    this.listaExcel = []
    this.contadorExcel = 0
    this.suma = 0
    this.series2 = [{ x: "Lunes", y: 0, z: 0, w: 0 }, { x: "Martes", y: 0, z: 0, w: 0 }, { x: "Miércoles", y: 0, z: 0, w: 0 }, { x: "Jueves", y: 0, z: 0, w: 0 }, { x: "Viernes", y: 0, z: 0, w: 0 }, { x: "Sábado", y: 0, z: 0, w: 0 }, { x: "Domingo", y: 0, z: 0, w: 0 }];
    this.listatotalx = [];
    this.listaEntradas = [];
    this.listaTransacciones = [];
    this.listaArticulos = [];
    this.listaVentas = [];
    this.contador = 6;

    const options2 = {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    };
    const numberFormat2 = new Intl.NumberFormat("en-US", options2);
    if (this.vista == "Ventas" || this.vista == "VPT") {
      this.chartOptions = {
        tooltip: {

          enabled: true,
          followCursor: false,
          intersect: false,
          theme: "light",
          y: {
            formatter: function (value) {
              return numberFormat2.format(Math.round(value));
            }
          },
          style: {
            fontSize: '20px',
            fontFamily: undefined,

          }
        },
        series: [

          {
            name: "",
            data: this.actualizarData("Domingo", 24),
          },
          {
            name: "",
            data: this.actualizarData("Sábado", 24),
          },
          {
            name: "",
            data: this.actualizarData("Viernes", 24),
          },
          {
            name: "",
            data: this.actualizarData("Jueves", 24),
          },
          {
            name: "",
            data: this.actualizarData("Miércoles", 24),
          },
          {
            name: "",
            data: this.actualizarData("Martes", 24),
          },
          {
            name: "",
            data: this.actualizarData("Lunes", 24),
          }
        ],


        chart: {
          height: 350,
          type: "heatmap",
          width: "300%",
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              customIcons: []
            }
          },

        },


        dataLabels: {
          style: {
            colors: ["#000000", "#000000", "#000000"],
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",

          },
          enabled: true,
          formatter: function (value) {
            return numberFormat2.format(Math.round(value));
          },
        },
        colors: ["#6300FB"],
        title: {
          text: "Informe por Hora",
        },
      };
    } else {
      this.chartOptions = {
        tooltip: {
          enabled: true,
          followCursor: false,
          intersect: false,
          theme: "light",
          y: {
            formatter: function (value) {
              return parseFloat((value as any)).toLocaleString('en');
            },
          },


          style: {
            fontSize: '20px',
            fontFamily: undefined
          }
        },

        series: [

          {
            name: "",
            data: this.actualizarData("Domingo", 24),
          },
          {
            name: "",
            data: this.actualizarData("Sábado", 24),
          },
          {
            name: "",
            data: this.actualizarData("Viernes", 24),
          },
          {
            name: "",
            data: this.actualizarData("Jueves", 24),
          },
          {
            name: "",
            data: this.actualizarData("Miércoles", 24),
          },
          {
            name: "",
            data: this.actualizarData("Martes", 24),
          },
          {
            name: "",
            data: this.actualizarData("Lunes", 24),
          }
        ],

        chart: {
          height: 350,
          type: "heatmap",
          width: "300%",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
            type: 'x',
            autoScaleYaxis: true,
            zoomedArea: {
              fill: {
                color: '#90CAF9',
                opacity: 0.4
              },
              stroke: {
                color: '#0D47A1',
                opacity: 0.4,
                width: 1
              }
            }
          }
        },

        dataLabels: {
          style: {
            colors: ["#000000", "#000000", "#000000"],
            fontSize: "14px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
          },
          enabled: true,
          formatter: function (value) {
            return parseFloat((value as any)).toLocaleString('en');
          },
        },
        colors: ["#6300FB"],
        title: {
          text: "Informe por Hora",
        },
      };
    }
    this.chartOptionss2();
    this.chartOptionss3();
    this.chartOptionss4();
    this.chartOptionss5();
    this.chartOptionss6();
  }
  chartOptionss6() {
    const options2 = {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    };
    const numberFormat2 = new Intl.NumberFormat("en-US", options2);
    if (this.vista == "Ventas" || this.vista == "VPT") {
      this.chartOptions6 = {
        tooltip: {
          enabled: true,
          followCursor: false,
          intersect: false,
          theme: "light",
          y: {
            formatter: function (value) {
              return numberFormat2.format(Math.round(value));
            }
          },

          style: {
            fontSize: '20px',
            fontFamily: undefined
          }
        },
        series: [
          {
            name: "",
            data: this.actualizarData("Totalizado", 1),
          },
        ],
        chart: {
          height: 100,
          type: "heatmap",
          width: "100%",

          toolbar: {
            show: false,
          },
        },

        dataLabels: {
          style: {
            colors: ["#000000", "#000000", "#000000"],
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
          },
          enabled: true,
          formatter: function (value) {
            return numberFormat2.format(Math.round(value));
          },
        },
        colors: ["#6300FB"],
        title: {
          text: "Informe por Hora",
        },
      };
    } else {
      this.chartOptions6 = {
        tooltip: {
          enabled: true,
          followCursor: false,
          intersect: false,
          theme: "light",
          y: {
            formatter: function (value) {
              return parseFloat((value as any)).toLocaleString('en');
            },
          },
        },
        series: [
          {
            name: "",
            data: this.actualizarData("Totalizado", 1),
          },
        ],
        chart: {
          height: 100,
          type: "heatmap",
          width: "100%",
          toolbar: {
            show: false,
          },
        },

        dataLabels: {
          style: {
            colors: ["#000000", "#000000", "#000000"],
            fontSize: "10px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
          },
          enabled: true,

          formatter: function (value) {
            return parseFloat((value as any)).toLocaleString('en');
          },
        },
        colors: ["#6300FB"],
        title: {
          text: "Informe por Hora",
        },
      };
    }
  }

  chartOptionss5() {
    const options2 = {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    };
    const numberFormat2 = new Intl.NumberFormat("en-US", options2);
    if (this.vista == "Ventas" || this.vista == "VPT") {
      this.chartOptions5 = {
        tooltip: {
          enabled: true,
          followCursor: false,
          intersect: false,
          theme: "light",

          style: {
            fontSize: '20px',
            fontFamily: undefined
          }
        },
        series: [
          {
            name: "",
            data: this.actualizarData("Participacion", 24),
          },
        ],
        chart: {
          height: 100,
          type: "heatmap",
          width: "300%",
          toolbar: {
            show: false,
          },
        },

        dataLabels: {
          style: {
            colors: ["#000000", "#000000", "#000000"],
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
          },
          enabled: true,
          formatter: function (value) {
            return value.toString() + "%";
          },
        },
        colors: ["#6300FB"],
        title: {
          text: "Informe por Hora",
        },
      };
    } else {
      this.chartOptions5 = {
        series: [
          {
            name: "",
            data: this.actualizarData("Participacion", 24),
          },
        ],
        chart: {
          height: 100,
          type: "heatmap",
          width: "300%",
          toolbar: {
            show: false,
          },
        },

        dataLabels: {
          style: {
            colors: ["#000000", "#000000", "#000000"],
            fontSize: "10px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
          },
          enabled: true,

          formatter: function (value) {
            return value.toString() + "%";
          },
        },
        colors: ["#6300FB"],
        title: {
          text: "Informe por Hora",
        },
      };
    }
  }

  filterResp() {
    this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter((p) => {
      return (
        p.Responsable.toString().toLowerCase() == this.coordinador.toLowerCase()
      );
    });
    this.fillFilter(this.infoTiendaFiltrada);
  }

  filterGer() {
    this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter((p) => {
      return p.Gerente == this.coordinador;
    });
    this.fillFilter(this.infoTiendaFiltrada);
  }
  private fillFilter(infotienda: any) {
    var agencia = infotienda.map((x) => {
      return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia };
    });
    var arregloAgencia = [];
    agencia.map((a) => {
      if (
        arregloAgencia.filter((p) => p.codigoAgencia == a.codigoAgencia)
          .length == 0
      )
        arregloAgencia.push(a);
    });


    this.agencias = [...new Set(arregloAgencia)];
    this.formatos = [...new Set(infotienda.map((x) => x.Formato))];
    this.ciudades = [...new Set(infotienda.map((x) => x.CIUDAD))];
    this.responsables = [...new Set(infotienda.map((x) => x.Responsable))];
    this.tiendas = [
      ...new Set(
        infotienda.map((x) => {
          return { Nombre: x.NOMBRE, Codigo: x.CodigoWinPOS };
        })
      ),
    ];
    this.vista = this.vista;
  }
  public generateData(nombredia, count) {
    var i = 0;
    var series = [];
    this.lista = [];
    while (i < count) {
      switch (nombredia) {
        case "1":
          var x = "Total";
          break;
        case "2":
          var x = "Total";
          break;
        case "3":
          var x = "Total";
          break;
        case "4":
          var x = "Total";
          break;
        case "5":
          var x = "Total";
          break;
        case "6":
          var x = "Total";
          break;
        case "7":
          var x = "Total";
          break;
        case "Part":
          var x = "Part"
          break
        default:
          var x = i.toString().padStart(2, "0") + ":00";
          break;
      }
      if (nombredia == "Header") {
        var x = ""
        var y: any = ""
      }
      if (nombredia == "Totalizado") {
        var x = "Totalizado"
      }
      var y: any = 0;

      series.push({
        x: x,
        y: y,
      });
      i++;
    }

    return series;
  }
  public actualizarData(nombredia, count) {
    var i = 0;
    var acumtot = 0;
    var acumtotent = 0;
    var acumtotart = 0;
    var acumtottrans = 0;
    var acumtovent = 0;
    var auxi = [];

    var auxil = []

    var sumatoria
    var series = [];
    var lista = [];
    var seri = this.resultadoHoras.filter((x) => {
      return x.NombreDia == nombredia;
    });


    if (this.vista == "Ventas") {
      seri.map((v) => {
        return v.Horario.map((c) => {
          return lista.push({
            x: c.Hora.toString().padStart(2, "0") + ":00",
            y: c.totalVentas,
          });
        });
      });
    } else if (this.vista == "Transacciones") {
      seri.map((v) => {
        return v.Horario.map((c) => {
          return lista.push({
            x: c.Hora.toString().padStart(2, "0") + ":00",
            y: c.numeroTransacciones,
          });
        });
      });
    } else if (this.vista == "Unidades") {
      seri.map((v) => {
        return v.Horario.map((c) => {
          return lista.push({
            x: c.Hora.toString().padStart(2, "0") + ":00",
            y: c.cantidadArticulos,
          });
        });
      });
    } else if (this.vista == "Entradas") {
      seri.map((v) => {
        return v.Horario.map((c) => {
          return lista.push({
            x: c.Hora.toString().padStart(2, "0") + ":00",
            y: c.NumeroEntradas,
          });
        });
      });
    } else if (this.vista == "VPT") {
      seri.map((v) => {
        return v.Horario.map((c) => {
          var resultado = Math.round(c.totalVentas / c.numeroTransacciones);
          if (resultado == Infinity || isNaN(resultado)) {
            resultado = 0
          }
          return lista.push({
            x: c.Hora.toString().padStart(2, "0") + ":00",
            y: resultado,
            z: c.totalVentas,
            w: c.numeroTransacciones
          });
        });
      });
    } else if (this.vista == "UPT") {
      seri.map((v) => {
        return v.Horario.map((c) => {
          var resultado = parseFloat((c.cantidadArticulos / c.numeroTransacciones).toFixed(2));
          if (resultado == Infinity || isNaN(resultado)) {
            resultado = 0
          }
          return lista.push({
            x: c.Hora.toString().padStart(2, "0") + ":00",
            y: resultado,
            z: c.cantidadArticulos,
            w: c.numeroTransacciones,

          });
        });
      });
    } else if (this.vista == "TC") {
      seri.map((v) => {
        return v.Horario.map((c) => {
          var resultado = parseFloat((((c.numeroTransacciones-c.numeroTransaccionesCarpa) / c.NumeroEntradas) * 100).toFixed(1))
          if (resultado == Infinity || isNaN(resultado)) {
            resultado = 0
          }
          return lista.push({
            x: c.Hora.toString().padStart(2, "0") + ":00",
            y: resultado,
            z: parseFloat(c.numeroTransacciones)-parseFloat(c.numeroTransaccionesCarpa),
            w: c.NumeroEntradas
          });
        });
      });
    }

    while (i < count) {
      switch (nombredia) {
        case "1":
          var x = "Total";

          break;
        case "2":
          var x = "Total";

          break;
        case "3":
          var x = "Total";

          break;
        case "4":
          var x = "Total";

          break;
        case "5":
          var x = "Total";

          break;
        case "6":
          var x = "Total";

          break;
        case "7":
          var x = "Total";

          break;
        default:
          var x = i.toString().padStart(2, "0") + ":00";
          break;
      }

      var y = 0;
      if (this.listatotalx[i] == undefined) {
        this.listatotalx.push({
          x: x,
          y: y,
        });
        if (this.vista == "VPT") {
          this.listaVentas.push({
            x: x,
            y: y
          });
          this.listaTransacciones.push({
            x: x,
            y: y
          })
        } else if (this.vista == "UPT") {
          this.listaArticulos.push({
            x: x,
            y: y
          });
          this.listaTransacciones.push({
            x: x,
            y: y
          })
        } else if (this.vista == "TC") {
          this.listaEntradas.push({
            x: x,
            y: y
          });
          this.listaTransacciones.push({
            x: x,
            y: y
          })
        }


      }
      series.push({
        x: x,
        y: y,
      });
      i++;
    }
    var f = 0;
    var j = 0;
    var u = 0
    var h = 0
    var listaux = []
    var cont = 0;
    for (f = 0; f < count; f++) {
      for (j = 0; j < lista.length; j++) {
        if (series[f].x == lista[j].x) {

          if (this.vista == "VPT") {
            this.listaVentas[f].y = this.listaVentas[f].y + lista[j].z;
            this.listaTransacciones[f].y = this.listaTransacciones[f].y + lista[j].w;
            acumtovent = acumtovent + lista[j].z;
            acumtottrans = acumtottrans + lista[j].w;
          } else if (this.vista == "UPT") {
            this.listaArticulos[f].y = this.listaArticulos[f].y + lista[j].z;
            this.listaTransacciones[f].y = this.listaTransacciones[f].y + lista[j].w;
            acumtotart = acumtotart + lista[j].z;
            acumtottrans = acumtottrans + lista[j].w;
          } else if (this.vista == "TC") {
            this.listaEntradas[f].y = this.listaEntradas[f].y + lista[j].w;
            this.listaTransacciones[f].y = this.listaTransacciones[f].y + lista[j].z;
            acumtottrans = acumtottrans + lista[j].z;
            acumtotent = acumtotent + lista[j].w;
          }

          this.listatotalx[f].y = this.listatotalx[f].y + lista[j].y;
          acumtot = acumtot + lista[j].y;

          series[f].y = lista[j].y;





          cont++;


        }

      }



    }
    var w = 0
    var listPart = [{ x: "Lunes", y: 0 }, { x: "Martes", y: 0 }, { x: "Miércoles", y: 0 }, { x: "Jueves", y: 0 }, { x: "Viernes", y: 0 }, { x: "Sábado", y: 0 }, { x: "Domingo", y: 0 }]
    var acumtotal = 0


    for (u = 0; u < 7; u++) {
      if (this.series2[u].x == nombredia) {
        this.series2[u].y = acumtot
        if (this.vista == "VPT") {
          this.series2[u].z = acumtovent
          this.series2[u].w = acumtottrans
        } else if (this.vista == "UPT") {
          this.series2[u].z = acumtotart
          this.series2[u].w = acumtottrans
        } else if (this.vista == "TC") {
          this.series2[u].z = acumtottrans
          this.series2[u].w = acumtotent
        }


      }

    }
    for (h = 0; h < 7; h++) {


      acumtotal = acumtotal + this.series2[h].y

    }
    for (w = 6; w >= 0; w--) {
      listPart[w].x = "Part"
      if (this.vista == "VPT") {
        listPart[w].y = 0
      } else if (this.vista == "UPT") {
        listPart[w].y = 0
      } else if (this.vista == "TC") {
        listPart[w].y = 0
      } else {

        listPart[w].y = Math.round(((this.series2[w].y * 100) / acumtotal))
      }
    }

    if (nombredia == "Part") {

      auxil.push(listPart[this.contador])
      this.contador = this.contador - 1
      if (this.vista == "VPT" || this.vista == "UPT" || this.vista == "TC") {
        auxil[0].y = 0
      }
      return auxil

    }
    if (nombredia == "Participacion") {

      var auxil2 = [];
      this.listatotalx.forEach(i => {
        this.suma = this.suma + i.y

      });

      this.listatotalx.forEach(p => {
        if (this.vista == "VPT" || this.vista == "UPT" || this.vista == "TC") {
          this.serieauxiliar = { x: p.x, y: 0 }
        } else {
          this.serieauxiliar = { x: p.x, y: Math.round(((p.y) * 100) / this.suma) }

        }
        auxil2.push(this.serieauxiliar)

      })

      return auxil2
      // }


    }

    if (x == "Total") {
      switch (nombredia) {
        case "1":
          this.series2[0].x = "Total"
          if (this.vista == "VPT") {
            if (this.series2[0].w != 0) {
              auxi.push({ x: this.series2[0].x, y: (this.series2[0].z / this.series2[0].w) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[0].x, y: 0 })
              return auxi;
            }

          } else if (this.vista == "UPT") {

            if (this.series2[0].w != 0) {
              auxi.push({ x: this.series2[0].x, y: parseFloat((this.series2[0].z / this.series2[0].w).toFixed(2)) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[0].x, y: 0 })
              return auxi;
            }
          } else if (this.vista == "TC") {
            if (this.series2[0].w != 0) {
              auxi.push({ x: this.series2[0].x, y: parseFloat(((this.series2[0].z * 100) / this.series2[0].w).toFixed(1)) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[0].x, y: 0 })
              return auxi;
            }
          } else {
            auxi.push({ x: this.series2[0].x, y: this.series2[0].y })
            return auxi;
          }


        case "2":
          this.series2[1].x = "Total"
          if (this.vista == "VPT") {
            if (this.series2[1].w != 0) {
              auxi.push({ x: this.series2[1].x, y: (this.series2[1].z / this.series2[1].w) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[1].x, y: 0 })
              return auxi;
            }

          } else if (this.vista == "UPT") {

            if (this.series2[1].w != 0) {
              auxi.push({ x: this.series2[1].x, y: parseFloat((this.series2[1].z / this.series2[1].w).toFixed(2)) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[1].x, y: 0 })
              return auxi;
            }
          } else if (this.vista == "TC") {
            if (this.series2[1].w != 0) {
              auxi.push({ x: this.series2[1].x, y: parseFloat(((this.series2[1].z * 100) / this.series2[1].w).toFixed(1)) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[1].x, y: 0 })
              return auxi;
            }
          } else {
            auxi.push({ x: this.series2[1].x, y: this.series2[1].y })
            return auxi;
          }

        case "3":
          this.series2[2].x = "Total"
          if (this.vista == "VPT") {
            if (this.series2[2].w != 0) {
              auxi.push({ x: this.series2[2].x, y: (this.series2[2].z / this.series2[2].w) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[2].x, y: 0 })
              return auxi;
            }

          } else if (this.vista == "UPT") {

            if (this.series2[2].w != 0) {
              auxi.push({ x: this.series2[2].x, y: parseFloat((this.series2[2].z / this.series2[2].w).toFixed(1)) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[2].x, y: 0 })
              return auxi;
            }
          } else if (this.vista == "TC") {
            if (this.series2[2].w != 0) {
              auxi.push({ x: this.series2[2].x, y: parseFloat(((this.series2[2].z * 100) / this.series2[2].w).toFixed(1)) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[2].x, y: 0 })
              return auxi;
            }
          } else {
            auxi.push({ x: this.series2[2].x, y: this.series2[2].y })
            return auxi;
          }

        case "4":
          this.series2[3].x = "Total"
          if (this.vista == "VPT") {
            if (this.series2[3].w != 0) {
              auxi.push({ x: this.series2[3].x, y: (this.series2[3].z / this.series2[3].w) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[3].x, y: 0 })
              return auxi;
            }

          } else if (this.vista == "UPT") {

            if (this.series2[3].w != 0) {
              auxi.push({ x: this.series2[3].x, y: parseFloat((this.series2[3].z / this.series2[3].w).toFixed(2)) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[3].x, y: 0 })
              return auxi;
            }
          } else if (this.vista == "TC") {
            if (this.series2[3].w != 0) {
              auxi.push({ x: this.series2[3].x, y: parseFloat(((this.series2[3].z * 100) / this.series2[3].w).toFixed(1)) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[3].x, y: 0 })
              return auxi;
            }
          } else {
            auxi.push({ x: this.series2[3].x, y: this.series2[3].y })
            return auxi;
          }


        case "5":
          this.series2[4].x = "Total"
          if (this.vista == "VPT") {
            if (this.series2[4].w != 0) {
              auxi.push({ x: this.series2[4].x, y: (this.series2[4].z / this.series2[4].w) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[4].x, y: 0 })
              return auxi;
            }

          } else if (this.vista == "UPT") {

            if (this.series2[4].w != 0) {
              auxi.push({ x: this.series2[4].x, y: parseFloat((this.series2[4].z / this.series2[4].w).toFixed(2)) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[4].x, y: 0 })
              return auxi;
            }
          } else if (this.vista == "TC") {
            if (this.series2[4].w != 0) {
              auxi.push({ x: this.series2[4].x, y: parseFloat(((this.series2[4].z * 100) / this.series2[4].w).toFixed(1)) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[4].x, y: 0 })
              return auxi;
            }
          } else {
            auxi.push({ x: this.series2[4].x, y: this.series2[4].y })
            return auxi;
          }

        case "6":
          this.series2[5].x = "Total"
          if (this.vista == "VPT") {
            if (this.series2[5].w != 0) {
              auxi.push({ x: this.series2[5].x, y: (this.series2[5].z / this.series2[5].w) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[5].x, y: 0 })
              return auxi;
            }

          } else if (this.vista == "UPT") {

            if (this.series2[5].w != 0) {
              auxi.push({ x: this.series2[5].x, y: parseFloat((this.series2[5].z / this.series2[5].w).toFixed(2)) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[5].x, y: 0 })
              return auxi;
            }
          } else if (this.vista == "TC") {
            if (this.series2[5].w != 0) {
              auxi.push({ x: this.series2[5].x, y: parseFloat(((this.series2[5].z * 100) / this.series2[5].w).toFixed(1)) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[5].x, y: 0 })
              return auxi;
            }
          } else {
            auxi.push({ x: this.series2[5].x, y: this.series2[5].y })
            return auxi;
          }
        case "7":
          this.series2[6].x = "Total"
          if (this.vista == "VPT") {
            if (this.series2[6].w != 0) {
              auxi.push({ x: this.series2[6].x, y: (this.series2[6].z / this.series2[6].w) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[6].x, y: 0 })
              return auxi;
            }

          } else if (this.vista == "UPT") {

            if (this.series2[6].w != 0) {
              auxi.push({ x: this.series2[6].x, y: parseFloat((this.series2[6].z / this.series2[6].w).toFixed(2)) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[6].x, y: 0 })
              return auxi;
            }
          } else if (this.vista == "TC") {
            if (this.series2[6].w != 0) {
              auxi.push({ x: this.series2[6].x, y: parseFloat(((this.series2[6].z * 100) / this.series2[6].w).toFixed(1)) })
              return auxi;
            } else {
              auxi.push({ x: this.series2[6].x, y: 0 })
              return auxi;
            }
          } else {
            auxi.push({ x: this.series2[6].x, y: this.series2[6].y })
            return auxi;
          }
      }
    } else if (nombredia == "Totalizado") {
      var totalizado = 0
      var sumaVentas = 0
      var sumaTransacciones = 0
      var listaVpttot = []
      var listaUpttot = []
      var listaTCtot = []
      var sumaArticulos = 0
      var sumaEntradas = 0
      var serietotalizada = []
      if (this.vista == "VPT") {
        this.listaVentas.map(x => {
          sumaVentas = sumaVentas + x.y
        })
        this.listaTransacciones.map(y => {
          sumaTransacciones = sumaTransacciones + y.y
        })
        listaVpttot.push({ x: "Totalizado", y: Math.round(sumaVentas / sumaTransacciones) })
        return listaVpttot
      } else if (this.vista == "UPT") {
        this.listaArticulos.map(x => {
          sumaArticulos = sumaArticulos + x.y
        })
        this.listaTransacciones.map(y => {
          sumaTransacciones = sumaTransacciones + y.y
        })
        listaUpttot.push({ x: "Totalizado", y: parseFloat((sumaArticulos / sumaTransacciones).toFixed(2)) })
        return listaUpttot
      } else if (this.vista == "TC") {
        this.listaEntradas.map(z => {
          sumaEntradas = sumaEntradas + z.y
        })
        this.listaTransacciones.map(y => {
          sumaTransacciones = sumaTransacciones + y.y
        })
        listaTCtot.push({ x: "Totalizado", y: parseFloat(((sumaTransacciones * 100) / sumaEntradas).toFixed(1)) })
        return listaTCtot
      }

      else {
        this.listatotalx.map(x => {
          totalizado = totalizado + x.y

        })
        serietotalizada.push({ x: "Totalizado", y: totalizado })
        return serietotalizada
      }

    } else if (nombredia != "Total") {
      // series=series.filter(o=>o.y!=0)
      this.listaExcel.push(series)
      this.contadorExcel = this.contadorExcel + 1
      return series;
    } else {
      // this.listatotalx=this.listatotalx.filter(o=>o.y!=0)
      if (this.vista == "VPT") {

        this.listaVentas.map(x => {
          this.listaTransacciones.map(y => {
            if (x.x == y.x) {
              if (y.y != 0) {
                this.listavpt.push({ x: x.x, y: (x.y / y.y) })
              } else {
                this.listavpt.push({ x: x.x, y: 0 })
              }

            }
          })
        })
        return this.listavpt
      } else if (this.vista == "UPT") {

        this.listaArticulos.map(x => {
          this.listaTransacciones.map(y => {
            if (x.x == y.x) {
              if (y.y != 0) {
                this.listaupt.push({ x: x.x, y: parseFloat((x.y / y.y).toFixed(2)) })
              } else {
                this.listaupt.push({ x: x.x, y: 0 })
              }

            }
          })
        })
        return this.listaupt
      } else if (this.vista == "TC") {

        this.listaEntradas.map(x => {
          this.listaTransacciones.map(y => {
            if (x.x == y.x) {
              if (x.y != 0) {
                this.listatc.push({ x: x.x, y: parseFloat(((y.y * 100) / x.y).toFixed(1)) })
              } else {
                this.listatc.push({ x: x.x, y: 0 })
              }

            }
          })
        })
        return this.listatc
      } else {
        return this.listatotalx;
      }

    }
  }

  onChange(event) {
    this.fechainicio = new Date(this.fechainicioStr + "T00:00");
    this.fechafin = new Date(this.fechafinStr + "T00:00");
    if (this.fechafin.getTime() >= this.fechainicio.getTime()) {
      this.difecha = this.fechainicio.getTime() - this.fechafin.getTime() + 1;
      this.dif = (this.difecha / (1000 * 3600 * 24)) * -1;

      if (this.dif <= 0) {
        this.vista = "Ar";
      } else {
        this.vista = "Ac";
      }
      return this.vista;
    } else {
      window.alert("seleccione un rango de fechas valido");
    }
  }

  onDateSelection(date: NgbDate, datepicker) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate) {
      this.toDate = date;
      datepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    this.fechainicioStr =
      this.fromDate.year.toString() +
      "-" +
      this.fromDate.month.toString().padStart(2, "0") +
      "-" +
      this.fromDate.day.toString().padStart(2, "0");
    this.fechafinStr =
      this.toDate.year.toString() +
      "-" +
      this.toDate.month.toString().padStart(2, "0") +
      "-" +
      this.toDate.day.toString().padStart(2, "0");
    this.desdehasta =
      " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr;
    this.fechainicio = new Date(this.fechainicioStr + "T00:00");
    this.fechafin = new Date(this.fechafinStr + "T00:00");
    if (this.fechafin.getTime() <= this.fechainicio.getTime()) {
      window.alert("seleccione un rango de fechas valido");
    }
  }

  /**
   * Is Hovered
   *
   * @param date
   */
  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  /**
   * Is Inside
   *
   * @param date
   */
  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  /**
   *  Is Range
   *
   * @param date
   */
  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }
  seleccionarHoy() {
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    this.fechainicioStr =
      this.fromDate.year.toString() +
      "-" +
      this.fromDate.month.toString().padStart(2, "0") +
      "-" +
      this.fromDate.day.toString().padStart(2, "0");
    this.fechafinStr =
      this.toDate.year.toString() +
      "-" +
      this.toDate.month.toString().padStart(2, "0") +
      "-" +
      this.toDate.day.toString().padStart(2, "0");
    this.desdehasta =
      " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr;
  }
  seleccionarMesActual() {
    var ano: any
    var mes: any
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    var mesActual = this.calendar.getToday().month
    this.diamess.mes.map(x => {
      if (x.Mes == mesActual.toString().padStart(2, "0")) {
        this.fechainicioStr = this.fromDate.year.toString() + "-" + this.fromDate.month.toString().padStart(2, "0") + "-" + x.diaInicio;
        this.fechafinStr = this.fromDate.year.toString() + "-" + this.fromDate.month.toString().padStart(2, "0") + "-" + x.diaFin;
        ano = (this.fromDate.year).toString()
        mes = (mesActual).toString().padStart(2, "0")
        this.fromDate = { "day": parseInt(x.diaInicio), "month": parseInt(mes), "year": parseInt(ano) }
        this.toDate = { "day": parseInt(x.diaFin), "month": parseInt(mes), "year": parseInt(ano) }
      }
    })
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr;
  }
  seleccionarAnterior() {

    var ano: any
    var mes: any
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    var mesActual = this.calendar.getToday().month
    if (mesActual.toString().padStart(2, "0") == "01") {
      mesActual = 13
    }
    this.diamess.mes.map(x => {
      if (x.Mes == (mesActual - 1).toString().padStart(2, "0")) {
        if (mesActual == 13) {
          this.fechainicioStr = (this.fromDate.year - 1).toString() + "-" + (mesActual - 1).toString().padStart(2, "0") + "-" + x.diaInicio;
          this.fechafinStr = (this.fromDate.year - 1).toString() + "-" + (mesActual - 1).toString().padStart(2, "0") + "-" + x.diaFin;
          ano = (this.fromDate.year - 1).toString()
          mes = (mesActual - 1).toString().padStart(2, "0")
          this.fromDate = { "day": parseInt(x.diaInicio), "month": parseInt(mes), "year": parseInt(ano) }
          this.toDate = { "day": parseInt(x.diaFin), "month": parseInt(mes), "year": parseInt(ano) }

        } else {
          this.fechainicioStr = this.fromDate.year.toString() + "-" + (mesActual - 1).toString().padStart(2, "0") + "-" + x.diaInicio;
          this.fechafinStr = this.toDate.year.toString() + "-" + (mesActual - 1).toString().padStart(2, "0") + "-" + x.diaFin;
          ano = this.toDate.year.toString()
          mes = (mesActual - 1).toString().padStart(2, "0")
          this.fromDate = { "day": parseInt(x.diaInicio), "month": parseInt(mes), "year": parseInt(ano) }
          this.toDate = { "day": parseInt(x.diaFin), "month": parseInt(mes), "year": parseInt(ano) }

        }

      }
    })

    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr;
  }
  seleccionarAyer() {
    this.fromDate = this.calendar.getNext(this.calendar.getToday(), "d", -1);
    this.toDate = this.calendar.getNext(this.calendar.getToday(), "d", -1);
    this.fechainicioStr =
      this.fromDate.year.toString() +
      "-" +
      this.fromDate.month.toString().padStart(2, "0") +
      "-" +
      this.fromDate.day.toString().padStart(2, "0");
    this.fechafinStr =
      this.toDate.year.toString() +
      "-" +
      this.toDate.month.toString().padStart(2, "0") +
      "-" +
      this.toDate.day.toString().padStart(2, "0");
    this.desdehasta =
      " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr;
  }
  filterTiend(event) {
    if (this.codigoTienda != "null") {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter((p) => {
        return this.codigoTienda.filter((d) => p.codigo == d).length > 0;
      });
    } else {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter((p) => {
        return p.Regional != "";
      });
    }
    this.fillFilter(this.infoTiendaFiltrada);
  }

  filterAge(event) {
    // this.infoTiendaFiltrada = this.inforTiendas.infoTienda;

    if (this.agenciaTienda != "null") {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter((p) => {
        return (
          this.agenciaTienda.filter((d) => p.Regional == d.codigoAgencia)
            .length > 0
        );
      });
    } else {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter((p) => {
        return p.Regional != "";
      });
    }

    this.fillFilter(this.infoTiendaFiltrada);
    this.filtroAplicados.push("Agencia");
  }

  filterFormat(event) {

    if (this.formatoTienda != "null") {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter((p) => {
        return this.formatoTienda.filter((d) => p.Formato == d).length > 0;
      });
    } else {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter((p) => {
        return p.Formato != "";
      });
    }
    this.fillFilter(this.infoTiendaFiltrada);
    this.filtroAplicados.push("Formato");
  }
  limpiarFiltAgen(infotienda: any = this.inforTiendas.infoTienda) {

    let arregloAgencia = [];

    if (
      this.rol == "GERENTE" &&
      !this.excepciones.join(",").includes(this.coordinador)
    ) {
      let agencia = infotienda.map((x) => {
        return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia };
      });

      agencia.forEach((a) => {
        if (
          arregloAgencia.filter(
            (b) => b.codigoAgencia.trim() == a.codigoAgencia.trim()
          ).length < 1
        ) {
          arregloAgencia.push(a);
        }
      });


      this.filtroAplicados = this.filtroAplicados.filter((p) => p != "Agencia");
      if (this.filtroAplicados.filter((p) => p == "ciudad").length > 0) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(
          (p) => p.CIUDAD == this.ciudad && p.Gerente == this.coordinador
        );
        this.ciudades = [
          ...new Set(this.infoTiendaFiltrada.map((x) => x.CIUDAD)),
        ];
        this.agenciaTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }

      if (this.filtroAplicados.filter((p) => p == "Formato").length > 0) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(
          (p) =>
            p.Formato == this.formatoTienda && p.Gerente == this.coordinador
        );
        this.formatos = [
          ...new Set(this.infoTiendaFiltrada.map((x) => x.Formato)),
        ];
        this.agenciaTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }
      if (this.filtroAplicados.length == 0) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda
        this.agenciaTienda = null;
        this.agencias = [...new Set(arregloAgencia)];
        this.fillFilter(this.infoTiendaFiltrada);
      }
    } else {
      if (
        this.rol == "COORDINADOR" &&
        !this.excepciones.join(",").includes(this.coordinador)
      ) {
        let agencia = infotienda.map((x) => {
          return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia };
        });

        agencia.forEach((a) => {
          if (
            arregloAgencia.filter(
              (b) => b.codigoAgencia.trim() == a.codigoAgencia.trim()
            ).length < 1
          ) {
            arregloAgencia.push(a);
          }
        });

        this.filtroAplicados = this.filtroAplicados.filter(
          (p) => p != "Agencia"
        );
        if (this.filtroAplicados.filter((p) => p == "ciudad").length > 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(
            (p) => p.CIUDAD == this.ciudad && p.Responsable == this.coordinador
          );
          this.ciudades = [
            ...new Set(this.infoTiendaFiltrada.map((x) => x.CIUDAD)),
          ];
          this.agenciaTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter((p) => p == "Formato").length > 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(
            (p) =>
              p.Formato == this.formatoTienda &&
              p.Responsable == this.coordinador
          );
          this.formatos = [
            ...new Set(this.infoTiendaFiltrada.map((x) => x.Formato)),
          ];
          this.agenciaTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda
          this.agenciaTienda = null;
          this.agencias = [...new Set(arregloAgencia)];
          this.fillFilter(this.infoTiendaFiltrada);
        }
      } else {
        var agencia = infotienda.map((x) => {
          return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia };
        });

        agencia.map((a) => {
          if (
            arregloAgencia.filter((p) => p.codigoAgencia == a.codigoAgencia)
              .length == 0
          )
            arregloAgencia.push(a);
        });

        this.filtroAplicados = this.filtroAplicados.filter(
          (p) => p != "Agencia"
        );
        if (this.filtroAplicados.filter((p) => p == "ciudad").length > 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter((p) => {
            return this.ciudad.filter((d) => p.CIUDAD == d).length > 0;
          });
          this.ciudades = [
            ...new Set(this.infoTiendaFiltrada.map((x) => x.CIUDAD)),
          ];
          this.agenciaTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter((p) => p == "Formato").length > 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter((p) => {
            return this.formatoTienda.filter((d) => p.Formato == d).length > 0;
          });
          this.formatos = [
            ...new Set(this.infoTiendaFiltrada.map((x) => x.Formato)),
          ];
          this.agenciaTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if ((this, this.filtroAplicados.length == 0)) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
          this.agenciaTienda = null;
          this.agencias = [...new Set(arregloAgencia)];
          this.fillFilter(this.infoTiendaFiltrada);
        }
      }
    }
  }

  limpiarFiltCiu(infotienda: any = this.inforTiendas.infoTienda) {

    if (
      this.rol == "GERENTE" &&
      !this.excepciones.join(",").includes(this.coordinador)
    ) {
      var agencia = infotienda.map((x) => {
        return {
          codigoAgencia: x.Regional,
          nombreAgencia: x.NombreAgencia,
          Gerente: x.Gerente,
        };
      });
      var arregloAgencia = [];
      agencia.map((a) => {
        if (
          arregloAgencia.filter(
            (p) =>
              p.codigoAgencia == a.codigoAgencia &&
              p.Gerente == this.coordinador
          ).length == 0
        )
          arregloAgencia.push(a);
      });

      this.filtroAplicados = this.filtroAplicados.filter((p) => p != "ciudad");
      this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
      if (this.filtroAplicados.filter((p) => p == "Agencia").length > 0) {
        this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter((p) => {
          return (
            this.agenciaTienda.filter(
              (d) =>
                p.Regional == d.codigoAgencia && p.Gerente == this.coordinador
            ).length > 0
          );
        });
        this.ciudades = [
          ...new Set(this.infoTiendaFiltrada.map((x) => x.CIUDAD)),
        ];
        this.ciudad = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }

      if (this.filtroAplicados.filter((p) => p == "Formato").length > 0) {
        this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter((p) => {
          return (
            this.formatoTienda.filter(
              (d) => p.Formato == d && p.Gerente == this.coordinador
            ).length > 0
          );
        });
        this.ciudades = [
          ...new Set(this.infoTiendaFiltrada.map((x) => x.CIUDAD)),
        ];
        this.ciudad = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }
      if (this.filtroAplicados.length == 0) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        this.ciudades = [
          ...new Set(this.infoTiendaFiltrada.map((x) => x.CIUDAD)),
        ];
        this.ciudad = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }
    } else {
      if (
        this.rol == "COORDINADOR" &&
        !this.excepciones.join(",").includes(this.coordinador)
      ) {
        this.filtroAplicados = this.filtroAplicados.filter(
          (p) => p != "ciudad"
        );
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        if (this.filtroAplicados.filter((p) => p == "Agencia").length > 0) {
          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter((p) => {
            return (
              this.agenciaTienda.filter(
                (d) =>
                  p.Regional == d.codigoAgencia &&
                  p.Responsable == this.coordinador
              ).length > 0
            );
          });
          this.ciudades = [
            ...new Set(this.infoTiendaFiltrada.map((x) => x.CIUDAD)),
          ];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter((p) => p == "Formato").length > 0) {
          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter((p) => {
            return (
              this.formatoTienda.filter(
                (d) => p.Formato == d && p.Responsable == this.coordinador
              ).length > 0
            );
          });
          this.ciudades = [
            ...new Set(this.infoTiendaFiltrada.map((x) => x.CIUDAD)),
          ];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
          this.ciudades = [
            ...new Set(this.infoTiendaFiltrada.map((x) => x.CIUDAD)),
          ];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
      } else {
        this.filtroAplicados = this.filtroAplicados.filter(
          (p) => p != "ciudad"
        );
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        if (this.filtroAplicados.filter((p) => p == "Agencia").length > 0) {
          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter((p) => {
            return (
              this.agenciaTienda.filter((d) => p.Regional == d.codigoAgencia)
                .length > 0
            );
          });
          this.ciudades = [
            ...new Set(this.infoTiendaFiltrada.map((x) => x.CIUDAD)),
          ];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter((p) => p == "Formato").length > 0) {
          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter((p) => {
            return this.formatoTienda.filter((d) => p.Formato == d).length > 0;
          });
          this.ciudades = [
            ...new Set(this.infoTiendaFiltrada.map((x) => x.CIUDAD)),
          ];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
          this.ciudades = [
            ...new Set(this.infoTiendaFiltrada.map((x) => x.CIUDAD)),
          ];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
      }
    }
  }
  limpiarFiltPv() {
    this.codigoTienda = null;
  }
  isDisabled = (date: NgbDate, current: { month: number; year: number }) =>
    date.month !== current.month;

  limpiarFiltFor(infotienda: any = this.inforTiendas.infoTienda) {

    var arregloAgencia = [];
    if (
      this.rol == "GERENTE" &&
      !this.excepciones.join(",").includes(this.coordinador)
    ) {
      var agencia = infotienda.map((x) => {
        return {
          codigoAgencia: x.Regional,
          nombreAgencia: x.NombreAgencia,
          Gerente: x.Gerente,
        };
      });

      agencia.map((a) => {
        if (
          arregloAgencia.filter(
            (p) =>
              p.codigoAgencia == a.codigoAgencia &&
              p.Gerente == this.coordinador
          ).length == 0
        )
          arregloAgencia.push(a);
      });

      this.filtroAplicados = this.filtroAplicados.filter((p) => p != "Formato");
      this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
      if (this.filtroAplicados.filter((p) => p == "Agencia").length > 0) {
        this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter((p) => {
          return (
            this.agenciaTienda.filter(
              (d) =>
                p.Regional == d.codigoAgencia && p.Gerente == this.coordinador
            ).length > 0
          );
        });
        this.formatos = [
          ...new Set(this.infoTiendaFiltrada.map((x) => x.Formato)),
        ];
        this.formatoTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }

      if (this.filtroAplicados.filter((p) => p == "ciudad").length > 0) {
        this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter((p) => {
          return (
            this.ciudad.filter(
              (d) => p.CIUDAD == d && p.Gerente == this.coordinador
            ).length > 0
          );
        });
        this.formatos = [
          ...new Set(this.infoTiendaFiltrada.map((x) => x.Formato)),
        ];
        this.formatoTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }
      if (this.filtroAplicados.length == 0) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        this.formatos = [...new Set(infotienda.map((x) => x.Formato))];
        this.formatoTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }
    } else {
      if (
        this.rol == "COORDINADOR" &&
        !this.excepciones.join(",").includes(this.coordinador)
      ) {
        this.filtroAplicados = this.filtroAplicados.filter(
          (p) => p != "Formato"
        );
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        if (this.filtroAplicados.filter((p) => p == "Agencia").length > 0) {
          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter((p) => {
            return (
              this.agenciaTienda.filter(
                (d) =>
                  p.Regional == d.codigoAgencia &&
                  p.Responsable == this.coordinador
              ).length > 0
            );
          });
          this.formatos = [
            ...new Set(this.infoTiendaFiltrada.map((x) => x.Formato)),
          ];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter((p) => p == "ciudad").length > 0) {
          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter((p) => {
            return (
              this.ciudad.filter(
                (d) => p.CIUDAD == d && p.Responsable == this.coordinador
              ).length > 0
            );
          });
          this.formatos = [
            ...new Set(this.infoTiendaFiltrada.map((x) => x.Formato)),
          ];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
          this.formatos = [...new Set(infotienda.map((x) => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
      } else {
        this.filtroAplicados = this.filtroAplicados.filter(
          (p) => p != "Formato"
        );
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        if (this.filtroAplicados.filter((p) => p == "Agencia").length > 0) {
          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter((p) => {
            return (
              this.agenciaTienda.filter((d) => p.Regional == d.codigoAgencia)
                .length > 0
            );
          });
          this.formatos = [
            ...new Set(this.infoTiendaFiltrada.map((x) => x.Formato)),
          ];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter((p) => p == "ciudad").length > 0) {
          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter((p) => {
            return this.ciudad.filter((d) => p.CIUDAD == d).length > 0;
          });
          this.formatos = [
            ...new Set(this.infoTiendaFiltrada.map((x) => x.Formato)),
          ];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
          this.formatos = [...new Set(infotienda.map((x) => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
      }
    }
  }
  enviarexceheatmap() {
    const datos = [{
      Dia: 'Lunes',
      "00:00": this.listaExcel[6][0].y,
      "01:00": this.listaExcel[6][1].y,
      "02:00": this.listaExcel[6][2].y,
      "03:00": this.listaExcel[6][3].y,
      "04:00": this.listaExcel[6][4].y,
      "05:00": this.listaExcel[6][5].y,
      "06:00": this.listaExcel[6][6].y,
      "07:00": this.listaExcel[6][7].y,
      "08:00": this.listaExcel[6][8].y,
      "09:00": this.listaExcel[6][9].y,
      "10:00": this.listaExcel[6][10].y,
      "11:00": this.listaExcel[6][11].y,
      "12:00": this.listaExcel[6][12].y,
      "13:00": this.listaExcel[6][13].y,
      "14:00": this.listaExcel[6][14].y,
      "15:00": this.listaExcel[6][15].y,
      "16:00": this.listaExcel[6][16].y,
      "17:00": this.listaExcel[6][17].y,
      "18:00": this.listaExcel[6][18].y,
      "19:00": this.listaExcel[6][19].y,
      "20:00": this.listaExcel[6][20].y,
      "21:00": this.listaExcel[6][21].y,
      "22:00": this.listaExcel[6][22].y,
      "23:00": this.listaExcel[6][23].y,
    },
    {
      Dia: 'Martes',
      "00:00": this.listaExcel[5][0].y,
      "01:00": this.listaExcel[5][1].y,
      "02:00": this.listaExcel[5][2].y,
      "03:00": this.listaExcel[5][3].y,
      "04:00": this.listaExcel[5][4].y,
      "05:00": this.listaExcel[5][5].y,
      "06:00": this.listaExcel[5][6].y,
      "07:00": this.listaExcel[5][7].y,
      "08:00": this.listaExcel[5][8].y,
      "09:00": this.listaExcel[5][9].y,
      "10:00": this.listaExcel[5][10].y,
      "11:00": this.listaExcel[5][11].y,
      "12:00": this.listaExcel[5][12].y,
      "13:00": this.listaExcel[5][13].y,
      "14:00": this.listaExcel[5][14].y,
      "15:00": this.listaExcel[5][15].y,
      "16:00": this.listaExcel[5][16].y,
      "17:00": this.listaExcel[5][17].y,
      "18:00": this.listaExcel[5][18].y,
      "19:00": this.listaExcel[5][19].y,
      "20:00": this.listaExcel[5][20].y,
      "21:00": this.listaExcel[5][21].y,
      "22:00": this.listaExcel[5][22].y,
      "23:00": this.listaExcel[5][23].y,
    }, {
      Dia: 'Miércoles',
      "00:00": this.listaExcel[4][0].y,
      "01:00": this.listaExcel[4][1].y,
      "02:00": this.listaExcel[4][2].y,
      "03:00": this.listaExcel[4][3].y,
      "04:00": this.listaExcel[4][4].y,
      "05:00": this.listaExcel[4][5].y,
      "06:00": this.listaExcel[4][6].y,
      "07:00": this.listaExcel[4][7].y,
      "08:00": this.listaExcel[4][8].y,
      "09:00": this.listaExcel[4][9].y,
      "10:00": this.listaExcel[4][10].y,
      "11:00": this.listaExcel[4][11].y,
      "12:00": this.listaExcel[4][12].y,
      "13:00": this.listaExcel[4][13].y,
      "14:00": this.listaExcel[4][14].y,
      "15:00": this.listaExcel[4][15].y,
      "16:00": this.listaExcel[4][16].y,
      "17:00": this.listaExcel[4][17].y,
      "18:00": this.listaExcel[4][18].y,
      "19:00": this.listaExcel[4][19].y,
      "20:00": this.listaExcel[4][20].y,
      "21:00": this.listaExcel[4][21].y,
      "22:00": this.listaExcel[4][22].y,
      "23:00": this.listaExcel[4][23].y,
    }, {
      Dia: 'Jueves',
      "00:00": this.listaExcel[3][0].y,
      "01:00": this.listaExcel[3][1].y,
      "02:00": this.listaExcel[3][2].y,
      "03:00": this.listaExcel[3][3].y,
      "04:00": this.listaExcel[3][4].y,
      "05:00": this.listaExcel[3][5].y,
      "06:00": this.listaExcel[3][6].y,
      "07:00": this.listaExcel[3][7].y,
      "08:00": this.listaExcel[3][8].y,
      "09:00": this.listaExcel[3][9].y,
      "10:00": this.listaExcel[3][10].y,
      "11:00": this.listaExcel[3][11].y,
      "12:00": this.listaExcel[3][12].y,
      "13:00": this.listaExcel[3][13].y,
      "14:00": this.listaExcel[3][14].y,
      "15:00": this.listaExcel[3][15].y,
      "16:00": this.listaExcel[3][16].y,
      "17:00": this.listaExcel[3][17].y,
      "18:00": this.listaExcel[3][18].y,
      "19:00": this.listaExcel[3][19].y,
      "20:00": this.listaExcel[3][20].y,
      "21:00": this.listaExcel[3][21].y,
      "22:00": this.listaExcel[3][22].y,
      "23:00": this.listaExcel[3][23].y,
    }, {
      Dia: 'Viernes',
      "00:00": this.listaExcel[2][0].y,
      "01:00": this.listaExcel[2][1].y,
      "02:00": this.listaExcel[2][2].y,
      "03:00": this.listaExcel[2][3].y,
      "04:00": this.listaExcel[2][4].y,
      "05:00": this.listaExcel[2][5].y,
      "06:00": this.listaExcel[2][6].y,
      "07:00": this.listaExcel[2][7].y,
      "08:00": this.listaExcel[2][8].y,
      "09:00": this.listaExcel[2][9].y,
      "10:00": this.listaExcel[2][10].y,
      "11:00": this.listaExcel[2][11].y,
      "12:00": this.listaExcel[2][12].y,
      "13:00": this.listaExcel[2][13].y,
      "14:00": this.listaExcel[2][14].y,
      "15:00": this.listaExcel[2][15].y,
      "16:00": this.listaExcel[2][16].y,
      "17:00": this.listaExcel[2][17].y,
      "18:00": this.listaExcel[2][18].y,
      "19:00": this.listaExcel[2][19].y,
      "20:00": this.listaExcel[2][20].y,
      "21:00": this.listaExcel[2][21].y,
      "22:00": this.listaExcel[2][22].y,
      "23:00": this.listaExcel[2][23].y,
    }, {
      Dia: 'Sábado',
      "00:00": this.listaExcel[1][0].y,
      "01:00": this.listaExcel[1][1].y,
      "02:00": this.listaExcel[1][2].y,
      "03:00": this.listaExcel[1][3].y,
      "04:00": this.listaExcel[1][4].y,
      "05:00": this.listaExcel[1][5].y,
      "06:00": this.listaExcel[1][6].y,
      "07:00": this.listaExcel[1][7].y,
      "08:00": this.listaExcel[1][8].y,
      "09:00": this.listaExcel[1][9].y,
      "10:00": this.listaExcel[1][10].y,
      "11:00": this.listaExcel[1][11].y,
      "12:00": this.listaExcel[1][12].y,
      "13:00": this.listaExcel[1][13].y,
      "14:00": this.listaExcel[1][14].y,
      "15:00": this.listaExcel[1][15].y,
      "16:00": this.listaExcel[1][16].y,
      "17:00": this.listaExcel[1][17].y,
      "18:00": this.listaExcel[1][18].y,
      "19:00": this.listaExcel[1][19].y,
      "20:00": this.listaExcel[1][20].y,
      "21:00": this.listaExcel[1][21].y,
      "22:00": this.listaExcel[1][22].y,
      "23:00": this.listaExcel[1][23].y,
    }, {
      Dia: 'Domingo',
      "00:00": this.listaExcel[0][0].y,
      "01:00": this.listaExcel[0][1].y,
      "02:00": this.listaExcel[0][2].y,
      "03:00": this.listaExcel[0][3].y,
      "04:00": this.listaExcel[0][4].y,
      "05:00": this.listaExcel[0][5].y,
      "06:00": this.listaExcel[0][6].y,
      "07:00": this.listaExcel[0][7].y,
      "08:00": this.listaExcel[0][8].y,
      "09:00": this.listaExcel[0][9].y,
      "10:00": this.listaExcel[0][10].y,
      "11:00": this.listaExcel[0][11].y,
      "12:00": this.listaExcel[0][12].y,
      "13:00": this.listaExcel[0][13].y,
      "14:00": this.listaExcel[0][14].y,
      "15:00": this.listaExcel[0][15].y,
      "16:00": this.listaExcel[0][16].y,
      "17:00": this.listaExcel[0][17].y,
      "18:00": this.listaExcel[0][18].y,
      "19:00": this.listaExcel[0][19].y,
      "20:00": this.listaExcel[0][20].y,
      "21:00": this.listaExcel[0][21].y,
      "22:00": this.listaExcel[0][22].y,
      "23:00": this.listaExcel[0][23].y,
    }]
    this.excelService.exportAsExcelFile(datos, 'KPI');

  }
}
