import { Component, OnInit } from '@angular/core'
import { FileUploader } from 'ng2-file-upload';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ExcelService } from 'app/services/excelservice';
import * as XLSX from 'xlsx'
import { Router } from '@angular/router';
import { ServiceService } from 'app/services/service.service';

import { DiasMes } from 'app/mock/diasmes';
import { swalProviderToken } from '@sweetalert2/ngx-sweetalert2/lib/di';
const URL = 'https://your-url.com';
@Component({
  selector: 'app-subir-datos-reposicion-pedido',
  templateUrl: './subir-datos-reposicion-pedido.component.html',
  styleUrls: ['./subir-datos-reposicion-pedido.component.scss']
})
export class SubirDatosReposicionPedidoComponent implements OnInit {

  nombre: string;
  info: any[];
  superficie: any[];
  flag: number;
  value: any;
  errors: any[] = [];
  flag1: number;
  flag2: number;
  nombreMes: any;
  nombretienda: string;
  anio: number;
  listaConsulta: any=[];
  factorEstacional: any;
  tabla: any[];





  constructor(private router: Router, private diasmes: DiasMes, public excelService: ExcelService, public service: ServiceService) { }

  public contentHeader: object
  jsonConvertido!: string;
  public uploader: FileUploader = new FileUploader({
    url: URL,
    isHTML5: true
  });
  loading: boolean = false;
  data: any[];
  descripcion: string;
  porsubir: boolean = false;
  subio: boolean = false;
  nosubio: boolean = false;
  numpv: number = 0;
  
  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.numpv = 0
    this.nombretienda = sessionStorage.getItem('tienda');
    if (this.nombretienda == null) {
      this.router.navigate(['../pages/authentication/auth-login-v2/auth-login-v2.component.html'])
      return
    }
    this.nombre = sessionStorage.getItem('responsable').toUpperCase();

    if (this.nombre == 'JOSE MARIO TAMAYO LOPERA') {
      

    }
    sessionStorage.setItem("nr", this.numpv.toString())



    // console.log(this.info)
    this.contentHeader = {
      headerTitle: 'Home',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/home'
          },
          {
            name: 'Datos Reposicion',
            isLink: false
          }
        ]
      }
    }

  }
  enviarexceventas():void {

    const datos:any= [{
      REFERENCIA:"",
      TALLA:"",
      COLOR:"",
      INVENTARIOTOTALPV:"",
      VENTASPROMEDIO:""
    }]
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datos);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      
      XLSX.writeFile(wb, 'export.xlsx');
  }
  questionBeforeOpen() {
    Swal.fire('Adjunto correctamente', 'Adjunto correctamente', 'success');
  }
  questionBeforeOpen2() {
    Swal.fire('Subida Completa', this.descripcion, 'success');
  }
  questionBeforeOpen3() {
    Swal.fire('Error al Adjuntar', 'Recuerde que el formato de la columna FECHA del archivo deben ser de tipo TEXTO con el formato YYYYMMDD', 'error');
  }
  enviar() {
    if (this.flag == 1) {
      Swal.fire('No permitido', 'No se permite subir archivos con errores', 'error');
    } else {


      this.loading = true

    }
  }
  remove(item) {
    this.flag = 0
    this.value = null;
    item.remove()
  }
  calcularValor(item: any): number {
    const resultado = (item.VENTASPROMEDIO / 30) * 25 * item.PROMEDIOFACTORESTACIONAL - item.INVENTARIOTOTALPV;
    const resultadoRedondeado=Math.round(resultado)
    if (resultadoRedondeado < 0) {
      return 0;
    } else if (resultadoRedondeado > 0 && resultadoRedondeado < 1) {
      return 1;
    } else {
      return resultadoRedondeado;
    }
  }

  fileUpload(event: any) {
    this.loading=true
    let datosConsultar:any={}
    // console.log(event.target.files);
    const selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(selectedFile);
    fileReader.onload = (event) => {
      // console.log(event);
      let binaryData = event.target.result;
      let workbook = XLSX.read(binaryData, { type: 'binary' })
      workbook.SheetNames.forEach(sheet => {
        const data2 = XLSX.utils.sheet_to_html(workbook.Sheets[sheet])
        this.data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
        console.log(this.data)
        let cont=1
        this.errors=[]
        this.data.map(x => {
          
          cont++
          if (x.REFERENCIA===""|| x.TALLA === "" || x.COLOR === "" ||x.REFERENCIA==undefined|| x.TALLA == undefined || x.COLOR == undefined || x.INVENTARIOTOTALPV === "" || x.VENTASPROMEDIO === "" ) {
            
            this.errors.push(cont)
            
            // console.log(this.errors)
          }
        })
        if (this.errors.length>0){
            this.subio = false
            this.porsubir = false
            this.nosubio = true
            Swal.fire('Error al adjuntar', 'los campos: ' + this.errors.map(c => { return c + ' ' }) + ' , contienen errores. Verifique que todos los campos esten llenos correctamente', 'error');
        }else{
          this.subio = true
            this.porsubir = false
            this.nosubio = false
        this.data.forEach(element => {
          datosConsultar={
            Referencia:element.REFERENCIA.toString(),
            Talla:element.TALLA.toString().trim(),
            Color:element.COLOR.toString()
          }
          this.listaConsulta.push(datosConsultar)
        });
        this.service.getFactorEstacional(this.listaConsulta).then(res=>{
          console.table(res)
          if(res){
            this.tabla=this.data.filter(x=>{
              return res.map(y=>{
                if(y.Referencia==x.REFERENCIA && y.Talla==x.TALLA && y.Color==x.COLOR){
                  x.PROMEDIOFACTORESTACIONAL=y.PromedioFactorEstacional
                  Swal.fire('Hecho', 'Se calculo correctamente la reposicion para las referencias indicadas en tu archivo', 'success');
                  this.loading=false
                  return x
                }
              })
            })
            this.factorEstacional=res
          }else{
            Swal.fire('Error', 'Error al calcular la reposicion sugerida', 'error');
            this.loading=false
          }
          
         
        })
        let cont = 1
        this.flag = 0
        let keys: any = Object.keys(this.data[0])
        let values: any = Object.values(this.data[0])
        // console.log(keys);
        // console.log(values)
        
      }
      })
      console.log(workbook)
    }

  }
  exportarExcel(): void {
    this.tabla.map(x=>{
      x.REOPOS=this.calcularValor(x)
    })
    // Crear un nuevo libro de Excel
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // Convertir el JSON en una hoja de Excel
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.tabla);
    // Agregar la hoja al libro
    XLSX.utils.book_append_sheet(wb, ws, 'Datos');
    // Guardar el archivo
    XLSX.writeFile(wb, 'datos_tabla.xlsx');
  }

}
