<div class="content-wrapper container-xxl p-0">

  <div class="content-body">
    <!-- content-header component -->

    <app-content-header [contentHeader]="contentHeader">


    </app-content-header>
    <div class="card">
      <div class="row" style="margin:1rem ;">
        <div class="col-sm-2">
          <h3><label for="">Fecha Venta Inicial</label></h3>
          <br>
          <input #fechaInputIni id="fechaInicial" type="date" class="form-control">
        </div>
        <div class="col-sm-2">
          <h3><label for="">Fecha Venta Final</label></h3>
          <br>
          <input #fechaInputFin id="fechaFinal" type="date" class="form-control">
        </div>
        <div class="col-sm-3">
          <div class="row">

            <div class="columy" style="padding-left: unset;padding-right: unset;">
              <h3><label for="">Punto de Venta</label></h3>
              <br>
              <ng-select [clearSearchOnAdd]="true" [items]="tiendas" [clearable]="true" [multiple]="true"
                [closeOnSelect]="false" [searchable]="true" bindLabel="Nombre" placeholder="Seleccione PV"
                [(ngModel)]="codigoTienda">
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label" [value]="item.Codigo"> {{ item.Nombre }}</span>
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>
              </ng-select>


            </div>

          </div>

        </div>
        <div class="col-md-2">
          <h3><label for=""></label></h3>
          <br>
          <button class="btn btn-primary" (click)="clic()">
            <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>Consultar
          </button>
        </div>
        <div class="col-md-3">
          <h3><label></label></h3>
          <br>
          <button class="btn btn-primary" (click)="clic1()">
            <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>Consultar Diferencias
          </button>
        </div>


      </div>


    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="card">


          <div class="row" style="margin:1rem ;">
            <div class="col-sm-12">
              <label for=""><h5>Puntos de Venta Con Comprobantes</h5></label>
              <div class="table-responsive">
                <table class="table table-hover-animation">
                  <thead>
                    <tr>
                      <th>Codigo PV</th>
                      <th>Nombre PV</th>
                      <th>Detalle PV</th>
                    </tr>

                  </thead>
                  <tbody>
                    <tr *ngFor="let item of consignaciones;let i = index">
                      <td colspan="0">{{item.codigoAlmacen}}</td>
                      <td colspan="1">{{item.nombreAlmacen}}</td>
                      <td colspan="2"><button class="btn btn-primary" (click)="openLg(detalle,i)">+</button>



                      </td>

                    </tr>



                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card">


          <div class="row" style="margin:1rem ;">
            <div class="col-sm-12">
              <label for=""><h5>Puntos de Venta sin Actualizar Comprobantes</h5></label>
              <div class="table-responsive">
                <table class="table table-hover-animation">
                  <thead>
                    <tr>
                      <th>Codigo PV</th>
                      <th>Nombre PV</th>
                      
                    </tr>
                  </thead>
                  <tbody>

                    <tr *ngFor="let item of noSubidas">
                      <td>{{item.Codigo}}</td>
                      <td>{{item.Nombre}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>

  <ng-template #detalle let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Detalles de Fechas</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">


        <table *ngIf="mostrarDetalleFechas" class="table table-hover-animation">
          <thead>
            <tr>
              <th>id</th>
              <th>Fecha Comprobante</th>
              <th>Detalle Comprobante</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item1 of consolidados;let i1=index">
              <td colspan="0">{{i1}}</td>
              <td colspan="1">{{item1.fechaCreacion}}</td>
              <td colspan="2"><button class="btn btn-primary" (click)="openLg2(detalleComprobante,i1)">ver
                  Comprobante</button>



              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">
        Ok
      </button>
    </div>
  </ng-template>
  <ng-template #detalleComprobante let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Detalles de Fechas</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">

        <div class="table-responsive">
          <table *ngIf="mostrarDetalleComprobante" class="table table-hover-animation">
            <thead>
              <tr>
                <th>Id</th>
                <th>Banco</th>
                <th>Codigo</th>
                <th>Convenio</th>
                <th>Diferencia</th>
                <th>Fecha Pago</th>
                <th>Hora Pago</th>
                <th>Fecha POS Inicial</th>
                <th>Fecha POS Final</th>
                <th>Referencia</th>
                <th>Valor</th>
                <th>Valor POS</th>
                <th>Observaciones</th>
                <th>Comprobante</th>


              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item2 of comprobantes; let i2 = index">
                <td>{{item2.item}}</td>
                <td>{{item2.banco}}</td>
                <td>{{item2.codigo}}</td>
                <td>{{item2.convenio}}</td>
                <td>{{item2.diferencia | currency
                  : '$' :
                  'symbol' : '1.0-0'}}</td>
                <td class="fecha-celda">{{item2.fechaPago | date:'yyyy-MM-dd'}}</td>
                <td class="fecha-celda">{{item2.horaPago | date:'HH:mm a'}}</td>
                <td class="fecha-celda">{{item2.fechaComparacionInicial | date:'yyyy-MM-dd'}}</td>
                <td class="fecha-celda">{{item2.fechaComparacionFinal | date:'yyyy-MM-dd'}}</td>
                <td>{{item2.referencia}}</td>

                <td>{{item2.valor | currency
                  : '$' :
                  'symbol' : '1.0-0'}}</td>
                <td>{{(item2.valor + item2.diferencia) | currency
                  : '$' :
                  'symbol' : '1.0-0'}}</td>
                <td>{{item2.observaciones}}</td>
                <!-- <td><img [src]="item2.comprobante" style="cursor: pointer;" [width]="100"
                    (click)="openLg3(imagenTamanoCompleto,i2)"></td> -->
                <td>
                  <button class="btn btn-primary" (click)="openLg3(imagenTamanoCompleto,i2)"><span *ngIf="loading1" class="spinner-border spinner-border-sm"></span>ver
                    Imagen</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">
        Ok
      </button>
    </div>
  </ng-template>
  <ng-template #imagenTamanoCompleto let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Imagen</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <img [src]="image">
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">
        Ok
      </button>
    </div>
  </ng-template>