import { CoreMenu } from '@core/types'

export const menuPanama: 
CoreMenu[] = [
  
    {
        id: 'informePanama',
        title: 'Indicadores Panama',
        translate: 'MENU.PANAMA',
        type: 'item',
        icon: 'layers',
        url: 'panamaComponent'
      }
  
]
