<div class="content-wrapper container-xxl p-0">

  <div class="content-body">


    <app-content-header [contentHeader]="contentHeader">

    </app-content-header>


    <section id="home-page">
      <section class="range-selection-DP">

        <div class="row">
          <div class="col-12">
            <div class="card">

              <div class="card-body">

              
                <div class="card">
                  <div class="row">

                    <div class="col-12">
                      <div class="row">
                        
                        <div class="col">
                          

                          <button class="btn btn-flat-success" (click)="enviarexceventas()" rippleEffect><i
                              data-feather='file-text'></i> Descargar Plantilla Presupuestos</button>
                          <swal  (beforeOpen)="questionBeforeOpen()"> </swal>
                          <swal  (beforeOpen)="questionBeforeOpen2()"> </swal>
                          <swal  (beforeOpen)="questionBeforeOpen3()"> </swal>
                        </div>
                      </div>
                      <br>
                      <span style="font-weight: bolder;">Subir Presupuesto</span>

                      <fieldset class="form-group">
                        
                        <div class="custom-file">
                          <input type="file"  class="custom-file-input"
                            (change)="fileUpload($event)" ng2FileSelect [uploader]="uploader" id="file-upload-single"
                            accept=".xls,.xlsx" />
                          <label class="custom-file-label">Seleccione el archivo</label>
                        </div>
                      </fieldset>
                      <div class="col-lg-12 overflow-auto">
                        <div class="d-flex justify-content-between mb-1">
                          
                          <p>archivos: {{ uploader?.queue?.length }}</p>
                        </div>

                        <table class="table">
                          <thead>
                            <tr>
                              <th width="50%">Nombre</th>
                              <th>Tamaño</th>
                              
                              <th>Estado</th>
                              <th>Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of uploader.queue">
                              <td>
                                <strong>{{ item?.file?.name }}</strong>
                              </td>
                              <td *ngIf="uploader.options.isHTML5" nowrap>
                                {{ item?.file?.size / 1024 / 1024 | number: '.2' }} MB
                              </td>
                              
                              <td >
                                <div *ngIf="porsubir" class="badge badge-glow badge-warning">Pendiente por carga</div>
                                <div *ngIf="subio" class="badge badge-glow badge-success">Cargado en la Base de datos</div>
                                <div *ngIf="nosubio" class="badge badge-glow badge-danger">Error al cargar</div>
                              </td>
                              <td nowrap>
                                <button type="button" class="btn btn-raised btn-outline-success btn-sm mr-50"
                                  (click)="enviar()"
                                  [disabled]="item.isReady || item.isUploading || item.isSuccess" rippleEffect>
                                  <span *ngIf="loading" class="spinner-border spinner-border-sm"></span><span data-feather="upload"></span>Subir
                                </button>
                                <button type="button" class="btn btn-raised btn-outline-warning btn-sm mr-50"
                                  (click)="item.cancel()" [disabled]="!item.isUploading" rippleEffect>
                                  <i data-feather="slash"></i>Cancelar
                                </button>
                                <button type="button" class="btn btn-raised btn-outline-danger btn-sm"
                                  (click)="item.remove()" rippleEffect>
                                  <i data-feather="trash"></i> Eliminar
                                </button>
                              </td>
                            </tr>
                          </tbody>

                        </table>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </section>
    
  </div>
</div>