import { CoreMenu } from '@core/types'

export const menuPeru: 
CoreMenu[] = [
  
    {
        id: 'informePeru',
        title: 'Indicadores Peru',
        translate: 'MENU.PERU',
        type: 'item',
        icon: 'flag',
        url: 'peruComponent'
      }
  
]
