import { Component, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { ExcelService } from 'app/services/excelservice';
import { ServiceService } from 'app/services/service.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as XLSX from 'xlsx'
const URL = 'https://your-url.com';
@Component({
  selector: 'app-subirppto',
  templateUrl: './subirppto.component.html',
  styleUrls: ['./subirppto.component.scss']
})
export class SubirpptoComponent implements OnInit {
  public contentHeader: object
  jsonConvertido!:string;
  public uploader: FileUploader = new FileUploader({
    url: URL,
    isHTML5: true
  });
  loading: boolean=false;
  data: any[];
  descripcion: string;
  porsubir: boolean=false;
  subio:boolean=false;
  nosubio:boolean=false;
  constructor(public excelService: ExcelService,public service:ServiceService) { }

  ngOnInit(): void {
    Swal.fire('iniciando')
    this.contentHeader = {
      headerTitle: 'SubirPpto',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'SubirPpto',
            isLink: false,
            link: '/'
          },
          {
            name: 'SubirPpto',
            isLink: false
          }
        ]
      }
    }
  }
  enviarexceventas() {
    const datos = [{
      PUNTO_DE_VENTA: '',
      ID_de_la_Tienda: '',
      Fecha: '',
      Monto_RDL: '',
      Monto_fisico: '',
      PptoParticipacionDiaria:'',
      Ppto_VPT: '',
      Ppto_UPT: '',
      Ppto_PP: '',
      Ppto_TC: '',
      Ppto_trans: '',
      Ppto_unid: '',
      Ppto_entradas: '',
      Ppto_credito_pesos: '',
      Ppto_credito_numero: '',
      Prcnt_validas:'',
      Prcnt_Comodin:''
      
    }]
    this.excelService.exportAsExcelFile(datos, 'Ventas');
  }
  questionBeforeOpen() {
    Swal.fire('Adjunto correctamente','Recuerde que el formato de la columna FECHA del archivo deben ser de tipo TEXTO con el formato YYYYMMDD','success');
  }
  questionBeforeOpen2() {
    Swal.fire('Subida Completa',this.descripcion,'success');
  }
  questionBeforeOpen3() {
    Swal.fire('Error al Adjuntar','Recuerde que el formato de la columna FECHA del archivo deben ser de tipo TEXTO con el formato YYYYMMDD','error');
  }
  enviar(){
    this.loading=true
    
    this.service.guardarPpto(this.data).then(res=>{
      console.log(res)
      if(res.codigo=="0"){
        this.descripcion=res.descripcion
        this.subio=true
        this.porsubir=false
        this.nosubio=false
        this.questionBeforeOpen2()
      }else{
        this.subio=false
        this.porsubir=false
        this.nosubio=true
        this.questionBeforeOpen3()
      }
      this.loading=false
    })
  }
  
  fileUpload(event:any){
    this.questionBeforeOpen()
    console.log(event.target.files);
    const selectedFile=event.target.files[0];
    const fileReader= new FileReader();
    fileReader.readAsBinaryString(selectedFile);
    fileReader.onload=(event)=>{
      console.log(event);
      let binaryData=event.target.result;
      let workbook=XLSX.read(binaryData,{type:'binary'})
      workbook.SheetNames.forEach(sheet=>{
        const data2=XLSX.utils.sheet_to_html(workbook.Sheets[sheet])
        this.data=XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
        console.log(this.data)
        this.jsonConvertido=JSON.stringify(this.data,undefined,4)
        this.subio=false
        this.porsubir=true
        this.nosubio=false
      })
      console.log(workbook)
    }

  }

}
