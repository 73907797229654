<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"> </app-content-header>
    <section id="home-page">
      <section class="range-selection-DP">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">Informe por Hora</div>
              <div class="card-body">
                <div class="card">
                  <div class="row">
                    <div class="col-12">
                      <span style="font-weight: bolder; margin-left: 1rem;">Fechas</span>
                      <div class="row" style="margin-left:1rem;">
                        <form class="form-inline">
                          <div class="form-group">
                            <div class="input-group w-0">
                              <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                                [autoClose]="'outside'" [showWeekNumbers]="false"
                                (dateSelect)="onDateSelection($event, datepicker)" [displayMonths]="1" [dayTemplate]="t"
                                outsideDays="visible" [startDate]="fromDate!" tabindex="-1" />
                              <ng-template #t let-date let-currentMonth="currentMonth" let-selected="selected"
                                let-focused="focused">
                                <span class="custom-day" [class.focused]="focused"
                                  [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled"
                                  [class.range]="isRange(date)" [class.faded]="
                                  isHovered(date) || isInside(date)
                                " (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                  {{ date.day }}
                                </span>
                              </ng-template>
                            </div>
                          </div>

                          <div class="form-group">
                            <div class="input-group">
                              <input style="display: none" #dpFromDate class="form-control" placeholder="yyyy-mm-dd"
                                name="dpFromDate" [value]="formatter.format(fromDate)" (input)="
                                fromDate = validateInput(
                                  fromDate,
                                  dpFromDate.value
                                )
                              " disabled />
                              <div class="input-group fecha">
                                <input class="fecha" type="text" [value]="desdehasta" name="" id="" disabled />
                              </div>
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary feather icon-calendar"
                                  (click)="datepicker.toggle()" type="button" rippleEffect></button>
                              </div>
                            </div>
                          </div>
                          <div class="form-group ml-sm-2" style="display: none">
                            <div class="input-group">
                              <input #dpToDate class="form-control" placeholder="yyyy-mm-dd" name="dpToDate"
                                [value]="formatter.format(toDate)" disabled />
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary feather" (click)="datepicker.toggle()"
                                  type="button" rippleEffect disabled></button>
                              </div>
                            </div>
                          </div>
                        </form>

                        <div class="row" style="display:contents ;">
                          <div class="columnbut">
                            <button class="btn btn-outline-primary btn-sm m-2 float-left"
                              (click)="seleccionarAnterior()" rippleEffect>
                              Seleccionar Mes Anterior
                            </button>
                          </div>
                          <div class="columnbut">
                            <button class="btn btn-outline-primary btn-sm m-2 float-left"
                              (click)="seleccionarMesActual()" rippleEffect>
                              Seleccionar Mes Actual
                            </button>
                          </div>
                          <div class="columnbut">

                            <button class="btn btn-outline-primary btn-sm m-2 float-left" (click)="seleccionarHoy()"
                              rippleEffect>
                              Seleccionar Hoy</button>

                          </div>

                          <div class="columnbut">

                            <button class="btn btn-outline-primary btn-sm m-2 float-left" (click)="seleccionarAyer()"
                              rippleEffect>
                              Seleccionar Ayer</button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" style="display: inline-flex">
                <div class="column" style="width: 20%">
                  <div class="row">
                    <span *ngIf="mostrarfiltros" style="font-weight: bolder">Agencia</span>
                    <div *ngIf="mostrarfiltros" class="columy" style="padding-left: unset; padding-right: unset">
                      <ng-select [clearSearchOnAdd]="true" [clearable]="false" (blur)="filterAge($event)"
                        [disabled]="deshabilitado" [items]="agencias" [multiple]="true" [closeOnSelect]="false"
                        [searchable]="true" bindLabel="nombreAgencia" placeholder="Seleccione Agencias"
                        [(ngModel)]="agenciaTienda">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label" [value]="item.codigoAgencia">
                            {{ item.nombreAgencia }}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>
                    </div>
                    <div *ngIf="mostrarfiltros" class="columx" style="padding-left: unset; padding-right: unset">
                      <span *ngIf="!deshabilitado"><button *ngIf="mostrarCampo" type="button"
                          class="btn btn-flat-danger" rippleEffect (click)="limpiarFiltAgen()" title="limpiar filtro">
                          X
                        </button></span>
                    </div>
                  </div>
                </div>
                <div class="column" style="width: 20%">
                  <div class="row">
                    <span *ngIf="mostrarfiltros" style="font-weight: bolder">Formato</span>
                    <div *ngIf="mostrarfiltros" class="columy" style="padding-left: unset; padding-right: unset">
                      <ng-select [clearSearchOnAdd]="true" [clearable]="false" (blur)="filterFormat($event)"
                        [items]="formatos" [multiple]="true" [closeOnSelect]="false" [searchable]="true"
                        bindLabel="item" placeholder="Seleccione formatos" [(ngModel)]="formatoTienda">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label" [value]="item">
                            {{ item }}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>
                    </div>
                    <div *ngIf="mostrarfiltros" class="columx" style="padding-left: unset; padding-right: unset">
                      <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger" rippleEffect
                        (click)="limpiarFiltFor()" title="limpiar filtro">
                        X
                      </button>
                    </div>
                  </div>
                </div>
                <div class="column" style="width: 20%">
                  <div class="row">
                    <span *ngIf="mostrarfiltros" style="font-weight: bolder">Ciudad</span>
                    <div *ngIf="mostrarfiltros" class="columy" style="padding-left: unset; padding-right: unset">
                      <ng-select [clearable]="false" (blur)="filterPV($event)" [items]="ciudades" [multiple]="true"
                        [closeOnSelect]="false" [searchable]="true" [clearSearchOnAdd]="true" bindLabel="item"
                        placeholder="Seleccione ciudad" [(ngModel)]="ciudad">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label" [value]="item">
                            {{ item }}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>
                    </div>
                    <div *ngIf="mostrarfiltros" class="columx" style="padding-left: unset; padding-right: unset">
                      <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger" rippleEffect
                        (click)="limpiarFiltCiu()" title="limpiar filtro">
                        X
                      </button>
                    </div>
                  </div>
                </div>
                <div class="column" style="width: 40%">
                  <div class="row">
                    <div *ngIf="mostrarfiltros" class="columy" style="padding-left: unset; padding-right: unset">
                      <span style="font-weight: bolder">Punto Venta</span>

                      <ng-select [clearSearchOnAdd]="true" [items]="tiendas" [clearable]="false" [multiple]="true"
                        [closeOnSelect]="false" [searchable]="true" bindLabel="Nombre" placeholder="Seleccione PV"
                        [(ngModel)]="codigoTienda">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label" [value]="item.Codigo">
                            {{ item.Nombre }}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>
                    </div>
                    <div *ngIf="mostrarfiltros" class="columx" style="padding-left: unset; padding-right: unset">
                      <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger" rippleEffect
                        (click)="limpiarFiltPv()" title="limpiar filtro">
                        X
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <button [disabled]="loading" class="btn btn-relief-primary" tabindex="4" rippleEffect
                (click)="buscarDatos()">
                <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>Consultar
              </button>
            </div>
          </div>
        </div>
      </section>
      <div>


      </div>

      <div class="card" *ngIf="mostrarvariable">
        <div class="row sdiv" style="margin: 1rem">
          <div class="col-12">
            <span style="font-weight: bolder">Variable:</span>
            <select name="select" [(ngModel)]="vista" (change)="chartOptionss()"
              class="comp form-control form-control-sm" aria-placeholder="Compara con">
              <option value="Ventas">Ventas</option>
              <option value="Transacciones">Transacciones</option>
              <option value="Unidades">Unidades</option>
              <option value="Entradas">Entradas</option>
              <option value="VPT">VPT</option>
              <option value="UPT">UPT</option>
              <option value="TC">TC</option>
            </select>
          </div>
        </div>
      </div>
      <h6 class="mt-2" style="font-size: 10px;">Ultima sincronizacion ventas:
        {{fechasincro | date:'MMM d, y, h:mm a'}}</h6>
      <h6 class="mt-2" style="font-size: 10px;margin-top: -0.5rem !important">Ultima sincronizacion Entradas:
        {{fechasincro | date:'MMM d, y, h:mm a'}}</h6>
      <div class="card" style="overflow:hidden ;">
        <div class="row ">
          <div class="col-12">
            <div class="row">

              <div class="col-1">
                <!-- <apx-chart [series]="chartOptions7.series" [chart]="chartOptions7.chart"
                  [dataLabels]="chartOptions7.dataLabels" [colors]="chartOptions7.colors"
                  [tooltip]="chartOptions7.tooltip">
                </apx-chart> -->
                <div class="col-12" style="margin-top:3rem ;margin-left: 1rem;">
                    <thead>
                      <tr ><th>Lunes</th></tr>
                      <tr style="line-height:450% ;"><th>Martes</th></tr>
                      <tr><th>Miercoles</th></tr>
                      <tr style="line-height:450% ;"><th>Jueves</th></tr>
                      <tr><th>VIernes</th></tr>
                      <tr style="line-height:450% ;"><th>Sabado</th></tr>
                      <tr><th>Domingo</th></tr>
                   
                    </thead>
                    
                
                  </div></div>

              <div class="col-9">
                <div class="col-12 container1">
                  <div id="chart">
                    <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                      [dataLabels]="chartOptions.dataLabels" [colors]="chartOptions.colors"
                      [tooltip]="chartOptions.tooltip">

                    </apx-chart>

                  </div>
                </div>
                <button type="button" class="btn btn-flat-success" (click)="enviarexceheatmap()" rippleEffect><i
                    data-feather='file-text'></i>Excel</button>
              </div>
              <div class="col-1">
                <div id="chart">
                  <apx-chart [series]="chartOptions3.series" [chart]="chartOptions3.chart"
                    [dataLabels]="chartOptions3.dataLabels" [colors]="chartOptions3.colors"
                    [tooltip]="chartOptions3.tooltip"></apx-chart>
                </div>
              </div>
              <div class="col-1">
                <div id="chart">
                  <apx-chart [series]="chartOptions4.series" [chart]="chartOptions4.chart"
                    [dataLabels]="chartOptions4.dataLabels" [colors]="chartOptions4.colors"></apx-chart>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-1" style="margin-top: -1rem;">
                <!-- <apx-chart [series]="chartOptions8.series" [chart]="chartOptions8.chart"
                  [dataLabels]="chartOptions8.dataLabels" [colors]="chartOptions8.colors"
                  [tooltip]="chartOptions8.tooltip">
                </apx-chart> -->
                <div class="col-12" style="margin-top:4rem ;margin-left: 1rem;">
                  <thead>
                    <tr ><th>Total</th></tr>
                    <tr style="line-height:800% ;"><th>Part</th></tr>

                 
                  </thead>
                  
              
                </div>
              </div>
              <div class="col-9 container1">
                <div id="chart">
                  <apx-chart [series]="chartOptions2.series" [chart]="chartOptions2.chart"
                    [dataLabels]="chartOptions2.dataLabels" [colors]="chartOptions2.colors"
                    [tooltip]="chartOptions2.tooltip"></apx-chart>

                </div>
                <div style="margin-top: -3rem;" id="chart">
                  <apx-chart [series]="chartOptions5.series" [chart]="chartOptions5.chart"
                    [dataLabels]="chartOptions5.dataLabels" [colors]="chartOptions5.colors"
                    [tooltip]="chartOptions5.tooltip"></apx-chart>
                </div>
              </div>
              <div class="col-2">
                <div id="chart">
                  <apx-chart [series]="chartOptions6.series" [chart]="chartOptions6.chart"
                    [dataLabels]="chartOptions6.dataLabels" [colors]="chartOptions6.colors"
                    [tooltip]="chartOptions6.tooltip"></apx-chart>


                </div>

              </div>
            </div>

          </div>
        </div>
      </div>


    </section>
  </div>
</div>