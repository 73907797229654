<div class="content-wrapper container-xxl p-0">

    <div class="content-body">
        <!-- content-header component -->

        <app-content-header [contentHeader]="contentHeader">

        </app-content-header>

        <!-- Basic Alerts start -->
        <!-- <core-theme-customizer></core-theme-customizer> -->
        <section id="home-page">
            <section class="range-selection-DP">

                <div class="row">
                    <div class="col-12">
                        <div class="card">

                            <div class="card-body">

                                <!-- Range selection Date Picker -->
                                <div class="card">
                                    <div class="row">
                                        <div class="col-12">
                                            <span style="font-weight: bolder;">Fechas</span>
                                            <div class="row" style="margin-left:1rem;">
                                                <form class="form-inline">
                                                    <div class="form-group">
                                                        <div class="input-group w-0 ">
                                                            <input name="datepicker" class="form-control" ngbDatepicker
                                                                #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                                                [showWeekNumbers]="false"
                                                                (dateSelect)="onDateSelection($event,datepicker,modalBasic)"
                                                                [displayMonths]="1" [dayTemplate]="t"
                                                                outsideDays="visible" [startDate]="fromDate!"
                                                                tabindex="-1" />
                                                            <ng-template #t let-date let-currentMonth="currentMonth"
                                                                let-selected="selected" let-focused="focused">
                                                                <span class="custom-day" [class.focused]="focused"
                                                                    [class.hidden]="date.month !== currentMonth"
                                                                    [class.text-muted]="disabled"
                                                                    [class.range]="isRange(date)"
                                                                    [class.faded]="isHovered(date) || isInside(date)"
                                                                    (mouseenter)="hoveredDate = date"
                                                                    (mouseleave)="hoveredDate = null">
                                                                    {{ date.day }}


                                                                </span>

                                                            </ng-template>

                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input style="display: none;" #dpFromDate
                                                                class="form-control" placeholder="yyyy-mm-dd"
                                                                name="dpFromDate" [value]="formatter.format(fromDate)"
                                                                (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                                                                disabled />
                                                            <div class="input-group fecha">
                                                                <input class="fecha" type="text" [value]="desdehasta"
                                                                    name="" id="" disabled>
                                                            </div>
                                                            <div class="input-group-append">
                                                                <button
                                                                    class="btn btn-outline-secondary feather icon-calendar"
                                                                    (click)="datepicker.toggle()" type="button"
                                                                    rippleEffect></button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ng-template #modalBasic let-modal>
                                                        <div class="modal-header">
                                                            <h4 class="modal-title" id="myModalLabel1"><i
                                                                    data-feather='arrow-left'></i></h4>

                                                        </div>
                                                        <div class="modal-body" tabindex="0" ngbAutofocus>

                                                            <p>
                                                                Seleccione la fecha de inicio y la fecha fin en el mismo
                                                                calendario
                                                            </p>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-primary"
                                                                (click)="modal.close('Accept click')" rippleEffect>
                                                                Aceptar
                                                            </button>
                                                        </div>
                                                    </ng-template>
                                                    <div class="form-group ml-sm-2" style="display: none;">
                                                        <div class="input-group">
                                                            <input #dpToDate class="form-control"
                                                                placeholder="yyyy-mm-dd" name="dpToDate"
                                                                [value]="formatter.format(toDate)" disabled />
                                                            <div class="input-group-append">
                                                                <button class="btn btn-outline-secondary feather "
                                                                    (click)="datepicker.toggle()" type="button"
                                                                    rippleEffect disabled></button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </form>



                                                <div class="row">
                                                    <div class="columnbut">
                                                        <button class="btn btn-outline-primary btn-sm m-2 float-left"
                                                            (click)="seleccionarAnterior()" rippleEffect>
                                                            Seleccionar Mes Anterior
                                                        </button>
                                                    </div>
                                                    <div class="columnbut">
                                                        <button class="btn btn-outline-primary btn-sm m-2 float-left"
                                                            (click)="seleccionarMesActual()" rippleEffect>
                                                            Seleccionar Mes Actual
                                                        </button>
                                                    </div>
                                                    <div class="columnbut">
                                                        <button class="btn btn-outline-primary btn-sm m-2 float-left"
                                                            (click)="seleccionarHoy()" rippleEffect>
                                                            Seleccionar Hoy</button>
                                                    </div>

                                                    <div class="columnbut">

                                                        <button class="btn btn-outline-primary btn-sm m-2 float-left"
                                                            (click)="seleccionarAyer()" rippleEffect>
                                                            Seleccionar Ayer</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column" style="width:40% ">
                                            <div class="row">
                                                <span style="font-weight: bolder;">Punto Venta</span>
                                                <div *ngIf="mostrarfiltros" class="columy"
                                                    style="padding-left: unset;padding-right: unset;">


                                                    <ng-select [clearSearchOnAdd]="true" [items]="tiendas"
                                                        [clearable]="false" [multiple]="true" [closeOnSelect]="false"
                                                        [searchable]="true" bindLabel="Nombre"
                                                        placeholder="Seleccione PV" [(ngModel)]="codigoTienda">
                                                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                            <span class="ng-value-label" [value]="item.Codigo"> {{
                                                                item.Nombre }}</span>
                                                            <span class="ng-value-icon right" (click)="clear(item)"
                                                                aria-hidden="true">×</span>
                                                        </ng-template>
                                                    </ng-select>


                                                </div>
                                                <div class="columx" style="padding-left: unset;padding-right: unset;">
                                                    <button type="button" class="btn btn-flat-danger" rippleEffect
                                                        (click)="limpiarFiltPv()" title="limpiar filtro">X</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <button [disabled]="loading" (click)="buscar()" class="btn btn-relief-primary"
                                        tabindex="4" rippleEffect>
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>Consultar
                                    </button>
                                </div>
                                
                            </div>
                        </div>
                        <div class="card">
                          <div class="card-header">
                            <h4> <b>Total Acumulado</b> </h4>
                          </div>
                          <div class="card-body">
                            <table class="table table-hover-animation">
                              <thead>
                                <tr>
                                  <th>Ventas</th>
                                  <th>Actual</th>
                                  <th >Unidades Actuales</th>
                                  <th >Transacciones Actuales</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Total</th>
                                  <td *ngFor="let item of diar">
                                      {{item.totalVentas | currency : '$' : 'symbol' :'1.0-0'}}
                                  </td>
                                  <td *ngFor="let item of diar">
                                      {{item.cantidadArticulosFisicos}}
                                  </td>
                                  <td *ngFor="let item of diar">
                                      {{item.numeroTransaccionesMes}}
                                  </td>
                                </tr>
                              </tbody>
                          </table>
                          </div>
                            
                        </div>
                        
                              <div class="card ">
                                <!--/ Basic Date Picker -->
                                <div class="card-header">
                                  <h4> <b>Detalle Acumulado</b> </h4>
                                </div>
                                <div class="card-body">
                                  <div class="col-lg-12 col-12">
                                    <div class="card card-company-table">
                                      <div class="card-body p-0">
                                        <div class="table-responsive">
                                          <table class="table table-hover-animation">
                                            <thead>
                                              <tr>
                                                <th>Nombre Pv</th>
                                               
                                                <th>venta total</th>
                                               
                                                <th class="sdiv" style="width: 20px;">Unid</th>
                                                <th class="sdiv" style="width: 20px;">Trans</th>
                                                
                                                
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr *ngFor="let item of resultado ">
                                                <td>
                                                  <div>
                                                    <div style="font-size: 12px;white-space: nowrap">
                    
                                                      {{item.Nombre}}
                    
                                                    </div>
                    
                                                    <div>
                                                      <div class="font-weight-bolder"></div>
                                                      <div class="font-small-2 text-muted"></div>
                                                    </div>
                                                  </div>
                                                </td>
                    
                    
                    
                                                
                                                <td>{{item.totalVentas | currency : '$' :
                                                  'symbol':'1.0-0'}}</td>
                                               
                                                <td>{{item.totalUnidades | number:'1.0-0'}}</td>
                                                <td>{{item.numeroTransaccionesMes | number:'1.0-0'}}</td>
                                                
                    
                                              </tr>
                                              
                    
                    
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <button type="button" class="btn btn-flat-success" rippleEffect (click)="enviarexceventas()"><i
                                      data-feather='file-text'></i>Excel</button>
                                  <!-- <button *ngIf="coordinador=='Luis Guillermo Maya Arbelaez'" type="button" class="btn btn-flat-success" rippleEffect
                                    (click)="enviarexceventasPedido()"><i data-feather='file-text'></i>Excel Coordinadores Comerciales</button> -->
                    
                                </div>
                                
                    
                              </div>
                    
                              <div class="card sdiv">
                                <!--/ Basic Date Picker -->
                                <div class="card-header">
                                  <h4> <b>Detalle Diario</b> </h4>
                                </div>
                                <div class="card-body">
                                  <div class="col-lg-12 col-12">
                                    <div class="card card-company-table">
                                      <div class="card-body p-0">
                                        <div class="table-responsive">
                                          <table class="table table-hover-animation">
                                            <thead>
                                              <tr>
                                                <th>Nombre Pv</th>
                                                <th>Dia </th>
                                                <th>Fecha</th>
                                                
                                                <th>venta total</th>
                                               
                                                <th style="width: 20px;">Unid</th>
                                                <th style="width: 20px;">Trans</th>
                                                
                                                <!-- <th style="width: 20px;">#Credito</th>
                                                <th style="width: 20px;">ppto #Credito</th> -->
                    
                    
                    
                    
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr *ngFor="let item of resultado1 | slice: (pageBasic-1) * pageSize : pageBasic * pageSize">
                                                <td>
                                                  <div>
                                                    <div style="font-size: 12px;white-space: nowrap">
                    
                                                      {{item.Nombre}}
                    
                                                    </div>
                    
                                                    <div>
                                                      <div class="font-weight-bolder"></div>
                                                      <div class="font-small-2 text-muted"></div>
                                                    </div>
                                                  </div>
                                                </td>
                    
                    
                                                <td>
                    
                                                  {{item.nombreDelDiaSemanaAnio }}
                    
                                                </td>
                                                <td>
                    
                                                  {{item.fecha }}
                    
                                                </td>
                    
                                              
                                                <td>{{item.totalVentas | currency : '$' :
                                                  'symbol':'1.0-0'}}</td>
                                                  
                                                <td>{{item.totalUnidades | number:'1.0-0'}}</td>
                                                <td>{{item.numeroTransaccionesMes | number:'1.0-0'}}</td>
                                                
                                                <!-- <td>{{item.cantidadTransaccionesCreditos | number:'1.1-1'}}</td>
                                                  <td>{{item.PptoCreditoNro | number:'1.1-1'}}</td> -->
                    
                    
                    
                                                <!-- Modal -->
                                                
                    
                                              </tr>
                                              
                    
                    
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <button type="button" class="btn btn-flat-success" rippleEffect (click)="enviarexceventas1()"><i
                                      data-feather='file-text' ></i>Excel</button>
                                  <!-- <button *ngIf="coordinador=='Luis Guillermo Maya Arbelaez'" type="button" class="btn btn-flat-success"
                                    rippleEffect (click)="enviarexceventasPedido()"><i data-feather='file-text'></i>Excel Coordinadores
                                    Comerciales</button> -->
                    
                                </div>
                                <div  class="row sdiv" style="margin-left: 1rem;">
                                  <ngb-pagination [collectionSize]="longitud" [(page)]="pageBasic" [pageSize]="pageSize" [maxSize]="20"
                                    [boundaryLinks]="true" [rotate]="true" [directionLinks]="false" aria-label="Default pagination"></ngb-pagination>
                                </div>
                    
                              </div>
                    </div>
                </div>
            </section>
        </section>
    </div>
</div>