<p>consultar-repos-pedidos works!</p>
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- content-header component -->
        <app-content-header [contentHeader]="contentHeader"></app-content-header>

        <!-- Basic Alerts start -->

        <section id="home-page">
            <section class="range-selection-DP">

                <div class="row">
                    <div class="col-12">
                        <div class="card">

                            <div class="card-body">


                                <div class="card">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <label for="">Punto de Venta</label>
                                        </div>
                                        <div class="col-sm-4">
                                            <label for="">Buscar por:</label>
                                        </div>
                                        
                                    </div>
                                    <div class="row">

                                        <div class="col-sm-4">
                                            
                                            <div class="row">

                                                <div class="col">


                                                    <ng-select [clearSearchOnAdd]="true" [clearable]="false"
                                                        [multiple]="true" [closeOnSelect]="false" [searchable]="true"
                                                        bindLabel="nombreAgencia" placeholder="Seleccione Punto Venta">
                                                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                            <span class="ng-value-label" [value]="item.codigoAgencia">
                                                                {{item.nombreAgencia}}</span>
                                                            <span class="ng-value-icon right" (click)="clear(item)"
                                                                aria-hidden="true">×</span>
                                                        </ng-template>
                                                    </ng-select>
                                                </div>
                                            </div>
                                          


                                        </div>
                                        <div class="col-sm-4">
                                            
                                            <form class="form-inline">
                                                <div class="form-group">
                                                  <div class="input-group w-0 ">
                                                    <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                                                      [autoClose]="'outside'" [showWeekNumbers]="false"
                                                      (dateSelect)="onDateSelection($event,datepicker)" [displayMonths]="1"
                                                      [dayTemplate]="t" outsideDays="visible" [startDate]="fromDate!" tabindex="-1" />
                                                    <ng-template #t let-date let-currentMonth="currentMonth" let-selected="selected"
                                                      let-focused="focused">
                                                      <span class="custom-day" [class.focused]="focused"
                                                        [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled"
                                                        [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)"
                                                        (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                                        {{ date.day }}
                      
                      
                                                      </span>
                      
                                                    </ng-template>
                      
                                                  </div>
                                                </div>
                                                <div class="form-group">
                                                  <div class="input-group">
                                                    <input style="display: none;" #dpFromDate class="form-control" placeholder="yyyy-mm-dd"
                                                      name="dpFromDate" [value]="formatter.format(fromDate)"
                                                      (input)="fromDate = validateInput(fromDate, dpFromDate.value)" disabled />
                                                    <div class="input-group fecha">
                                                      <input class="fecha" type="text" [value]="desdehasta" name="" id="" disabled>
                                                    </div>
                                                    <div class="input-group-append">
                                                      <button class="btn btn-outline-secondary feather icon-calendar"
                                                        (click)="datepicker.toggle()" type="button" rippleEffect></button>
                      
                                                    </div>
                                                  </div>
                                                </div>
                                              
                                                <div class="form-group ml-sm-2" style="display: none;">
                                                  <div class="input-group">
                                                    <input #dpToDate class="form-control" placeholder="yyyy-mm-dd" name="dpToDate"
                                                      [value]="formatter.format(toDate)" disabled />
                                                    <div class="input-group-append">
                                                      <button class="btn btn-outline-secondary feather " (click)="datepicker.toggle()"
                                                        type="button" rippleEffect disabled></button>
                                                    </div>
                                                  </div>
                                                </div>
                      
                                              </form>
                                        </div>
                                        <div class="col-sm-1">
                                            <button class="btn btn-primary"
                                                        type="button" rippleEffect >Buscar</button>
                                        </div>
                                        <div class="col-sm-3">
                                            <button class="btn btn-primary"
                                                        type="button" rippleEffect >Buscar Ultima Reposicion</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </section>
    </div>
</div>