<div class="content-wrapper container-xxl p-0">

  <div class="content-body">
    <!-- content-header component -->

    <app-content-header [contentHeader]="contentHeader">


    </app-content-header>
    <div class="card">
      <div class="row" style="margin:1rem ;">
        <div class="col-sm-3">
          <h3><label for="">Fecha Venta Inicial</label></h3>
          <br>
          <input #fechaInputIni id="fechaInicial" type="date" class="form-control">
        </div>
        <div class="col-sm-3">
          <h3><label for="">Fecha Venta Final</label></h3>
          <br>
          <input #fechaInputFin id="fechaFinal" type="date" class="form-control">
        </div>
        <div class="col-sm-3">
          <h3><label for="">Adjuntar Consignacion formato Imagen</label></h3>
          <br>
          <input multiple id="" accept="image/*" type="file" class="form-control" (change)="onFileSelected($event)">
        </div>

        <!-- <div class="col-sm-3">
          <h3><label for="">Mostrar Canvas</label></h3>
          <br>
          <button id="snap" type="button" class="btn btn-primary" (click)="iniciarcamara()">
            Foto
          </button>
        </div> -->  
        <!-- <div class="row" > -->
          <!-- <div class="col-md-6">
            <video style="margin: 1rem" id="video" autoplay></video>
          </div> -->
          <!-- <div class="col-md-4">
            <button id="snap" type="button" class="btn btn-primary" (click)="tomarFoto()">
              Foto
            </button>
          </div> -->
          <!-- <div class="col-md-6"> -->
            <!-- <img [src]="foto" height="512" /> -->
            
            <!-- <canvas style="margin: 1rem" id="canvas" width="512" height="512"></canvas>
          </div>
        </div> -->


      </div>
      <div class="row">
        <div class="col-sm-12" style="margin:2rem ;">

          <button [disabled]="loading" class="btn btn-relief-primary" tabindex="4" rippleEffect (click)="procesar()">
            <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
            Procesar Archivos
          </button>

        </div>
      </div>
      <div class="row">
        <div class="table-responsive" style="margin:1rem ;">
          <table class="table table-hover-animation">
            <thead>
              <tr>
                <th>Id</th>
                <th>Referencia</th>
                <th>Banco</th>
                <th>Valor</th>
                <th>Codigo</th>
                <th>Convenio</th>
                <th>Fecha</th>
                <th>Hora</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of factura">
                <td>{{item.response.file_id}}</td>
                <td>{{item.response.REFERENCIA}}</td>
                <td>{{item.response.BANCO}}</td>
                <td>{{item.response.VALOR}}</td>
                <td>{{item.response.CODIGO}}</td>
                <td>{{item.response.CONVENIO}}</td>
                <td>{{item.response.FECHA}}</td>
                <td>{{item.response.HORA}}</td>

              </tr>
              <tr>
                <th>Diferencias</th>
                <td></td>
                <td></td>
                <td>{{diferencia | currency : '$' : 'symbol' :'1.0-0'}}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

              </tr>

            </tbody>
          </table>
        </div>
      </div>
      <div class="row" *ngIf="pedirComentario">
        <div class="col-sm-12" style="margin:2rem ;">
          <h5>Indique El Motivo De La Diferencia</h5>
          <textarea #comentario [(ngModel)]="comentarioDetalle" name="" style="width:80% ;margin:2rem"
            id="comentario"></textarea>
        </div>
      </div>
      <div class="row" *ngIf="mostrarGuardar">
        <div class="col-sm-3" style="margin:1rem ;">
          <button class="btn btn-primary" (click)="guardar()">Guardar</button>
        </div>

      </div>
    </div>
  </div>
</div>