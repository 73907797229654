import { Component, OnInit } from '@angular/core';
import { TinyColor } from '@ctrl/tinycolor';
import { DiasMes } from "../../../mock/diasmes";
import { NgbDateStruct, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoTiendaData } from 'app/mock/tiendas';
import { ExcelService } from 'app/services/excelservice';
import { ServiceService } from 'app/services/service.service';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexFill,
  ChartComponent,
  ApexLegend,
  ApexDataLabels,
  ApexTooltip,
  ApexStroke,
} from 'ng-apexcharts';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs'

import { NgSelectModule } from '@ng-select/ng-select';
import { AngularFireMessaging } from '@angular/fire/messaging';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ocupacion-tiendas',
  templateUrl: './ocupacion-tiendas.component.html',
  styleUrls: ['./ocupacion-tiendas.component.scss']
})
export class OcupacionTiendasComponent implements OnInit {
  public chartOptions: Partial<ApexChart> = {};
  public series: ApexNonAxisChartSeries = [];
  public labels: string[] = [];
  contentHeader: object
  public fromDate: any;
  public toDate: any;
  mesess: any;
  totalanios: any;
  anio: any;
  anios: any[];
  anioresult: any;
  excepciones: string[];
  rol: string;

  coordinador: string;



  model: NgbDateStruct;

  public selectMultiSelected = [{}];

  public hoveredDate: NgbDate | null = null;
  public loading = false;
  public selectedVariables: string[] = [];
  presupuestomes: any = 0;
  days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  hours = Array.from({ length: 24 }, (_, i) => i); // De 0 a 23
  availableProperties = ['NumeroEntradas', 'numeroTransacciones', 'totalVentas', 'cantidadArticulos', 'AsesorasPorHora', 'numeroTransaccionesCarpa', 'tasaConver']; // Añadir más según sea necesario
  variableColors = {
    'NumeroEntradas': '#FF0000',      // Rojo
    'numeroTransacciones': '#00FF00',  // Verde
    'totalVentas': '#FF8333',      // Rojo
    'cantidadArticulos': '#FFF333',  // Verde
    'AsesorasPorHora': '#02F4FF',      // Rojo
    'numeroTransaccionesCarpa': '#FF5000',  // Verde
    'tasaConver': '#FF02FB'

  };
  selectedProperty = this.availableProperties[0];
  ventastotaldia: number = 0;
  ventastotalmes: any = 0;
  porcentajedia: number = 0;
  porcentajemes: number = 0;
  show: number = 0;
  show1: number = 0;
  porcentajefaltantedia: number = 0;
  porcentajefaltantemes: number = 0;

  presupuestomenosventasdia: number = 0;
  presupuestomenosventasmes: number = 0;
  mesames: any;
  totalMes: any
  resultadoFinal: any = {}
  cumplimientohistorico: any;
  cantidadVentasDia: any = 0;
  ventasFisicasActualDia: any = 0;
  ventasVirtualesActualDia: number = 0;
  cantidadVentasMesActual: any = 0;
  ventasFisicasMesActual: any = 0;
  ventasVirtualesMesActual: any = 0;
  devoluciones: any;
  ventasFisicasMesActual1: number = 0;
  ventasFisicasActualDia1: number = 0;
  devolucionesActualDia: number = 0;
  devolucionesMesActual: number = 0;
  cantidadVentasDigitaldia: number = 0;
  cantidadVentasDigitalMes: number = 0;
  presupuestoActualDigitalDia: number = 0;
  presupuestoDigitalMesActual: number = 0;
  porcentajeDigitaldia: number = 0;
  porcentajeDigitalmes: number = 0;
  cantidadArticulosTotalesDia: number = 0;
  cantidadArticulosTotalesMesActual: any = 0;
  cantidadTransaccionesMesActual: number = 0;
  cantidadTransaccionesDia: number = 0;
  ticketpromediodia: number = 0;
  ticketpromediomes: number = 0;
  articulosPorTicketDia: number = 0;
  articulosPorTicketMes: number = 0;
  totalCreditoKaiowaMesActual: number = 0;
  totalCreditoKaiowaDiaActual: number = 0;
  presupuestoCreditoKaiowaDia: number = 0;
  presupuestoKaiowaMesActual: number = 0;
  porcentajeDiaKaiowa: number = 0;
  porcentajeMesKaiowa: number = 0;

  formatos = [];
  tiendas = [];
  agencias = [];
  ciudades = [];
  responsables: any[];
  agencia: any = {};
  pendientekaiowa: number;
  pendientedigital: number;
  vpt: number = 0;
  upt: number = 0;
  pp: number = 0;
  vptsupt: number = 0;
  fechainicioStr: string;
  idTienda: number;
  fechainicio: Date;
  fechainidash: string;
  diadia: any;
  totalventas: number = 0;
  totalpresup: number = 0;
  totalporcent: number = 0;
  totalpendiente: number = 0;
  fechafin: Date;
  mostrarfiltros: boolean = true;
  fechafinStr: string;
  fechafindash: string;
  prespuestoentrefechas: number = 0;
  totalventasentrefechas: number = 0;
  porcentajeentrefechas: number = 0;
  porcentajefaltanteentrefechas: number = 0;
  presupuestomenosventasentrefechas: number = 0;
  mostrarfiltrosi: boolean = false;

  ventadigital: number = 0;
  ventafisica: number = 0;

  nombre: string = 'Faltante';
  totalDevoluciones: number = 0;
  ventafisica1: number = 0;
  cantidadVentas: number = 0;
  cantidadTransacciones: number = 0;
  cantidadArticulosTotales: number = 0;
  ticketpromedio: number = 0;
  articulosporticket: number = 0;
  totalCreditoKaiowa: number = 0;
  presupuestoCreditoKaiowa: number = 0;
  cumplimientoKaiowa: number = 0;
  presupuestoDigital: number = 0;
  cumplimientoRdl: number = 0;
  totaltotal: number;
  totaltotalventas: number = 0;
  totalpresupuesto: number = 0;
  totalcumplimiento: number = 0;
  totalpendiente1: number = 0;
  presupuestoFisico: number = 0;
  pendientedig: number = 0;
  ventassobretransacciones: number = 0;
  cumplimientovpt: number = 0;
  articulossobretransacciones: number = 0;
  precioprom: number = 0;
  vptsobreupt: number = 0;
  pendientecrkaiowa: number = 0;
  participacionfisico: number = 0;
  participaciondigital: number = 0;
  participaciontotal: number = 0;
  diar: any;
  codigoTienda: any = null;
  agenciaTienda: any;
  formatoTienda: any = null;
  vista: any = "Ar";
  ciudad: any = null;
  difecha: any;
  dif: number;
  // arregloAgencia: void;
  color: string;
  colorPf: boolean;
  colorCf: boolean;
  colorPd: boolean;
  colorPt: boolean;
  colorCd: boolean;
  colorCt: string;
  colorPvpt: boolean;
  colorPupt: boolean;
  colorPpp: boolean;
  colorPtc: boolean;

  colorPent: boolean;
  colorPkai: boolean;
  colorCvpt: boolean;
  colorCupt: boolean;
  colorCpp: boolean;
  colorCtc: boolean;
  colorCent: boolean;
  colorCkai: boolean;

  infoTiendaFiltrada: any;

  mostrarCampo: boolean = true;


  infoTiendaFiltradaAge: any;

  filtroAplicados: string[] = [];
  colorPfi: boolean;
  colorPdi: boolean;
  colorPtot: boolean;
  colorTrans: boolean;
  mostrarsincronizacion: boolean;
  mostrarVentas: boolean;
  mostrarIndicadores: boolean;
  mostrarPotenciales: boolean;
  colorTransTotal: string;
  colorTransDia: string;
  colorTransss: string;
  mostrarDetalleVentasFisicos: boolean = false;
  mostrarDetalleVentasDigitaless: boolean = false;
  mostrarDetalleVentasTotal: boolean = false;
  mostrarDetalleVentasCreditos: boolean = false;
  mostrarDetallesVPTs: boolean = false;
  mostrarDetallesUPTs: boolean = false;
  mostrarDetallesPPs: boolean = false;
  mostrarDetallesTCs: boolean = false;
  mostrarDetallesEntrada: boolean = false;
  flecharoja: boolean;
  flechaRojacDig: boolean;
  flecharojacfis: boolean;
  flechaRojaPdig: boolean;
  flecharojaPt: boolean;
  flechaRojaPfi: boolean;
  flechaRojaPdi: boolean;
  flechaRojaPvpt: boolean;
  flechaRojaCvpt: boolean;
  flechaRojaPupt: boolean;
  flechaRojaCupt: boolean;
  flechaRojaPpp: boolean;
  flechaRojaCpp: boolean;
  flechaRojaPtc: boolean;
  flechaRojaCtc: boolean;
  flechaRojaPent: boolean;
  flechaRojaCent: boolean;
  flechaRojaPkai: boolean;
  flechaRojaCkai: boolean;
  flecaRojaPtot: boolean;
  deshabilitado: boolean = false;
  flechaRojaTra: boolean;
  colorcTrans: boolean;
  flechaRojacTrans: boolean;
  desdehasta: string;
  codigoarray: any;
  mostrarDetalleTransacciones: boolean = false;
  mostrarDetallePartCreditos: boolean;
  fechaSincronizacionCam: any;
  intervalId: number;
  token: string;
  pTotal: any;
  pendTotal: any;

  variable: any;
  fechaAux: any;
  fechaAuxFin: any;
  consultaAnterior: number = 0;
  colorTransCredTotal: string;
  colorTransCred: boolean;
  flechaRojaTransCred: boolean;
  colorTranskai: boolean;
  flechaRojaTranskai: boolean;
  colorCumplimTotal: boolean;
  flechaRojaCumplimTotal: boolean;
  colorcrecCantCedValid: boolean;
  flechaRojacrecCantCedValid: boolean;
  colorCcrecim: boolean;
  flechaverdeCcrecim: boolean;
  flecharojaCcrecim: boolean;
  mostrado: boolean = false;
  counte: any;
  acumPptoVPT: any;
  dataEnviar: { vista: string; fechaInicio: string; fechaFin: string; tiendas: any; };
  estienda: boolean = false;
  nombretienda: string;
  informacionTienda: any = [];
  resultadoFinals: any;
  Horas: any;
  resultadoFinal2: any;
  HoraSeleccionada: any;
  resultadoFinal22: any;
  groupedData: any = {};
  resultadoHoras: any;
  matriz: any = [0, 0, 0, 0];
  maxValues: { [key: string]: number } = {};
  mostrarResultado: boolean = false;
  totalPorDiaYVariable: { [key: string]: { [key: string]: number } } = {};
  sumaPorDia: any = [];
  maxValues1: { [key: string]: number } = {};
  sumValues1: { [key: string]: number } = {};
  constructor(private modalService: NgbModal, private diamess: DiasMes, private calendar: NgbCalendar, public excelService: ExcelService, private router: Router, public formatter: NgbDateParserFormatter, private inforTiendas: InfoTiendaData, private service: ServiceService) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();
    interface Resultado {
      Fecha: string;
      Horas: {
        Hora: number;
        Entradas: number;
        AsesorasPorHora: number;
      }[];
    }

  }

  ngOnInit(): void {
    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
    this.excepciones = ["Julian Alberto Navarro Garcia","Leydi Milena Peña Sepulveda", "Mariana Ossa Sanchez", "Juan Carlos Gallego Elejalde", "Melissa Castrillon Cano", "Jose Mario Tamayo Lopera","Carlos Ignacio Urrea", "Juan Carlos Maya Arbelaez", "Mauricio Andres Cardona Suarez", "Ana Lucia Hernandez Santa", "Alejandro Gonzalez Vasquez", "Maria Camila Cardona Arroyave", "Maria Cristina Londoño Cruz", "David  Gomez Patiño", "Soraya  Bedoya Mejia", "Margarita Maria Mazo Franco", "Luis Guillermo Maya Arbelaez", "Sergio Alexander Villa Cano"];
    this.rol = sessionStorage.getItem('rol');
    if (this.rol === "null") {
      this.estienda = false
    } else {
      this.estienda = true
    }
    this.anio = this.fromDate.year
    this.totalanios = this.anio
    this.anios = []
    for (let i = -5; i < 6; i++) {
      this.anioresult = this.anio + i
      this.anios.push(this.anioresult)

    }
    this.codigoTienda = null;
    this.agenciaTienda = null;
    this.formatoTienda = null;
    this.ciudad = null;
    this.coordinador = sessionStorage.getItem('responsable');
    this.fillfilter1()
    this.nombretienda = sessionStorage.getItem('tienda');
    if (this.nombretienda == null) {
      this.router.navigate(['../pages/authentication/auth-login-v2/auth-login-v2.component.html'])
      return
    }
    this.contentHeader = {
      headerTitle: 'Home',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'Ocupacion',
            isLink: false
          }
        ]
      }
    }
    if (this.rol == "COORDINADOR" && !this.excepciones.join(',').includes(this.coordinador)) {
      this.inforTiendas.infoTienda = this.inforTiendas.infoTiendaAll.filter(x => x.Responsable == this.coordinador);
      this.agenciaTienda = this.inforTiendas.infoTienda[0].Regional;
      if (this.agenciaTienda != '02') {
        this.deshabilitado = true;
      }


    }
    else if (this.rol == "GERENTE" && !this.excepciones.join(',').includes(this.coordinador)) {

      this.inforTiendas.infoTienda = this.inforTiendas.infoTiendaAll.filter(x => x.Gerente == this.coordinador);
      this.agenciaTienda = this.inforTiendas.infoTienda[0].Regional;
      if (this.agenciaTienda != '02') {
        this.deshabilitado = true;
      }

    }
    else
      this.inforTiendas.infoTienda = this.inforTiendas.infoTiendaAll;

    if (this.rol == "GERENTE" && !this.excepciones.join(',').includes(this.coordinador)) {
      this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
      this.filterGer()
    }
    else
      if (this.rol == "COORDINADOR" && !this.excepciones.join(',').includes(this.coordinador)) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        this.filterResp()

      } else {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        this.fillFilter(this.inforTiendas.infoTienda);
      }
  }

  private fillFilter(infotienda: any) {


    var agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia }; });
    var arregloAgencia = [];
    agencia.map((a) => {
      if (arregloAgencia.filter(p => p.codigoAgencia == a.codigoAgencia).length == 0)

        arregloAgencia.push(a);
    });
    // console.log(arregloAgencia);


    this.agencias = [...new Set(arregloAgencia)];
    this.formatos = [...new Set(infotienda.map(x => x.Formato))];
    this.ciudades = [...new Set(infotienda.map(x => x.CIUDAD))];
    this.responsables = [...new Set(infotienda.map(x => x.Responsable))];
    this.tiendas = [...new Set(infotienda.map(x => { return { Nombre: x.NOMBRE, Codigo: x.CodigoWinPOS }; }))];
    this.vista = this.vista;

  }

  fillfilter1() {
    this.mesess =
    {
      'meses': ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
    }
  }
  filterResp() {
    this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
      return p.Responsable.toString().toLowerCase() == this.coordinador.toLowerCase();
    })
    this.fillFilter(this.infoTiendaFiltrada);
  }

  filterGer() {
    this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
      return p.Gerente == this.coordinador;
    })
    this.fillFilter(this.infoTiendaFiltrada);
  }
  filterPV(event) {
    // console.log("evento:" + event);
    if (this.ciudad != "null") {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return this.ciudad.filter(d => p.CIUDAD == d).length > 0;
      })
    } else {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return p.CIUDAD != '';
      })
    }

    this.fillFilter(this.infoTiendaFiltrada);
    this.filtroAplicados.push("ciudad");
  }
  onChange(event) {

    this.fechainicio = new Date(this.fechainicioStr + 'T00:00');
    this.fechafin = new Date(this.fechafinStr + 'T00:00');
    if (this.fechafin.getTime() >= this.fechainicio.getTime()) {
      this.difecha = (this.fechainicio.getTime() - this.fechafin.getTime()) + (1);
      this.dif = (this.difecha / (1000 * 3600 * 24)) * (-1);
      // console.log("evento:" + event);
      if (this.dif <= 0) {
        this.vista = 'Ar'
      } else {
        this.vista = 'Ac'
      }
      return this.vista;
    } else {

      window.alert("seleccione un rango de fechas valido");

    }
  }
  filterTiend(event) {
    if (this.codigoTienda != "null") {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return this.codigoTienda.filter(d => p.codigo == d).length > 0;
      })
    } else {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return p.Regional != '';
      })
    }
    this.fillFilter(this.infoTiendaFiltrada);
  }

  filterAge(event) {
    // this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
    // console.log("evento:" + event);
    if (this.agenciaTienda != "null") {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia).length > 0;
      })
    } else {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return p.Regional != '';
      })
    }

    this.fillFilter(this.infoTiendaFiltrada);
    this.filtroAplicados.push("Agencia");
  }



  filterFormat(event) {

    // console.log("evento:" + event);
    if (this.formatoTienda != "null") {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return this.formatoTienda.filter(d => p.Formato == d).length > 0;
      });
    } else {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return p.Formato != '';
      })
    }
    this.fillFilter(this.infoTiendaFiltrada);
    this.filtroAplicados.push("Formato");

  }
  limpiarFiltAgen(infotienda: any = this.inforTiendas.infoTienda) {

    let arregloAgencia = [];

    if (this.rol == "GERENTE" && !this.excepciones.join(',').includes(this.coordinador)) {
      let agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia }; });


      agencia.forEach(a => {
        if (arregloAgencia.filter(b => b.codigoAgencia.trim() == a.codigoAgencia.trim()).length < 1) {
          arregloAgencia.push(a);
        }
      });

      // console.log(arregloAgencia);
      this.filtroAplicados = this.filtroAplicados.filter(p => p != "Agencia");
      if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

        this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => p.CIUDAD == this.ciudad && p.Gerente == this.coordinador);
        this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
        this.agenciaTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }

      if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

        this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => p.Formato == this.formatoTienda && p.Gerente == this.coordinador);
        this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
        this.agenciaTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }
      if (this.filtroAplicados.length == 0) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda
        this.agenciaTienda = null;
        this.agencias = [...new Set(arregloAgencia)];
        this.fillFilter(this.infoTiendaFiltrada);
      }
    } else {
      if (this.rol == "COORDINADOR" && !this.excepciones.join(',').includes(this.coordinador)) {
        let agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia }; });


        agencia.forEach(a => {
          if (arregloAgencia.filter(b => b.codigoAgencia.trim() == a.codigoAgencia.trim()).length < 1) {
            arregloAgencia.push(a);
          }
        });
        // console.log(arregloAgencia);
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "Agencia");
        if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

          this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => p.CIUDAD == this.ciudad && p.Responsable == this.coordinador);
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.agenciaTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

          this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => p.Formato == this.formatoTienda && p.Responsable == this.coordinador);
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.agenciaTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda
          this.agenciaTienda = null;
          this.agencias = [...new Set(arregloAgencia)];
          this.fillFilter(this.infoTiendaFiltrada);
        }
      } else {
        var agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia }; });

        agencia.map((a) => {
          if (arregloAgencia.filter(p => p.codigoAgencia == a.codigoAgencia).length == 0)
            arregloAgencia.push(a);
        });
        // console.log(arregloAgencia);
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "Agencia");
        if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

          this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => { return this.ciudad.filter(d => p.CIUDAD == d).length > 0 });
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.agenciaTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

          this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => { return this.formatoTienda.filter(d => p.Formato == d).length > 0 });
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.agenciaTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda
          this.agenciaTienda = null;
          this.agencias = [...new Set(arregloAgencia)];
          this.fillFilter(this.infoTiendaFiltrada);
        }
      }
    }
  }


  limpiarFiltCiu(infotienda: any = this.inforTiendas.infoTienda) {

    if (this.rol == "GERENTE" && !this.excepciones.join(',').includes(this.coordinador)) {
      var agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia, Gerente: x.Gerente }; });
      var arregloAgencia = [];
      agencia.map((a) => {
        if (arregloAgencia.filter(p => p.codigoAgencia == a.codigoAgencia && p.Gerente == this.coordinador).length == 0)
          arregloAgencia.push(a);
      });
      // console.log(arregloAgencia);
      this.filtroAplicados = this.filtroAplicados.filter(p => p != "ciudad");
      this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
      if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {

        this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia && p.Gerente == this.coordinador).length > 0 });
        this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
        this.ciudad = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }

      if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

        this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.formatoTienda.filter(d => p.Formato == d && p.Gerente == this.coordinador).length > 0 });
        this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
        this.ciudad = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }
      if (this.filtroAplicados.length == 0) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda
        this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
        this.ciudad = null;
        this.fillFilter(this.infoTiendaFiltrada);

      }
    } else {
      if (this.rol == "COORDINADOR" && !this.excepciones.join(',').includes(this.coordinador)) {
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "ciudad");
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia && p.Responsable == this.coordinador).length > 0 });
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.formatoTienda.filter(d => p.Formato == d && p.Responsable == this.coordinador).length > 0 });
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);

        }
      } else {
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "ciudad");
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia).length > 0; });
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.formatoTienda.filter(d => p.Formato == d).length > 0 });
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);

        }
      }
    }
  }
  seleccionarHoy() {
    this.consultaAnterior = 1
    this.vista = 'Ar';
    this.fromDate = this.calendar.getToday()
    this.toDate = this.calendar.getToday()
    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
  }
  seleccionarAnterior() {
    this.vista = 'Ac';
    this.consultaAnterior = 1
    var ano: any
    var mes: any
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    var mesActual = this.calendar.getToday().month
    if (mesActual.toString().padStart(2, "0") == "01") {
      mesActual = 13
    }
    this.diamess.mes.map(x => {
      if (x.Mes == (mesActual - 1).toString().padStart(2, "0")) {
        if (mesActual == 13) {
          this.fechainicioStr = (this.fromDate.year - 1).toString() + "-" + (mesActual - 1).toString().padStart(2, "0") + "-" + x.diaInicio;
          this.fechafinStr = (this.fromDate.year - 1).toString() + "-" + (mesActual - 1).toString().padStart(2, "0") + "-" + x.diaFin;
          ano = (this.fromDate.year - 1).toString()
          mes = (mesActual - 1).toString().padStart(2, "0")
          this.fromDate = { "day": parseInt(x.diaInicio), "month": parseInt(mes), "year": parseInt(ano) }
          this.toDate = { "day": parseInt(x.diaFin), "month": parseInt(mes), "year": parseInt(ano) }

        } else {
          this.fechainicioStr = this.fromDate.year.toString() + "-" + (mesActual - 1).toString().padStart(2, "0") + "-" + x.diaInicio;
          this.fechafinStr = this.toDate.year.toString() + "-" + (mesActual - 1).toString().padStart(2, "0") + "-" + x.diaFin;
          ano = this.toDate.year.toString()
          mes = (mesActual - 1).toString().padStart(2, "0")
          this.fromDate = { "day": parseInt(x.diaInicio), "month": parseInt(mes), "year": parseInt(ano) }
          this.toDate = { "day": parseInt(x.diaFin), "month": parseInt(mes), "year": parseInt(ano) }

        }

      }
    })

    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr;
  }
  seleccionarAyer() {
    this.consultaAnterior = 1
    this.vista = 'Ar';
    this.fromDate = this.calendar.getNext(this.calendar.getToday(), 'd', -1);
    this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', -1);
    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
  }
  seleccionarMesActual() {
    var ano: any
    var mes: any
    this.vista = 'Ac';
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    var mesActual = this.calendar.getToday().month
    this.diamess.mes.map(x => {
      if (x.Mes == mesActual.toString().padStart(2, "0")) {
        this.fechainicioStr = this.fromDate.year.toString() + "-" + this.fromDate.month.toString().padStart(2, "0") + "-" + x.diaInicio;
        this.fechafinStr = this.fromDate.year.toString() + "-" + this.fromDate.month.toString().padStart(2, "0") + "-" + x.diaFin;
        ano = (this.fromDate.year).toString()
        mes = (mesActual).toString().padStart(2, "0")
        this.fromDate = { "day": parseInt(x.diaInicio), "month": parseInt(mes), "year": parseInt(ano) }
        this.toDate = { "day": parseInt(x.diaFin), "month": parseInt(mes), "year": parseInt(ano) }
      }
    })
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr;
  }
  modalOpen(modalXS) {
    if (this.mostrado == false) {
      this.mostrado = true
      this.modalService.open(modalXS, {
        centered: true,
        size: 'xs'
      });
    }

  }
  onDateSelection(date: NgbDate, datepicker, modalBasic) {
    this.modalOpen(modalBasic)
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate) {
      this.toDate = date;
      datepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
    this.fechainicio = new Date(this.fechainicioStr + 'T00:00');
    this.fechafin = new Date(this.fechafinStr + 'T00:00');
    if (this.fechafin.getTime() >= this.fechainicio.getTime()) {
      this.difecha = (this.fechainicio.getTime() - this.fechafin.getTime()) + (1);
      this.dif = (this.difecha / (1000 * 3600 * 24)) * (-1);
      // console.log("evento:" + event);
      if (this.dif <= 0) {
        this.vista = 'Ar'
      } else {
        this.vista = 'Ac'
      }
      return this.vista;
    } else {

      window.alert("seleccione un rango de fechas valido");

    }
  }
  buscarDatos() {

    this.loading = true
    let data =
    {
      "NombreUsuario": "92091652929",
      "Contrasena": "92091652929"
    }

    this.service.getTokenTPA(data).then(res => {
      console.log(res)
      let codigoAsesor = ""
      let codigoTiendaTPA = ""
      let codigoConsulta = ""
      let listaTiendasConsulta = []
      if (this.codigoTienda != null) {
        this.inforTiendas.infoTiendaAll.filter(v => {

          this.codigoTienda.map(e => {
            if (e.Codigo == v.CodigoWinPOS) {
              if (e.Codigo.length == 3) {
                e.Codigo = 0 + e.Codigo
              }
              this.informacionTienda.push({ codigo: v.Regional + e.Codigo })
            }

          })
        })


      } else {
        this.infoTiendaFiltrada.map(r => {
          if (r.CodigoWinPOS.length == 3) {
            r.CodigoWinPOS = 0 + r.CodigoWinPOS
          }
          this.informacionTienda.push({ codigo: r.Regional + r.CodigoWinPOS })
        });
      }
      if (this.informacionTienda) {
        codigoAsesor = this.informacionTienda.idAsesorComercialTPA
        codigoTiendaTPA = this.informacionTienda.idTiendaTPA


      } else {
        Swal.fire('Error', 'Tienda no encontrada', 'error')
      } if (this.codigoTienda != null) {
        var dataEnviar = {
          fechaInicio: this.fechainicioStr,
          fechaFin: this.fechafinStr,
          tiendas: this.codigoTienda.map((r) => r.Codigo),
        };
      } else {
        var dataEnviar = {
          fechaInicio: this.fechainicioStr,

          fechaFin: this.fechafinStr,
          tiendas: this.infoTiendaFiltrada.map((r) => r.CodigoWinPOS),
        };
      }

      this.service.RDLIndicadoresAcumuladosPorHora(dataEnviar).then((result1) => {
        this.mostrarResultado = true;
        this.resultadoHoras = result1;

        const data = {

          "tiendas": this.informacionTienda.map(objeto => {
            if (objeto.codigo === "020678" || objeto.codigo === "040679") {
              return "51" + objeto.codigo.substring(2);
            } else {
              return objeto.codigo;
            }
          }).join(','),
          "from": this.fechainicioStr,
          "to": this.fechafinStr
        }
        const tiendasData = {}
        const listaValidos = []
        const dataTiendas = []
        this.service.getTimeTPA1(data).then(res1 => {
          console.log(res1)
          res1.forEach(element => {
            if (element.IdPersona !== null && element.Descripcion != 'Dia compensado' && element.Nombres != null) {
              const diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
              const fecha1 = element.FechaInicio.split("T")[0];
              const dateParts = fecha1.split('-');
              const year = parseInt(dateParts[0]);
              const month = parseInt(dateParts[1]) - 1;
              const day = parseInt(dateParts[2]);
              const dayOfWeek = new Date(year, month, day).getDay();

              element.DiaSemana = diasSemana[dayOfWeek];
              listaValidos.push(element)
            }

            const idTienda = element.CodigoTienda
            if (!tiendasData[idTienda]) {
              tiendasData[idTienda] = {
                IdTienda: idTienda,
                NombreTienda: element.NombreTienda,
                Fechas: [], // Array para almacenar las fechas

              };
            }
            const fecha = element.FechaInicio.split("T")[0];
            if (!tiendasData[idTienda].Fechas[fecha]) {
              tiendasData[idTienda].Fechas[fecha] = {
                Fecha: fecha,
                Turnos: [] // Array para almacenar los turnos de esta fecha
              };
            }


          });
          const transformedData = this.transformData(listaValidos);
          console.log(transformedData)
          // console.log(result1, 'resultadoprimeraconsulta');
          result1.map(c => {
            transformedData.map(g => {
              if (c.NombreDia == g.NombreDia) {
                c.Horario.map(m => {
                  const registroEncontrado = g.Horario.find(h => h.Hora == m.Hora);
                  if (registroEncontrado) {
                    m.AsesorasPorHora = registroEncontrado.AsesorasPorHora// Asignar el nuevo valor a la propiedad 'totalVentas'
                  } else {
                    m.AsesorasPorHora = 0
                  }
                })
              }
            })
          })

          result1.map(h => {
            const horasExistentes = {};
            h.Horario.forEach(item => {
              horasExistentes[item.Hora] = true;
            });
            // Recorrer las 24 horas del día
            for (let i = 0; i < 24; i++) {
              // Verificar si la hora ya existe
              if (!horasExistentes[i]) {
                // Añadir un nuevo objeto para la hora faltante
                h.Horario.push({
                  Hora: i,
                  NumeroEntradas: 0,
                  numeroTransacciones: 0,
                  numeroTransaccionesCarpa: 0,
                  cantidadArticulos: 0,
                  totalVentas: 0,
                  NombreDia: h.NombreDia, // Puedes ajustar esto según tus necesidades
                  AsesorasPorHora: 0 // Puedes ajustar esto según tus necesidades
                });
              }
            }
            h.Horario.sort((a, b) => a.Hora - b.Hora);

          })
          // Iterar a través de cada objeto Hora en dayObj.Horario
          const DIAS_DE_LA_SEMANA = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
          this.matriz = result1
          this.matriz.sort((a, b) => {
            return DIAS_DE_LA_SEMANA.indexOf(a.NombreDia) - DIAS_DE_LA_SEMANA.indexOf(b.NombreDia);
          });
          

          this.sumaPorDia = JSON.parse(JSON.stringify(result1))
          this.maxValues = {}
          this.maxValues1 = {}
          this.sumValues1 = {}

          result1.forEach((dayObj) => {

            dayObj.Horario.forEach((Hora) => {
              Hora.tasaConver = parseInt(((Hora.numeroTransacciones * 100) / Hora.NumeroEntradas).toString())
            })
          })

          result1.forEach((dayObj) => {

            dayObj.Horario.forEach((Hora) => {

              const variables = ['NumeroEntradas', 'AsesorasPorHora', 'numeroTransacciones', 'numeroTransaccionesCarpa', 'cantidadArticulos', 'totalVentas', 'tasaConver'];

              variables.forEach((variable) => {
                // Si la variable no existe en maxValues, inicialízala con el valor mínimo posible.
                if (!this.maxValues[variable]) {
                  this.maxValues[variable] = Number.MIN_SAFE_INTEGER;
                }


                // Compara y actualiza el valor máximo para cada variable.
                this.maxValues[variable] = Math.max(this.maxValues[variable], Hora[variable]);

              });
            });
          });
          this.sumaPorDia.forEach((dayObj1) => {
            // Crear un objeto para almacenar los totales
            const totales = {
              'NumeroEntradas': 0,
              'AsesorasPorHora': 0,
              'numeroTransacciones': 0,
              'numeroTransaccionesCarpa': 0,
              'cantidadArticulos': 0,
              'totalVentas': 0,
              'tasaConver': 0,
              'NombreDia': dayObj1.NombreDia || 'Desconocido', // puedes ajustar esto según tus necesidades
            };

            dayObj1.Horario.forEach((Hora) => {
              // Iterar a través de cada variable en el objeto totales
              for (const variable in totales) {
                // Si la variable existe en el objeto Hora, sumarla al total correspondiente
                if (Hora[variable] !== undefined) {
                  totales[variable] += Hora[variable];
                }
              }
            });

            // Reemplazar el array dayObj.Horario con un array que contiene solo el objeto totales
            dayObj1.Horario = [totales];
          });
          this.sumaPorDia.forEach((dayObj2) => {

            dayObj2.Horario.forEach((Hora1) => {
              Hora1.tasaConver = parseInt(((Hora1.numeroTransacciones * 100) / Hora1.NumeroEntradas).toString())
            })
          })
          this.sumaPorDia.forEach((dayObj2) => {

            dayObj2.Horario.forEach((Hora1) => {

              const variables = ['NumeroEntradas', 'AsesorasPorHora', 'numeroTransacciones', 'numeroTransaccionesCarpa', 'cantidadArticulos', 'totalVentas', 'tasaConver'];

              variables.forEach((variable1) => {
                // Si la variable no existe en maxValues, inicialízala con el valor mínimo posible.
                if (!this.maxValues1[variable1]) {
                  this.maxValues1[variable1] = Number.MIN_SAFE_INTEGER;
                }


                // Compara y actualiza el valor máximo para cada variable.
                this.maxValues1[variable1] = Math.max(this.maxValues1[variable1], Hora1[variable1]);

              });
            });
          });
          this.sumaPorDia.forEach((dayObj2) => {

            dayObj2.Horario.forEach((Hora1) => {

              const variables = ['NumeroEntradas', 'AsesorasPorHora', 'numeroTransacciones', 'numeroTransaccionesCarpa', 'cantidadArticulos', 'totalVentas', 'tasaConver'];

              variables.forEach((variable1) => {
                // Si la variable no existe en maxValues, inicialízala con el valor mínimo posible.
                if (!this.sumValues1[variable1]) {
                  this.sumValues1[variable1] = 0;
                }


                // Compara y actualiza el valor máximo para cada variable.
                this.sumValues1[variable1] = this.sumValues1[variable1] + Hora1[variable1];

              });
            });
          });
          console.log(this.sumValues1, 'HOLA')
          console.log(this.maxValues1, 'maxValues')
          console.log(this.sumaPorDia, 'sumapordia')
          console.log(this.maxValues, 'maxValues')
          console.log(this.variableColors)
          console.log(result1, 'matriz de datos')
          console.log(tiendasData, 'tiendasData');
          this.loading = false

        })
      })
    })
  }


  // ...
  formatCurrency(value: number): string {
    return value.toLocaleString('es-CO', { style: 'currency', currency: 'COP' });
  }
  calcularTotalesPorDia() {
    this.matriz.forEach(dia => {
      let nombreDia = dia.NombreDia;
      this.totalPorDiaYVariable[nombreDia] = {};

      dia.Horario.forEach(horario => {
        this.selectedVariables.forEach(variable => {
          if (!this.totalPorDiaYVariable[nombreDia][variable]) {
            this.totalPorDiaYVariable[nombreDia][variable] = 0;
          }

          this.totalPorDiaYVariable[nombreDia][variable] += horario[variable];
        });
      });
    });
  }
  // sumaTotalPorDia(i): number {
  //   this.matriz.forEach(dia => {
  //     let nombreDia = dia.NombreDia;
  //     this.totalPorDiaYVariable[nombreDia] = {};

  //     dia.Horario.forEach(horario => {
  //       this.selectedVariables.forEach(variable => {
  //         if (!this.totalPorDiaYVariable[nombreDia][variable]) {
  //           this.totalPorDiaYVariable[nombreDia][variable] = 0;
  //         }

  //         this.totalPorDiaYVariable[nombreDia][variable] += horario[variable];
  //       });
  //     });
  //   });
  // }

  getDynamicColor(value: number, maxValue: number, baseColor: string): string {
    if (value === undefined || maxValue === undefined || baseColor === undefined) {
      return 'transparent'; // Puedes retornar cualquier valor por defecto
    }

    // Calcula un coeficiente para el valor actual basado en el valor máximo.
    const coefficient = value / maxValue;

    // Usa TinyColor para manipular el color.
    const modifiedColor = new TinyColor(baseColor).lighten(100 - coefficient * 100).toString();

    return modifiedColor;
  }

  selectVariable(variable: string): void {
    this.calcularTotalesPorDia();
    const index = this.selectedVariables.indexOf(variable);
    if (index > -1) {
      this.selectedVariables.splice(index, 1);
    } else {
      this.selectedVariables.push(variable);
    }
  }
  transformData(data: any[]) {
    const result = data.reduce((acc, curr) => {
      // Convertimos las horas de inicio y fin a enteros para facilitar la comparación
      const startHour = parseInt(curr.TurnoInicio.split(":")[0]);
      const endHour = parseInt(curr.TurnoFin.split(":")[0]);

      // Buscamos si el día ya existe en el acumulador
      const existingDay = acc.find((day: any) => day.NombreDia === curr.DiaSemana);

      if (existingDay) {
        // Si el día ya existe, actualizamos el conteo de asesoras por hora
        for (let i = startHour; i <= endHour; i++) {
          const existingHour = existingDay.Horario.find((hour: any) => hour.Hora === i);
          if (existingHour) {
            existingHour.AsesorasPorHora++;
          } else {
            existingDay.Horario.push({ Hora: i, AsesorasPorHora: 1, NombreDia: curr.DiaSemana });
          }
        }
      } else {
        // Si el día no existe, lo añadimos al acumulador
        const newDay = {
          NombreDia: curr.DiaSemana,
          fechaSincronizacion: new Date().toISOString(),
          Horario: []
        };

        for (let i = startHour; i <= endHour; i++) {
          newDay.Horario.push({ Hora: i, AsesorasPorHora: 1, NombreDia: curr.DiaSemana });
        }

        acc.push(newDay);
      }

      return acc;
    }, []);

    return result;
  }
  // Función para unir los datos

  openLg2(content, index) {


    console.log(content)
    this.Horas = this.resultadoFinal2[index].Horas
    this.HoraSeleccionada = this.resultadoFinal2[index].Fecha
    // this.generarGrafico();

    this.modalService.open(content, { size: 'lg' });
  }

  // generarGrafico() {
  //   // Recorrer los datos de resultadoFinal para obtener las horas, entradas y asesoras
  //   this.resultadoFinal.forEach((item) => {
  //     const horas = item.Horas.map((hora) => hora.Hora.toString());
  //     const entradas = item.Horas.map((hora) => hora.Entradas);
  //     const asesoras = item.Horas.map((hora) => hora.AsesorasPorHora);

  //     // Add the hours to the labels
  //     this.labels.push(...horas);

  //     // Add the entries and advisors to the series array
  //     this.series.push({
  //       name: 'Entradas',
  //       data: entradas,
  //     });
  //     this.series.push({
  //       name: 'Asesoras por Hora',
  //       data: asesoras,
  //     });
  //   });

  //   // Configure chart options
  //   this.chartOptions = {
  //     series: this.series,
  //     chart: {
  //       type: 'line',
  //       height: 350,
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       width: [2, 2],
  //     },
  //     title: {
  //       text: 'Entradas y Asesoras por Hora',
  //       align: 'left',
  //     },
  //     xaxis: {
  //       categories: this.labels,
  //     },
  //     yaxis: [
  //       {
  //         title: {
  //           text: 'Entradas',
  //         },
  //       },
  //       {
  //         opposite: true,
  //         title: {
  //           text: 'Asesoras por Hora',
  //         },
  //       },
  //     ],
  //   };

  // }

  // ordenarHoras(): void {
  //   this.Horas.sort((a, b) => {
  //     return new Date(a).getTime() - new Date(b).getTime();
  //   });
  // }
  // ordenarFechas(): void {
  //   this.resultadoFinals.sort((a, b) => {
  //     return new Date(a).getTime() - new Date(b).getTime();
  //   });
  // }
  /**
    * Is Hovered
    *
    * @param date
    */
  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  /**
 * Is Inside
 *
 * @param date
 */
  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  /**
   *  Is Range
   *
   * @param date
   */
  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
  isDisabled = (date: NgbDate, current: { month: number, year: number }) => date.month !== current.month;



  limpiarFiltFor(infotienda: any = this.inforTiendas.infoTienda) {

    var arregloAgencia = [];
    if (this.rol == "GERENTE" && !this.excepciones.join(',').includes(this.coordinador)) {
      var agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia, Gerente: x.Gerente }; });

      agencia.map((a) => {
        if (arregloAgencia.filter(p => p.codigoAgencia == a.codigoAgencia && p.Gerente == this.coordinador).length == 0)
          arregloAgencia.push(a);
      });
      // console.log(arregloAgencia);
      this.filtroAplicados = this.filtroAplicados.filter(p => p != "Formato");
      this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
      if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {
        this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia && p.Gerente == this.coordinador).length > 0 });
        this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
        this.formatoTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }

      if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

        this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.ciudad.filter(d => p.CIUDAD == d && p.Gerente == this.coordinador).length > 0 });
        this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
        this.formatoTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }
      if (this.filtroAplicados.length == 0) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        this.formatos = [...new Set(infotienda.map(x => x.Formato))];
        this.formatoTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }
    } else {
      if (this.rol == "COORDINADOR" && !this.excepciones.join(',').includes(this.coordinador)) {
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "Formato");
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {
          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia && p.Responsable == this.coordinador).length > 0 });
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.ciudad.filter(d => p.CIUDAD == d && p.Responsable == this.coordinador).length > 0 });
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
          this.formatos = [...new Set(infotienda.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
      } else {
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "Formato");
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {
          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia).length > 0; });
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.ciudad.filter(d => p.CIUDAD == d).length > 0 });
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
          this.formatos = [...new Set(infotienda.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
      }

    }
  }
  limpiarFiltPv() {
    this.codigoTienda = null;
  }

}
