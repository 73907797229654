import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core'
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { InfoTiendaData } from '../../../mock/tiendas'
import { ExcelService } from 'app/services/excelservice';
import { ServiceService } from 'app/services/service.service';
import { NgbDateStruct, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { DiasMes } from "../../../mock/diasmes";
@Component({
  selector: 'app-concesiones',
  templateUrl: './concesiones.component.html',
  styleUrls: ['./concesiones.component.scss']
})
export class ConcesionesComponent implements OnInit {
  contentHeader: { headerTitle: string; actionButton: boolean; breadcrumb: { type: string; links: ({ name: string; isLink: boolean; link: string; } | { name: string; isLink: boolean; link?: undefined; })[]; }; };
  public fromDate: any
  public toDate: any
  model: NgbDateStruct;

  public selectMulti: Observable<any[]>;
  public selectMultiSelected = [{}];

  public hoveredDate: NgbDate | null = null;
  public loading = false;
  presupuestomes: any = 0;
  public pageBasic = 1;
  public page = 1;
  public pageSize = 10;
  ventastotaldia: number = 0;
  ventastotalmes: any = 0;
  porcentajedia: number = 0;
  porcentajemes: number = 0;
  show: number = 0;
  show1: number = 0;
  porcentajefaltantedia: number = 0;
  porcentajefaltantemes: number = 0;

  presupuestomenosventasdia: number = 0;
  presupuestomenosventasmes: number = 0;
  mesames: any;
  nombretienda: string;
  cumplimientohistorico: any;
  cantidadVentasDia: any = 0;
  ventasFisicasActualDia: any = 0;
  ventasVirtualesActualDia: number = 0;
  cantidadVentasMesActual: any = 0;
  ventasFisicasMesActual: any = 0;
  ventasVirtualesMesActual: any = 0;
  devoluciones: any;
  ventasFisicasMesActual1: number = 0;
  ventasFisicasActualDia1: number = 0;
  devolucionesActualDia: number = 0;
  devolucionesMesActual: number = 0;
  cantidadVentasDigitaldia: number = 0;
  cantidadVentasDigitalMes: number = 0;
  presupuestoActualDigitalDia: number = 0;
  presupuestoDigitalMesActual: number = 0;
  porcentajeDigitaldia: number = 0;
  porcentajeDigitalmes: number = 0;
  cantidadArticulosTotalesDia: number = 0;
  cantidadArticulosTotalesMesActual: any = 0;
  cantidadTransaccionesMesActual: number = 0;
  cantidadTransaccionesDia: number = 0;
  ticketpromediodia: number = 0;
  ticketpromediomes: number = 0;
  articulosPorTicketDia: number = 0;
  articulosPorTicketMes: number = 0;
  totalCreditoKaiowaMesActual: number = 0;
  totalCreditoKaiowaDiaActual: number = 0;
  presupuestoCreditoKaiowaDia: number = 0;
  presupuestoKaiowaMesActual: number = 0;
  porcentajeDiaKaiowa: number = 0;
  porcentajeMesKaiowa: number = 0;

  formatos = [];
  tiendas = [];
  agencias = [];
  ciudades = [];
  responsables: any[];
  agencia: any = {};
  pendientekaiowa: number;
  pendientedigital: number;
  vpt: number = 0;
  upt: number = 0;
  pp: number = 0;
  vptsupt: number = 0;
  fechainicioStr: string;
  idTienda: number;
  fechainicio: Date;
  fechainidash: string;
  diadia: any;
  totalventas: number = 0;
  totalpresup: number = 0;
  totalporcent: number = 0;
  totalpendiente: number = 0;
  fechafin: Date;
  mostrarfiltros: boolean = true;
  fechafinStr: string;
  fechafindash: string;
  prespuestoentrefechas: number = 0;
  totalventasentrefechas: number = 0;
  porcentajeentrefechas: number = 0;
  porcentajefaltanteentrefechas: number = 0;
  presupuestomenosventasentrefechas: number = 0;
  mostrarfiltrosi: boolean = false;

  ventadigital: number = 0;
  ventafisica: number = 0;

  nombre: string = 'Faltante';
  totalDevoluciones: number = 0;
  ventafisica1: number = 0;
  cantidadVentas: number = 0;
  cantidadTransacciones: number = 0;
  cantidadArticulosTotales: number = 0;
  ticketpromedio: number = 0;
  articulosporticket: number = 0;
  totalCreditoKaiowa: number = 0;
  presupuestoCreditoKaiowa: number = 0;
  cumplimientoKaiowa: number = 0;
  presupuestoDigital: number = 0;
  cumplimientoRdl: number = 0;
  totaltotal: number;
  totaltotalventas: number = 0;
  totalpresupuesto: number = 0;
  totalcumplimiento: number = 0;
  totalpendiente1: number = 0;
  presupuestoFisico: number = 0;
  pendientedig: number = 0;
  ventassobretransacciones: number = 0;
  cumplimientovpt: number = 0;
  articulossobretransacciones: number = 0;
  precioprom: number = 0;
  vptsobreupt: number = 0;
  pendientecrkaiowa: number = 0;
  participacionfisico: number = 0;
  participaciondigital: number = 0;
  participaciontotal: number = 0;
  diar: any;
  codigoTienda: any = null;
  agenciaTienda: any;
  formatoTienda: any = null;
  vista: any = "Ar";
  ciudad: any = null;
  difecha: any;
  dif: number;
  // arregloAgencia: void;
  color: string;
  colorPf: boolean;
  colorCf: boolean;
  colorPd: boolean;
  colorPt: boolean;
  colorCd: boolean;
  colorCt: string;
  colorPvpt: boolean;
  colorPupt: boolean;
  colorPpp: boolean;
  colorPtc: boolean;

  colorPent: boolean;
  colorPkai: boolean;
  colorCvpt: boolean;
  colorCupt: boolean;
  colorCpp: boolean;
  colorCtc: boolean;
  colorCent: boolean;
  colorCkai: boolean;

  infoTiendaFiltrada: any;
  rol: string;
  mostrarCampo: boolean = true;

  coordinador: string;
  infoTiendaFiltradaAge: any;

  filtroAplicados: string[] = [];
  colorPfi: boolean;
  colorPdi: boolean;
  colorPtot: boolean;
  colorTrans: boolean;
  mostrarsincronizacion: boolean;
  mostrarVentas: boolean;
  mostrarIndicadores: boolean;
  mostrarPotenciales: boolean;
  colorTransTotal: string;
  colorTransDia: string;
  colorTransss: string;
  mostrarDetalleVentasFisicos: boolean = false;
  mostrarDetalleVentasDigitaless: boolean = false;
  mostrarDetalleVentasTotal: boolean = false;
  mostrarDetalleVentasCreditos: boolean = false;
  mostrarDetallesVPTs: boolean = false;
  mostrarDetallesUPTs: boolean = false;
  mostrarDetallesPPs: boolean = false;
  mostrarDetallesTCs: boolean = false;
  mostrarDetallesEntrada: boolean = false;
  flecharoja: boolean;
  flechaRojacDig: boolean;
  flecharojacfis: boolean;
  flechaRojaPdig: boolean;
  flecharojaPt: boolean;
  flechaRojaPfi: boolean;
  flechaRojaPdi: boolean;
  flechaRojaPvpt: boolean;
  flechaRojaCvpt: boolean;
  flechaRojaPupt: boolean;
  flechaRojaCupt: boolean;
  flechaRojaPpp: boolean;
  flechaRojaCpp: boolean;
  flechaRojaPtc: boolean;
  flechaRojaCtc: boolean;
  flechaRojaPent: boolean;
  flechaRojaCent: boolean;
  flechaRojaPkai: boolean;
  flechaRojaCkai: boolean;
  flecaRojaPtot: boolean;
  deshabilitado: boolean = false;
  flechaRojaTra: boolean;
  colorcTrans: boolean;
  flechaRojacTrans: boolean;
  desdehasta: string;
  codigoarray: any;
  mostrarDetalleTransacciones: boolean = false;
  mostrarDetallePartCreditos: boolean;
  fechaSincronizacionCam: any;
  intervalId: number;
  token: string;
  pTotal: any;
  pendTotal: any;
  excepciones: string[];
  variable: any;
  fechaAux: any;
  fechaAuxFin: any;
  consultaAnterior: number = 0;
  colorTransCredTotal: string;
  colorTransCred: boolean;
  flechaRojaTransCred: boolean;
  colorTranskai: boolean;
  flechaRojaTranskai: boolean;
  colorCumplimTotal: boolean;
  flechaRojaCumplimTotal: boolean;
  colorcrecCantCedValid: boolean;
  flechaRojacrecCantCedValid: boolean;
  colorCcrecim: boolean;
  flechaverdeCcrecim: boolean;
  flecharojaCcrecim: boolean;
  mostrado: boolean = false;
  counte: any;
  acumPptoVPT: any;
  dataEnviar: { vista: string; fechaInicio: string; fechaFin: string; tiendas: any; };
  resultado: any;
  resultado1: any;
  longitud: any;
  constructor(private modalService: NgbModal, private diamess: DiasMes, private afMessaging: AngularFireMessaging, private calendar: NgbCalendar, public excelService: ExcelService, private router: Router, public formatter: NgbDateParserFormatter, private inforTiendas: InfoTiendaData, private service: ServiceService) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Home',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'Concesiones',
            isLink: false
          }
        ]
      }
    }
    this.excepciones = ["Julian Alberto Navarro Garcia","Leydi Milena Peña Sepulveda","Mariana Ossa Sanchez", "Juan Carlos Gallego Elejalde", "Melissa Castrillon Cano", "Jose Mario Tamayo Lopera","Carlos Ignacio Urrea", "Juan Carlos Maya Arbelaez", "Mauricio Andres Cardona Suarez", "Ana Lucia Hernandez Santa", "Alejandro Gonzalez Vasquez", "Maria Camila Cardona Arroyave", "Maria Cristina Londoño Cruz", "David  Gomez Patiño", "Soraya  Bedoya Mejia", "Margarita Maria Mazo Franco", "Luis Guillermo Maya Arbelaez", "Sergio Alexander Villa Cano"];
    this.rol = sessionStorage.getItem('rol');
    // var d = new Date();
    // var year = d.getFullYear();
    // var month = d.getMonth() + 1
    // var day = d.getDate();
    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    // this.fechainicioStr = year.toString() + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
    // this.fechafinStr = year.toString() + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
    this.coordinador = sessionStorage.getItem('responsable');
    this.fillFilter(this.inforTiendas.infoConcesion)
  }
  onDateSelection(date: NgbDate, datepicker, modalBasic) {
    this.modalOpen(modalBasic)
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate) {
      this.toDate = date;
      datepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
    this.fechainicio = new Date(this.fechainicioStr + 'T00:00');
    this.fechafin = new Date(this.fechafinStr + 'T00:00');
    if (this.fechafin.getTime() >= this.fechainicio.getTime()) {
      this.difecha = (this.fechainicio.getTime() - this.fechafin.getTime()) + (1);
      this.dif = (this.difecha / (1000 * 3600 * 24)) * (-1);
      // console.log("evento:" + event);
      if (this.dif <= 0) {
        this.vista = 'Ar'
      } else {
        this.vista = 'Ac'
      }
      return this.vista;
    } else {

      window.alert("seleccione un rango de fechas valido");

    }
  }
  mostrarFiltross() {
    if (this.mostrarfiltros == true && this.mostrarfiltrosi == false) {
      this.mostrarfiltros = false
      this.mostrarfiltrosi = true
    } else {
      this.mostrarfiltros = true
      this.mostrarfiltrosi = false
    }


  }
  private fillFilter(infotienda: any) {



    this.tiendas = [...new Set(infotienda.map(x => { return { Nombre: x.NOMBRE, Codigo: x.CodigoWinPOS }; }))];


  }
  limpiarFiltPv() {
    this.codigoTienda = null;
  }
  buscar() {
    this.loading = true
    if (this.codigoTienda != null) {
      this.dataEnviar = {
        vista: "Ac",
        fechaInicio: this.fechainicioStr,
        fechaFin: this.fechafinStr,
        tiendas: this.codigoTienda.map(r => r.Codigo)
      }
    } else {
      this.dataEnviar = {
        vista: "Ac",
        fechaInicio: this.fechainicioStr,
        fechaFin: this.fechafinStr,
        tiendas: this.tiendas.map(r => r.Codigo)
      }

    }

    this.service.RDLIndicadoresAcumuladosMesMuchasTiendas(this.dataEnviar).then(result => {
      this.loading = false;
      console.log(result)
      this.diar = result.meses.map(e => {
        e["totalVentas"] = parseFloat(e.totalVentasFisicas) - parseFloat(e.totalDevoluciones);
        return e
      })
      this.service.RDLIndicadoresAcumuladosPorFechas(this.dataEnviar).then(r => {
        console.log(r)
        this.resultado = r.map(x => {
          x["totalVentasFisicass"] = parseFloat(x.totalVentasFisicas) - parseFloat(x.totalDevoluciones);
          x["totalVentas"] = parseFloat(x["totalVentasFisicass"]) + parseFloat(x.totalVentasVirtuales);
          x['totalUnidades'] = parseFloat(x.cantidadArticulosFisicos) + parseFloat(x.cantidadArticulosDigitales);
          return x
        })
        console.log(this.resultado)
        this.service.ObtenerTasaDeConversionAcumuladaDiaria(this.dataEnviar).then(w => {
          console.log(w)
          this.resultado1 = w.map(x => {
            x["totalVentasFisicass"] = parseFloat(x.totalVentasFisicas) - parseFloat(x.totalDevoluciones);
            x["totalVentas"] = parseFloat(x["totalVentasFisicass"]) + parseFloat(x.totalVentasVirtuales);
            x['totalUnidades'] = parseFloat(x.cantidadArticulosFisicos) + parseFloat(x.cantidadArticulosDigitales);
            x['fecha'] = x.dia.toString() + '/' + x.mes.toString() + '/' + x.anio.toString();
            return x
          })
          this.longitud = this.resultado1.length;
          console.log(this.resultado1)
        })


      })
    })
  }
  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
  /**
 * Is Hovered
 *
 * @param date
 */
  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  /**
   * Is Inside
   *
   * @param date
   */
  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }
  modalOpen(modalXS) {
    if (this.mostrado == false) {
      this.mostrado = true
      this.modalService.open(modalXS, {
        centered: true,
        size: 'xs'
      });
    }

  }
  seleccionarHoy() {
    this.consultaAnterior = 1
    this.vista = 'Ar';
    this.fromDate = this.calendar.getToday()
    this.toDate = this.calendar.getToday()
    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
  }
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
  seleccionarAnterior() {
    this.vista = 'Ac';
    this.consultaAnterior = 1
    var ano: any
    var mes: any
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    var mesActual = this.calendar.getToday().month
    if (mesActual.toString().padStart(2, "0") == "01") {
      mesActual = 13
    }
    this.diamess.mes.map(x => {
      if (x.Mes == (mesActual - 1).toString().padStart(2, "0")) {
        if (mesActual == 13) {
          this.fechainicioStr = (this.fromDate.year - 1).toString() + "-" + (mesActual - 1).toString().padStart(2, "0") + "-" + x.diaInicio;
          this.fechafinStr = (this.fromDate.year - 1).toString() + "-" + (mesActual - 1).toString().padStart(2, "0") + "-" + x.diaFin;
          ano = (this.fromDate.year - 1).toString()
          mes = (mesActual - 1).toString().padStart(2, "0")
          this.fromDate = { "day": parseInt(x.diaInicio), "month": parseInt(mes), "year": parseInt(ano) }
          this.toDate = { "day": parseInt(x.diaFin), "month": parseInt(mes), "year": parseInt(ano) }

        } else {
          this.fechainicioStr = this.fromDate.year.toString() + "-" + (mesActual - 1).toString().padStart(2, "0") + "-" + x.diaInicio;
          this.fechafinStr = this.toDate.year.toString() + "-" + (mesActual - 1).toString().padStart(2, "0") + "-" + x.diaFin;
          ano = this.toDate.year.toString()
          mes = (mesActual - 1).toString().padStart(2, "0")
          this.fromDate = { "day": parseInt(x.diaInicio), "month": parseInt(mes), "year": parseInt(ano) }
          this.toDate = { "day": parseInt(x.diaFin), "month": parseInt(mes), "year": parseInt(ano) }

        }

      }
    })

    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr;
  }
  seleccionarAyer() {
    this.consultaAnterior = 1
    this.vista = 'Ar';
    this.fromDate = this.calendar.getNext(this.calendar.getToday(), 'd', -1);
    this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', -1);
    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
  }

  seleccionarMesActual() {
    var ano: any
    var mes: any
    this.vista = 'Ac';
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    var mesActual = this.calendar.getToday().month
    this.diamess.mes.map(x => {
      if (x.Mes == mesActual.toString().padStart(2, "0")) {
        this.fechainicioStr = this.fromDate.year.toString() + "-" + this.fromDate.month.toString().padStart(2, "0") + "-" + x.diaInicio;
        this.fechafinStr = this.fromDate.year.toString() + "-" + this.fromDate.month.toString().padStart(2, "0") + "-" + x.diaFin;
        ano = (this.fromDate.year).toString()
        mes = (mesActual).toString().padStart(2, "0")
        this.fromDate = { "day": parseInt(x.diaInicio), "month": parseInt(mes), "year": parseInt(ano) }
        this.toDate = { "day": parseInt(x.diaFin), "month": parseInt(mes), "year": parseInt(ano) }
      }
    })
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr;
  }

  enviarexceventas() {

    const datos = this.resultado.map(element => {
      return {
        IdAlmacen: element.agencia.toString() + element.idAlmacen.toString(),
        Nombre: element.Nombre, VentaFisica: element.totalVentasFisicass,

        VentaTotal: element.totalVentas,

        Articulos: element.totalUnidades,
        Transacciones: element.numeroTransaccionesMes,


        // NroCredito:element.cantidadTransaccionesCreditos,
        // PptoNroCredito:element.PptoCreditoNro 
      }
    });

    this.excelService.exportAsExcelFile(datos, 'Ventas');

  }
  enviarexceventas1() {
    const datos = this.resultado1.map(element => {
      return {
        IdAlmacen: element.agencia.toString() + element.idAlmacen.toString(),
        Nombre: element.Nombre,
        Dia: element.nombreDelDiaSemanaAnio,
        Fecha: element.fecha,
        VentaTotal: element.totalVentas,

        Articulos: element.totalUnidades,
        Transacciones: element.numeroTransaccionesMes,


      }
    });


    this.excelService.exportAsExcelFile(datos, 'Ventas');

  }


}
