import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InfoTiendaData } from '../../../mock/tiendas'
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServiceService } from 'app/services/service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-visualizar-comprobantes',
  templateUrl: './visualizar-comprobantes.component.html',
  styleUrls: ['./visualizar-comprobantes.component.scss']
})
export class VisualizarComprobantesComponent implements OnInit {
  @ViewChild('fechaInputIni') fechaInputIni: ElementRef;
  @ViewChild('fechaInputFin') fechaInputFin: ElementRef;
  contentHeader: { headerTitle: string; actionButton: boolean; breadcrumb: { type: string; links: ({ name: string; isLink: boolean; link: string; } | { name: string; isLink: boolean; link?: undefined; })[]; }; };
  tiendas: { Nombre: string; Codigo: string; }[];
  public loading = false;
  public loading1 = false;
  codigoTienda: any;
  consignaciones: any;
  modalRef: any;
  consolidados: any;
  mostrarDetalleFechas: boolean=false;
  mostrarDetalleComprobante: boolean=false;
  comprobantes: any;
  image: any;
  listaTiendas: any;
  noSubidas: any[]=[];
  nosubidas1: any[]=[];
  index1: any;
  index2: any;
  constructor(private inforTiendas: InfoTiendaData, private service:ServiceService,private modalService: NgbModal) {
    this.tiendas = [...new Set(inforTiendas.infoTiendaAll.map(x => { return { Nombre: x.NOMBRE, Codigo: x.CodigoWinPOS }; }))];
   }

  ngOnInit(): void {
    
    
  }
  clic(){
    this.loading=true
    let codigos=[]
    
    if(this.codigoTienda!=undefined){
      if (this.codigoTienda.length >0 ){
        this.codigoTienda.map(x=>{
          codigos.push(x.Codigo)
        })
      }else{
        this.tiendas.map(x=>{
          codigos.push(x.Codigo)
        })
      }
    }else{
      this.tiendas.map(x=>{
        codigos.push(x.Codigo)
      })
    }
    
    const listasTiendas=[]
    let data = {
      tiendas: codigos.join(','),
      fechaInicial: this.fechaInputIni.nativeElement.value.replace(/-/g, ''),
      fechaFinal: this.fechaInputFin.nativeElement.value.replace(/-/g, ''), 
      diferencia:0
    }
    console.log(data)
    this.service.getComprobantes(data).then((res)=>{
      this.loading=false
      console.log(res)
      this.consignaciones=res.consignaciones
      this.listaTiendas=res.consignaciones.map(x=>{
        listasTiendas.push({codigopv:x.codigoAlmacen,nombrepv:x.nombreAlmacen})
      })

      this.noSubidas = this.tiendas.filter((tienda) => {
        return !listasTiendas.find((tiendaConsignacion) => {
          return tiendaConsignacion.codigopv.toString() === tienda.Codigo  
        })
      });
      // codigos.filter(x=>listasTiendas.map(y=>{
      //   if(y.codigopv.toString()==x){
          
      //   }else{
      //     this.noSubidas.push(y)
      //   }
      // }))
      console.log(this.noSubidas)
      // this.noSubidas=this.noSubidas.map(o=>{
      //   this.tiendas.map(p=>{
      //     if(o==p.Codigo){
      //       this.nosubidas1.push(p)
      //     }
      //   })
      // })
      console.log(this.tiendas)
    })
  }

  clic1(){
    this.loading=true
    let codigos=[]
    
    if(this.codigoTienda!=undefined){
      if (this.codigoTienda.length >0 ){
        this.codigoTienda.map(x=>{
          codigos.push(x.Codigo)
        })
      }else{
        this.tiendas.map(x=>{
          codigos.push(x.Codigo)
        })
      }
    }else{
      this.tiendas.map(x=>{
        codigos.push(x.Codigo)
      })
    }
    
    
    let data = {
      tiendas: codigos.join(','),
      fechaInicial: this.fechaInputIni.nativeElement.value.replace(/-/g, ''),
      fechaFinal: this.fechaInputFin.nativeElement.value.replace(/-/g, ''),
      diferencia:1
    }
    console.log(data)
    this.service.getComprobantes(data).then((res)=>{
      this.loading=false
      console.log(res)
      this.consignaciones=res.consignaciones
    })
  }

  detalleFechas(index){
    
      this.mostrarDetalleFechas=true
    
    
    this.consolidados=this.consignaciones[index].consolidadoFechas
  }
  verComprobante(index){
   
      this.mostrarDetalleComprobante=true
    
    
    this.comprobantes=this.consolidados[index].detalleComprobante
  }
  
  openLg(content,index) {

  console.log(content)
  this.index1=index
  this.detalleFechas(index)
    this.modalService.open(content, { size: 'lg' });
  }
  openLg2(content,index) {
    this.index2=index

    console.log(content)
    this.verComprobante(index)
      this.modalService.open(content, { size: 'lg' });
    }
    openLg3(content,index) {
      this.loading1=false
      console.log(content)
      var data={
        "idPais": 169,
        "idAlmacen": this.consignaciones[this.index1].codigoAlmacen,
        "FechaCreacion": this.consolidados[this.index2].detalleComprobante[0].fechaCreacion.split('T')[0],
        "Item":this.consolidados[this.index2].detalleComprobante[0].item
      }
      console.log(data)
      this.service.getBase64(data).then((res)=>{
        if(res){
          this.loading1=true
          console.log(res)
          this.image=res
          this.modalService.open(content, { size: 'lg' });
        }else{
          Swal.fire('Error','No se puede mostrar la imagen','error')
        }
        
      })
      // this.image=this.comprobantes[index].comprobante
        
      }

}
