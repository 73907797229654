<div *ngIf="mostrarfiltros" class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="sample-page">
      <section class="range-selection-DP">
        <div class="row">
          <div class="col-12">
            <div class="card">

              <div class="card-body">

                <!-- Range selection Date Picker -->
                <div class="card">
                  <div class="row">
                    <div class="col-12">
                      <span style="font-weight: bolder; margin-left: 1rem;">Fechas</span>
                      <div class="row" style="margin-left:1rem;">
                        <form class="form-inline">
                          <div class="form-group">
                            <div class="input-group w-0 ">
                              <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                                [autoClose]="'outside'" [showWeekNumbers]="false"
                                (dateSelect)="onDateSelection($event,datepicker)" [displayMonths]="1" [dayTemplate]="t"
                                outsideDays="visible" [startDate]="fromDate!" tabindex="-1" />
                              <ng-template #t let-date let-currentMonth="currentMonth" let-selected="selected"
                                let-focused="focused">
                                <span class="custom-day" [class.focused]="focused"
                                  [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled"
                                  [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)"
                                  (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                  {{ date.day }}


                                </span>

                              </ng-template>

                            </div>
                          </div>
                          <div class="form-group">
                            <div class="input-group">
                              <input style="display: none;" #dpFromDate class="form-control" placeholder="yyyy-mm-dd"
                                name="dpFromDate" [value]="formatter.format(fromDate)"
                                (input)="fromDate = validateInput(fromDate, dpFromDate.value)" disabled />
                              <div class="input-group fecha">
                                <input class="fecha" type="text" [value]="desdehasta" name="" id="" disabled>
                              </div>
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary feather icon-calendar"
                                  (click)="datepicker.toggle()" type="button" rippleEffect></button>

                              </div>
                            </div>
                          </div>
                          <div class="form-group ml-sm-2" style="display: none;">
                            <div class="input-group">
                              <input #dpToDate class="form-control" placeholder="yyyy-mm-dd" name="dpToDate"
                                [value]="formatter.format(toDate)" disabled />
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary feather " (click)="datepicker.toggle()"
                                  type="button" rippleEffect disabled></button>
                              </div>
                            </div>
                          </div>
                        </form>



                        <div class="row">
                          <div class="columnbut">
                            <button class="btn btn-outline-primary btn-sm m-2 float-left" (click)="seleccionarAnterior()"
                              rippleEffect>
                              Seleccionar Mes Anterior
                            </button>
                          </div>
                          <div class="columnbut">
                            <button class="btn btn-outline-primary btn-sm m-2 float-left" (click)="seleccionarMesActual()"
                              rippleEffect>
                              Seleccionar Mes Actual
                            </button>
                          </div>
                          <div class="columnbut">

                            <button class="btn btn-outline-primary btn-sm m-2 float-left" (click)="seleccionarHoy()"
                              rippleEffect>
                              Seleccionar Hoy</button>

                          </div>

                          <div class="columnbut">

                            <button class="btn btn-outline-primary btn-sm m-2 float-left" (click)="seleccionarAyer()"
                              rippleEffect>
                              Seleccionar Ayer</button>

                          </div>
                        </div>
                        <!-- <div class="columnbut">
    
                            <button class="btn btn-outline-primary btn-sm m-2 float-left" (click)="seleccionarHoy()"
                              rippleEffect>
                              Seleccionar este mes</button>
    
                          </div> -->
                      </div>
                    </div>
                  </div>
                </div>

                
              </div>

              <div class="row" style="display: inline-flex;">

                <div class="column" style="width:20% ">
                  <div class="row">
                    <span style="font-weight: bolder;">Agencia</span>
                    <div *ngIf="mostrarfiltros" class="columy" style="padding-left: unset;padding-right: unset;">

                      <!-- <ng-select [disabled]="deshabilitado" placeholder="Agencia"
                          style="border: 1px;border-radius: 5px;" [(ngModel)]="agenciaTienda"
                          (ngModelChange)="filterAge($event)">
                          <ng-option value="null">Agencia</ng-option>
                          <ng-option *ngFor="let item of agencias" selected [value]="item.codigoAgencia">
                            {{item.nombreAgencia}}
                          </ng-option>
  
  
                        </ng-select> -->
                      <ng-select [clearSearchOnAdd]="true" [clearable]="false" (blur)="filterAge($event)" [disabled]="deshabilitado" [items]="agencias"
                        [multiple]="true" [closeOnSelect]="false" [searchable]="true" bindLabel="nombreAgencia"
                        placeholder="Seleccione Agencias" [(ngModel)]="agenciaTienda">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label" [value]="item.codigoAgencia"> {{item.nombreAgencia}}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>

                    </div>
                    <div *ngIf="mostrarfiltros" class="columx" style="padding-left: unset;padding-right: unset;">
                      <span *ngIf="!deshabilitado"><button *ngIf="mostrarCampo" type="button"
                          class="btn btn-flat-danger" rippleEffect (click)="limpiarFiltAgen()"
                          title="limpiar filtro">X</button></span>
                    </div>
                  </div>
                </div>
                <div class="column" style="width:20% ">
                  <div class="row">
                    <span style="font-weight: bolder;">Formato</span>
                    <div *ngIf="mostrarfiltros" class="columy" style="padding-left: unset;padding-right: unset;">
                      <!-- <ng-select placeholder="Formato" style="border: 1px;border-radius: 5px;"
                          [(ngModel)]="formatoTienda" (ngModelChange)="filterFormat($event)">
                          <ng-option selected value="null">Formato</ng-option>
                          <ng-option *ngFor="let item of formatos" [value]="item">{{item}}
                          </ng-option>
  
  
                        </ng-select> -->
                      <ng-select [clearSearchOnAdd]="true" [clearable]="false" (blur)="filterFormat($event)" [items]="formatos" [multiple]="true"
                        [closeOnSelect]="false" [searchable]="true" bindLabel="item" placeholder="Seleccione formatos"
                        [(ngModel)]="formatoTienda">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label" [value]="item"> {{ item }}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>

                    </div>
                    <div *ngIf="mostrarfiltros" class="columx" style="padding-left: unset;padding-right: unset;">
                      <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger" rippleEffect
                        (click)="limpiarFiltFor()" title="limpiar filtro">X</button>
                    </div>
                  </div>
                </div>
                <div class="column" style="width:20% ">
                  <div class="row">
                    <span style="font-weight: bolder;">Ciudad</span>
                    <div *ngIf="mostrarfiltros" class="columy" style="padding-left: unset;padding-right: unset;">
                      <!-- <ng-select placeholder="Ciudad" style="border: 1px;border-radius: 5px;" [(ngModel)]="ciudad"
                          (ngModelChange)="filterPV($event)">
                          <option value="null" selected>Ciudad</option>
                          <ng-option *ngFor="let item of ciudades" [value]="item">{{item}}
                          </ng-option>
  
  
                        </ng-select> -->
                      <ng-select [clearSearchOnAdd]="true" [clearable]="false" (blur)="filterPV($event)" [items]="ciudades" [multiple]="true" [closeOnSelect]="false"
                        [searchable]="true" bindLabel="item" placeholder="Seleccione ciudad" [(ngModel)]="ciudad">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label" [value]="item"> {{ item }}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>

                    </div>
                    <div *ngIf="mostrarfiltros" class="columx" style="padding-left: unset;padding-right: unset;">
                      <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger" rippleEffect
                        (click)="limpiarFiltCiu()" title="limpiar filtro">X</button>
                    </div>
                  </div>
                </div>
                <div class="column" style="width:40% ">
                  <div class="row">

                    <div *ngIf="mostrarfiltros" class="columy" style="padding-left: unset;padding-right: unset;">
                      <span style="font-weight: bolder;">Punto Venta</span>
                      <!-- <ng-select placeholder="P V" [(ngModel)]="codigoTienda" (ngModelChange)="filterAge($event)">
                          <ng-option selected value="null">P V</ng-option>
                          <ng-option *ngFor="let item of tiendas" [value]="item.Codigo">{{item.Nombre}}
                          </ng-option>
  
  
                        </ng-select> -->
                      <!-- filterTiend($event) -->
                      <ng-select [clearSearchOnAdd]="true" [clearable]="false" [items]="tiendas" [multiple]="true" [closeOnSelect]="false" [searchable]="true"
                        bindLabel="Nombre" placeholder="Seleccione PV" [(ngModel)]="codigoTienda">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label" [value]="item.Codigo"> {{ item.Nombre }}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>


                    </div>
                    <div *ngIf="mostrarfiltros" class="columx" style="padding-left: unset;padding-right: unset;">
                      <button *ngIf="mostrarCampo" type="button" class="btn btn-flat-danger" rippleEffect
                        (click)="limpiarFiltPv()" title="limpiar filtro">X</button>
                    </div>
                  </div>
                </div>
              </div>


              <button [disabled]="loading" class="btn btn-relief-primary" tabindex="4" rippleEffect
                (click)="consultar()">
                <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>Consultar
              </button>

            </div>

          </div>

        </div>




      </section>




      <!-- Basic Alerts end -->

      <div class="row sdiv">
        <div class="col-12">

          <div class="card ">
            <!--/ Basic Date Picker -->
            <div class="card-header">
              <div class="col-lg-12 col-12">
                <div class="card card-company-table">
                  <div class="card-body p-0">
                    <div class="table-responsive">
                      <table class="table table-hover-animation">
                        <thead>
                          <tr>
                            <th>Pv</th>
                            <th>Fecha</th>
                            <th>Evento</th>
                            <th>Hora</th>


                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of obj | slice: (pageBasic-1) * pageSize : pageBasic * pageSize">

                            <td>{{item.NombreTienda}}</td>
                            <td>{{item.Fecha}}</td>
                            <td>{{item.evento}}</td>
                            <td>{{item.hora}}</td>


                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" class="btn btn-flat-success" rippleEffect (click)="enviarexceventas()"><i
                data-feather='file-text'></i>Excel</button> 
            </div>
          </div>
        </div>
      </div>


    </section>
    <div class="row ndiv">
      <div class="col-12">

        <div class="card ">
          <!--/ Basic Date Picker -->
          <div class="card-header">
            <div class="col-lg-12 col-12">
              <div class="card card-company-table">
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table table-hover-animation">
                      <thead>
                        <tr>
                          <th style="font-size: 10px;">Pv</th>
                          <th style="font-size: 10px;">Fecha</th>
                          <th style="font-size: 10px;">Evento</th>
                          <th style="font-size: 10px;">Hora</th>


                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of obj | slice: (page-1) * pageSize1 : page * pageSize1">

                          <td style="font-size: 10px;">{{item.NombreTienda}}</td>
                          <td style="font-size: 10px;">{{item.Fecha}}</td>
                          <td style="font-size: 10px;">{{item.evento}}</td>
                          <td style="font-size: 10px;">{{item.hora}}</td>


                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-flat-success" rippleEffect (click)="enviarexceventas()"><i
              data-feather='file-text'></i>Excel</button> 
          </div>
          
        </div>
        
      </div>
      
    </div>
    
  </div>
 

</div>
<div *ngIf="mostrarfiltros" class="row sdiv" style="margin-left: 1rem;">
  <ngb-pagination [collectionSize]="longitud" [(page)]="pageBasic" [pageSize]="pageSize" [maxSize]="20"
    [boundaryLinks]="true" [rotate]="true" [directionLinks]="false" aria-label="Default pagination">
  </ngb-pagination>
</div>
<div *ngIf="mostrarfiltros" class="row ndiv" style="margin-left: 1rem;">
  <ngb-pagination [collectionSize]="longitud1" [(page)]="page" [pageSize]="pageSize1" [maxSize]="3"
  [boundaryLinks]="true" [rotate]="true" [directionLinks]="true" aria-label="Default pagination"></ngb-pagination>
</div>