import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(public httpclient: HttpClient) { }
  login(user) {

    return this.httpclient.post('https://leonisavirtual.leonisa.com/pos-service/service/security/loginPOS', user)
      .toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }
  loginRecaptcha(data: any) {
    return this.httpclient.post('https://srvappsha.leonisa.com/Aplicativos_Informaticos/AuthService/api/v2/login', data)
      .toPromise()
      .then((res: any) => {
        return res;
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }


  ObtenerDatosActuales(idtienda) {

    return this.httpclient.get('https://leonisavirtual.leonisa.com/pos-service/service/sale/getBudgetSalesCurrent/' + idtienda)
      .toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }
  ObtenerDatosActualesporfecha(fechas: any) {

    return this.httpclient.post('https://leonisavirtual.leonisa.com/pos-service/service/sale/getBudgetSalesRange', JSON.stringify(fechas))
      .toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }
  ObtenerTasaDeConversion(fechaInicial: string, fechaFinal: string, codigoTienda: string) {

    return this.httpclient.get('https://us-central1-retaildigitalleonisa20.cloudfunctions.net/api/camera/' + fechaInicial + '/' + fechaFinal + '/' + codigoTienda)
      .toPromise()
      .then((res: any) => {
        // console.log(JSON.parse( JSON.parse(res)[0].resultado));
        return (JSON.parse(JSON.parse(res)[0].resultado));
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }

  // tokenServices() {
  //   const body = new URLSearchParams();
  //   body.set('grant_type', 'client_credentials');
  //   body.set('scope', 'tickets/b240aff7-7a51-4858-a197-818f41d912ee');
  //   body.set('client_id', '520fd49g3e91suu0obv9gg019e');
  //   body.set('client_secret', '1t4a41p9if3ir0hv7f35b23kqhst7o3772psskn0765jaumsu71');

  //     const headers = new HttpHeaders({
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       'Authorization': `Basic NTIwZmQ0OWczZTkxc3V1MG9idjlnZzAxOWU6MXQ0YTQxcDlpZjNpcjBodjdmMzViMjNrcWhzdDdvMzc3MnBzc2tuMDc2NWphdW1zdTcx`
  //     })

  //   return this.httpclient.post('https://braindir.auth.us-east-1.amazoncognito.com/oauth2/token', body.toString(), {headers:headers})
  //     .toPromise()
  //     .then(response => {

  //       return response;
  //     })
  //     .catch(error => {
  //       console.log('failed' + error);
  //       return error.ok;
  //     });
  // }
  tokenServices() {
    const body = new URLSearchParams();
    body.set('grant_type', 'client_credentials');
    body.set('scope', '12345678901234567890/consignaciones');
    body.set('client_id', '5dthqiu6t11dg4fqtc6dsrl9lm');
    body.set('client_secret', '1evpdvpqbpb5tnmsvqati4sqjqha687b5kdb817p1hgj5akdm4o9');

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Basic NWR0aHFpdTZ0MTFkZzRmcXRjNmRzcmw5bG06MWV2cGR2cHFicGI1dG5tc3ZxYXRpNHNxanFoYTY4N2I1a2RiODE3cDFoZ2o1YWtkbTRvOQ==`
    })

    return this.httpclient.post('https://leonisavouchers.auth.us-east-2.amazoncognito.com/oauth2/token', body.toString(), { headers: headers })
      .toPromise()
      .then(response => {

        return response;
      })
      .catch(error => {
        console.log('failed' + error);
        return error.ok;
      });
  }
  // ocrService(data:any){
  //   const auth_token=sessionStorage.getItem('brtkOcr')

  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${auth_token}`
  //   })
  //   return this.httpclient.post('https://api.braindir.com/v1/tickets', data, {headers:headers})
  //   .toPromise()
  //   .then(response => {
  //     console.log(response)
  //     return response;
  //   })
  //   .catch(error => {
  //     console.log('failed' + error);
  //     return error.ok;
  //   });
  // }
  ocrService(data: any) {
    const auth_token = sessionStorage.getItem('brtkOcr')

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`
    })
    // return this.httpclient.post('https://iaservices.leonisa360.com/v1/tickets', data, {headers:headers})
    return this.httpclient.post('https://iaservices.leonisa360.com/v1/tickets', data, { headers: headers })
      .toPromise()
      .then(response => {
        console.log(response)
        return response;
      })
      .catch(error => {
        console.log('failed' + error);
        return error.ok;
      });
  }

  RDLIndicadoresAcumuladosMesMuchasTiendas(dataEnviar: any) {

    return this.httpclient.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/RetailSuiteService/RDLIndicadoresAcumuladosMesMuchasTiendas', dataEnviar)
      .toPromise()
      .then((res: any) => {
        // console.log(JSON.parse( JSON.parse(res)[0].resultado));
        return (JSON.parse(JSON.parse(res)[0].resultado));
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }

  ObtenerTasaDeConversionAcumuladaDiaria(filtros: any) {

    return this.httpclient.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/RetailSuiteService/RDLIndicadoresAcumuladosDiario', filtros)
      .toPromise()
      .then((res: string) => {
        var resultado = ''
        JSON.parse(res).forEach(p => {
          resultado += Object.values(p)[0];
        })

        return JSON.parse(resultado);
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }

  RDLIndicadoresAcumuladosPorFechas(filtros: any) {

    return this.httpclient.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/RetailSuiteService/RDLIndicadoresAcumuladosPorFechas', filtros)
      .toPromise()
      .then((res: string) => {
        var resultado = ''
        JSON.parse(res).forEach(p => {
          resultado += Object.values(p)[0];
        })

        return JSON.parse(resultado);
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }

  getConsolidado(data: any) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `ZkGGszMM34nbFbTzcQXNvkzsYQw5mdSWpqvFz74tLge34Xt6D2`
    })

    return this.httpclient.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/metasRetailService/api/indicadores/consultarConsolidadoEfectivo', data, { headers: headers })
      .toPromise()
      .then((res: string) => {

        return (res);
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }
  getComprobantes(data: any) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `ZkGGszMM34nbFbTzcQXNvkzsYQw5mdSWpqvFz74tLge34Xt6D2`
    })

    return this.httpclient.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/metasRetailService/api/indicadores/listarConsignacionTiendas ', data, { headers: headers })
      .toPromise()
      .then((res: string) => {

        return (res);
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }
  saveConsignacion(data: any) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `ZkGGszMM34nbFbTzcQXNvkzsYQw5mdSWpqvFz74tLge34Xt6D2`
    })

    return this.httpclient.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/metasRetailService/api/indicadores/registrarConsignacionTiendas', data, { headers: headers })
      .toPromise()
      .then((res: string) => {

        return (res);
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }


  obtenerHoraEntradaSalida(filtros: any) {

    return this.httpclient.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/RetailSuiteService/RDLIndicadoresHorarioTiendas', filtros)
      .toPromise()
      .then((res: any) => {
        // console.log(JSON.parse( JSON.parse(res)[0].resultado));
        return (JSON.parse(JSON.parse(res)[0].resultado));
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });
  }

  RDLIndicadoresAcumuladosPorHora(filtros: any) {
    return this.httpclient.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/RetailSuiteService/RDLIndicadoresAcumuladosPorHora', filtros)
      .toPromise()
      .then((res: any) => {
        var resultado = ''
        JSON.parse(res).forEach(p => {
          resultado += Object.values(p)[0];
        })
        // console.log(JSON.parse(resultado));
        return (JSON.parse(resultado));
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });
  }

  getUserSecurityModule(data: any) {
    return this.httpclient.post('https://srvappsha.leonisa.com/Aplicativos_Informaticos/AuthService/api/v2/login', data)
      .toPromise()
      .then((res: any) => {
        return res;
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }





  guardarPpto(data: any) {
    const auth_token = sessionStorage.getItem('brt')
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`
    })
    return this.httpclient.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/metasRetailService/api/indicadores/carguePresupuesto', data, { headers: headers })
      .toPromise()
      .then((res: any) => {
        return res;
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }
  guardarVoluminizacion(data: any) {
    const auth_token = sessionStorage.getItem('rt')
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`
    })
    return this.httpclient.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/metasRetailService/api/Indicadores/cargueVoluminizacion', data, { headers: headers })
      .toPromise()
      .then((res: any) => {
        return res;
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }
  getVoluminizacionByDate(data: any) {
    const auth_token = sessionStorage.getItem('rt')
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`
    })
    // return this.httpclient.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/metasRetailService/api/Indicadores/getVoluminizacionTiendas2', data, { headers: headers })
    return this.httpclient.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/metasRetailService/api/Indicadores/consultarResultadoConsolidados', data, { headers: headers })
      .toPromise()
      .then((res: any) => {
        return res;
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }
  getVoluminizacionByDates(data: any) {
    const auth_token = sessionStorage.getItem('rt')
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`
    })
    // return this.httpclient.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/metasRetailService/api/Indicadores/getVoluminizacionTiendas2', data, { headers: headers })
    return this.httpclient.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/metasRetailService/api/Indicadores/getVoluminizacionByDate', data, { headers: headers })
      .toPromise()
      .then((res: any) => {
        return res;
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }
  getFactorEstacional(data: any) {
    const auth_token = sessionStorage.getItem('rt')
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`
    })
    // return this.httpclient.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/metasRetailService/api/Indicadores/getVoluminizacionTiendas2', data, { headers: headers })
    return this.httpclient.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/metasRetailService/api/Indicadores/resposicionSugerida', data, { headers: headers })
      .toPromise()
      .then((res: any) => {
        return res;
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }
 


  ObtenerTasaDeConversionDia(fechaInicial: string, fechaFinal: string, codigoTienda: string) {

    return this.httpclient.get('https://us-central1-retaildigitalleonisa20.cloudfunctions.net/api/camera/dia/' + fechaInicial + '/' + fechaFinal + '/' + codigoTienda)
      .toPromise()
      .then((res: any) => {
        // console.log(JSON.parse( JSON.parse(res)[0].resultado));
        return (JSON.parse(JSON.parse(res)[0].resultado));
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }
  ObtenerTasaDeConversionMes(fechaInicial: string, fechaFinal: string, codigoTienda: string) {

    return this.httpclient.get('https://us-central1-retaildigitalleonisa20.cloudfunctions.net/api/camera/mes/' + fechaInicial + '/' + fechaFinal + '/' + codigoTienda)
      .toPromise()
      .then((res: any) => {
        // console.log(JSON.parse( JSON.parse(res)[0].resultado));
        return (JSON.parse(JSON.parse(res)[0].resultado));
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }

  notificacion(data: any) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `key=AAAAFSeAQqo:APA91bEVvDUT0glYRLx7R419SDL_dRDGzBXEcxoZwHDVt3ZLRcLjF8o6HK4eWQiZKSlLUM4InrtoEuJ7BcnLgNxz8frNmWHU1xYYiEG1srjJWWbkzggZkzry2XSLuI6Ywr7Yptns3Xe5`
    })
    return this.httpclient.post('https://fcm.googleapis.com/fcm/send', data, { headers: headers })
      .toPromise()
      .then((res: any) => {
        return res;
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }

  loginInfo(data: any) {
    const auth_token = sessionStorage.getItem('rt')
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`
    })
    return this.httpclient.get('https://srvextranet.leonisa.com/Aplicativos_Informaticos/MetasRetailService/api/user/getInfoUser/' + data, { headers: headers })
      .toPromise()
      .then((res: any) => {
        return res;
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }

  getTiendaUsuario(data: any) {
    const auth_token = sessionStorage.getItem('rt')
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`
    })
    return this.httpclient.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/metasRetailService/api/user/getTiendasUsuario', data, { headers: headers })
      .toPromise()
      .then((res: any) => {
        return res.paises[0];
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }
  getBase64(data: any) {
    const auth_token = sessionStorage.getItem('rt')
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`
    })
    return this.httpclient.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/metasRetailService/api/efectivoTiendas/consultarComprobante', data, { headers: headers })
      .toPromise()
      .then((res: any) => {
        return res.comprobante;
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }

  getTokenTPA(data: any) {

    return this.httpclient.post('https://appsvcnpsecurity.azurewebsites.net/api/Login/ValidarUsuarioTimePlanner', data)
      .toPromise()
      .then((res: any) => {
        return res.data.token;
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });

  }
  getTimeTPA(idUser, idTienda, anio, mes, token) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    })
    return this.httpclient.get('https://appsvcnpsecurity.azurewebsites.net/api/Persona/GetEventosByPuntoVentaConNovedades/' + idUser + '/' + idTienda + '/' + anio + '/' + mes, { headers: headers })
      .toPromise()
      .then((res: any) => {
        return res[0].NovedadesPlaneadas;
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });
  }
  getTimeTPA1(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    return this.httpclient.post('https://appsvcnpsecurity.azurewebsites.net/api/Persona/GetEventosByPuntosDeVentaConNovedadesByTiendasYFechas', data, { headers: headers })
      .toPromise()
      .then((res: any) => {
        return res[0].NovedadesPlaneadas;
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });
  }
  getEntersbyHour(datos) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `ZkGGszMM34nbFbTzcQXNvkzsYQw5mdSWpqvFz74tLge34Xt6D2`
    })
    return this.httpclient.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/metasRetailService/api/indicadores/obtenerEntradasHora/', datos, { headers: headers })
      .toPromise()
      .then((res: any) => {
        return res;
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });
  }
  getInfoPanama(datos) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `ZkGGszMM34nbFbTzcQXNvkzsYQw5mdSWpqvFz74tLge34Xt6D2`
    })
    return this.httpclient.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/metasRetailService/api/indicadores/listarIndicadoresAcumuladosPais/', datos, { headers: headers })
      .toPromise()
      .then((res: any) => {
        return res;
      }).catch(err => {
        console.log('failed' + err);
        return err.ok;
      });
  }

}

