<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <!-- content-header component -->
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
  
      <!-- Basic Alerts start -->
     
      <section id="home-page">
        <section class="range-selection-DP">
  
          <div class="row">
            <div class="col-12">
              <div class="card">
  
                <div class="card-body">
  
                
                  <div class="card">
                    <div class="row">
  
                      <div class="col-12">
                        <div class="row">
                          
                          <div class="col">
                            
  
                            <button class="btn btn-flat-success" (click)="enviarexceventas()" ><i
                                data-feather='file-text'></i> Descargar Plantilla Reposicion</button>
                            <swal  (beforeOpen)="questionBeforeOpen()"> </swal>
                            <swal  (beforeOpen)="questionBeforeOpen2()"> </swal>
                            <swal  (beforeOpen)="questionBeforeOpen3()"> </swal>
                          </div>
                        </div>
                        <br>
                        <span style="font-weight: bolder;">Subir Presupuesto</span>
  
                        <fieldset class="form-group">
                          
                          <div class="custom-file">
                            <input type="file"  class="custom-file-input"
                              (change)="fileUpload($event)" ng2FileSelect [uploader]="uploader" id="file-upload-single"
                              accept=".xls,.xlsx" [(ngModel)]="value"/>
                            <label class="custom-file-label">Seleccione el archivo</label>
                          </div>
                        </fieldset>
                        <div class="col-lg-12 overflow-auto">
                          <div class="d-flex justify-content-between mb-1">
                            
                            <p>archivos: {{ uploader?.queue?.length }}</p>
                          </div>
  
                          <table class="table">
                            <thead>
                              <tr>
                                <th width="50%">Nombre</th>
                                <th>Tamaño</th>
                                
                                <th>Estado</th>
                                <th>Acciones</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of uploader.queue">
                                <td>
                                  <strong>{{ item?.file?.name }}</strong>
                                </td>
                                <td *ngIf="uploader.options.isHTML5" nowrap>
                                  {{ item?.file?.size / 1024 / 1024 | number: '.2' }} MB
                                </td>
                                
                                <td >
                                  <div *ngIf="subio" class="badge badge-glow badge-success">adjunto correctamente</div>
                          
                                  <div *ngIf="nosubio" class="badge badge-glow badge-danger">Error al cargar</div>
                                </td>
                                <!-- <td nowrap>
                                  <button type="button" class="btn btn-raised btn-outline-success btn-sm mr-50"
                                    (click)="enviar()"
                                    [disabled]="item.isReady || item.isUploading || item.isSuccess" rippleEffect>
                                    <span *ngIf="loading" class="spinner-border spinner-border-sm"></span><span data-feather="upload"></span>Subir
                                  </button>
                                  <button type="button" class="btn btn-raised btn-outline-warning btn-sm mr-50"
                                    (click)="item.cancel()" [disabled]="!item.isUploading" rippleEffect>
                                    <i data-feather="slash"></i>Cancelar
                                  </button> -->
                                  <button type="button" class="btn btn-raised btn-outline-danger btn-sm"
                                    (click)="remove(item)" rippleEffect>
                                    <i data-feather="trash"></i> Eliminar
                                  </button>
                                <!-- </td>  -->
                              </tr>
                            </tbody>
  
                          </table>
  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
        </section>
      </section>
    </div>
  </div>
  <div class="container">
    <div class="card">
      <div class="card-header">
        Reposicion Sugerida
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-hover-animation">
            <tr>
              <td><button (click)="exportarExcel()" class="btn btn-success mt-3">Exportar a Excel</button></td>
            </tr>
            <tr>
              <th>Referencia</th>
              <th>Talla</th>
              <th>Color</th>
              <th>Inv Total</th>
              <th>Ventas Mes</th>
              <th>Reponer</th>
              
            </tr>
             
            <tr *ngFor="let item of tabla">
              <td>{{item.REFERENCIA}}</td>
              <td>{{item.TALLA}}</td>
              <td>{{item.COLOR}}</td>
              <td>{{item.INVENTARIOTOTALPV}}</td>
              <td>{{item.VENTASPROMEDIO}}</td>
              <td>{{ calcularValor(item) }}</td>

            </tr>
           
          </table>
        </div>
      </div>
    </div>
  </div>
  