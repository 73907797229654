import { CoreMenu } from '@core/types'

export const menu4: 
CoreMenu[] = [
  
  {
    id: 'home',
    title: 'Metas',
    translate: 'MENU.HOME',
    type: 'item',
    icon: 'home',
    url: 'home'
  },
  {
    id: 'subirFactura',
    title: 'Subir-Consignacion GPT 3.5 AI',
    translate: 'MENU.SUBIRFACTURA',
    type: 'item',
    icon: 'layers',
    url: 'subirFactura'
  }
  
]
