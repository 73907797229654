<div class="content-wrapper container-xxl p-0">

    <div class="content-body">
        <!-- content-header component -->

        <app-content-header [contentHeader]="contentHeader">

        </app-content-header>

        <!-- Basic Alerts start -->
        <!-- <core-theme-customizer></core-theme-customizer> -->
        <section id="home-page">
            <section class="range-selection-DP">

                <div class="row">
                    <div class="col-12">
                        <div class="card">

                            <div class="card-body">

                                <!-- Range selection Date Picker -->
                                <div class="card">
                                    <div class="row">
                                        <div class="col-12">
                                            <span style="font-weight: bolder;">Fechas</span>
                                            <div class="row" style="margin-left:1rem;">
                                                <form class="form-inline">
                                                    <div class="form-group">
                                                        <div class="input-group w-0 ">
                                                            <input name="datepicker" class="form-control" ngbDatepicker
                                                                #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                                                [showWeekNumbers]="false"
                                                                (dateSelect)="onDateSelection($event,datepicker,modalBasic)"
                                                                [displayMonths]="1" [dayTemplate]="t"
                                                                outsideDays="visible" [startDate]="fromDate!"
                                                                tabindex="-1" />
                                                            <ng-template #t let-date let-currentMonth="currentMonth"
                                                                let-selected="selected" let-focused="focused">
                                                                <span class="custom-day" [class.focused]="focused"
                                                                    [class.hidden]="date.month !== currentMonth"
                                                                    [class.text-muted]="disabled"
                                                                    [class.range]="isRange(date)"
                                                                    [class.faded]="isHovered(date) || isInside(date)"
                                                                    (mouseenter)="hoveredDate = date"
                                                                    (mouseleave)="hoveredDate = null">
                                                                    {{ date.day }}


                                                                </span>

                                                            </ng-template>

                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input style="display: none;" #dpFromDate
                                                                class="form-control" placeholder="yyyy-mm-dd"
                                                                name="dpFromDate" [value]="formatter.format(fromDate)"
                                                                (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                                                                disabled />
                                                            <div class="input-group fecha">
                                                                <input class="fecha" type="text" [value]="desdehasta"
                                                                    name="" id="" disabled>
                                                            </div>
                                                            <div class="input-group-append">
                                                                <button
                                                                    class="btn btn-outline-secondary feather icon-calendar"
                                                                    (click)="datepicker.toggle()" type="button"
                                                                    rippleEffect></button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ng-template #modalBasic let-modal>
                                                        <div class="modal-header">
                                                            <h4 class="modal-title" id="myModalLabel1"><i
                                                                    data-feather='arrow-left'></i></h4>

                                                        </div>
                                                        <div class="modal-body" tabindex="0" ngbAutofocus>

                                                            <p>
                                                                Seleccione la fecha de inicio y la fecha fin en el mismo
                                                                calendario
                                                            </p>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-primary"
                                                                (click)="modal.close('Accept click')" rippleEffect>
                                                                Aceptar
                                                            </button>
                                                        </div>
                                                    </ng-template>
                                                    <div class="form-group ml-sm-2" style="display: none;">
                                                        <div class="input-group">
                                                            <input #dpToDate class="form-control"
                                                                placeholder="yyyy-mm-dd" name="dpToDate"
                                                                [value]="formatter.format(toDate)" disabled />
                                                            <div class="input-group-append">
                                                                <button class="btn btn-outline-secondary feather "
                                                                    (click)="datepicker.toggle()" type="button"
                                                                    rippleEffect disabled></button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </form>



                                                <div class="row">
                                                    <div class="columnbut">
                                                        <button class="btn btn-outline-primary btn-sm m-2 float-left"
                                                            (click)="seleccionarAnterior()" rippleEffect>
                                                            Seleccionar Mes Anterior
                                                        </button>
                                                    </div>
                                                    <div class="columnbut">
                                                        <button class="btn btn-outline-primary btn-sm m-2 float-left"
                                                            (click)="seleccionarMesActual()" rippleEffect>
                                                            Seleccionar Mes Actual
                                                        </button>
                                                    </div>
                                                    <div class="columnbut">
                                                        <button class="btn btn-outline-primary btn-sm m-2 float-left"
                                                            (click)="seleccionarHoy()" rippleEffect>
                                                            Seleccionar Hoy</button>
                                                    </div>

                                                    <div class="columnbut">

                                                        <button class="btn btn-outline-primary btn-sm m-2 float-left"
                                                            (click)="seleccionarAyer()" rippleEffect>
                                                            Seleccionar Ayer</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column" style="width:40% ">
                                            <div class="row">
                                                <span style="font-weight: bolder;">Punto Venta</span>
                                                <div *ngIf="mostrarfiltros" class="columy"
                                                    style="padding-left: unset;padding-right: unset;">


                                                    <ng-select [clearSearchOnAdd]="true" [items]="tiendas"
                                                        [clearable]="false" [multiple]="true" [closeOnSelect]="false"
                                                        [searchable]="true" bindLabel="Nombre"
                                                        placeholder="Seleccione PV" [(ngModel)]="codigoTienda">
                                                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                            <span class="ng-value-label" [value]="item.Codigo"> {{
                                                                item.Nombre }}</span>
                                                            <span class="ng-value-icon right" (click)="clear(item)"
                                                                aria-hidden="true">×</span>
                                                        </ng-template>
                                                    </ng-select>


                                                </div>
                                                <div class="columx" style="padding-left: unset;padding-right: unset;">
                                                    <button type="button" class="btn btn-flat-danger" rippleEffect
                                                        (click)="limpiarFiltPv()" title="limpiar filtro">X</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <button [disabled]="loading" (click)="buscar()" class="btn btn-relief-primary"
                                        tabindex="4" rippleEffect>
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>Consultar
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div class="card sdiv">
                            <div class="card-header">
                                <h4> <b>Total Acumulado</b> </h4>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table table-hover-animation">
                                        <thead>
                                            <tr>
                                                <th>Ventas</th>
                                                <th>Actual</th>

                                                <th>Unidades Actuales</th>
                                                <th>Transacciones Actuales</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Fisicas</th>
                                                <td>
                                                    {{ventas?.TotalVentasFisicas | currency : '$' : 'symbol' :'1.0-0'}}
                                                </td>

                                                <td>
                                                    {{ventas?.TotalUnidadesFisicas}}
                                                </td>
                                                <td>
                                                    {{ventas?.TotalTransaccionesFisicas}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Digitales</th>
                                                <td>{{ventas?.TotalVentasDigitales | currency : '$' : 'symbol'
                                                    :'1.0-0'}}
                                                </td>

                                                <td>{{ventas?.TotalUnidadesDigitales}}</td>
                                                <td>{{ventas?.TotalTransaccionesDigitales}}</td>
                                            </tr>
                                            <tr>
                                                <th>Totales</th>
                                                <td>{{ventas?.TotalVentasFisicas+ventas?.TotalVentasDigitales | currency
                                                    :
                                                    '$' : 'symbol' :'1.0-0'}}</td>

                                                <td>{{ventas?.TotalUnidadesFisicas+ventas?.TotalUnidadesDigitales}}</td>
                                                <td>{{ventas?.TotalTransaccionesFisicasConRepetidas+ventas?.TotalTransaccionesDigitales}}
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <div class="card sdiv">
                            <div class="card-header">
                                <h4> <b>Cumplimiento</b> </h4>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table table-hover-animation">
                                        <thead>
                                            <tr>
                                                <th>Total Ventas</th>
                                                <th>Presupuesto</th>

                                                <th>Cumplimiento</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>

                                                <td>
                                                    {{ventas?.TotalVentasFisicas + ventas?.TotalVentasDigitales|
                                                    currency : '$' : 'symbol' :'1.0-0'}}
                                                </td>
                                                <td>
                                                    {{ventas?.presupuestoTotal| currency : '$' : 'symbol' :'1.0-0'}}
                                                </td>
                                                <td>
                                                    {{((ventas?.TotalVentasFisicas +
                                                    ventas?.TotalVentasDigitales)*100)/ventas?.presupuestoTotal |
                                                    number:'1.0-0'}}%
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                        <div class="card sdiv">
                            <div class="card-header">
                                <h4> <b>Crecimiento</b> </h4>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table table-hover-animation">
                                        <thead>
                                            <tr>
                                                <th>Variable</th>
                                                <th>Actual</th>
                                                <th>Anterior</th>
                                                <th>Crecimiento</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Ventas</th>
                                                <td>
                                                    {{ventas?.TotalVentasFisicas + ventas?.TotalVentasDigitales|
                                                    currency : '$' : 'symbol' :'1.0-0'}}
                                                </td>
                                                <td>
                                                    {{ventas?.TotalVentasFisicas_Anterior + ventas?.TotalVentasDigitales_Antierior| currency : '$' : 'symbol' :'1.0-0'}}
                                                </td>
                                                <td>
                                                    {{(((ventas?.TotalVentasFisicas +
                                                    ventas?.TotalVentasDigitales)*100)/(ventas?.TotalVentasFisicas_Anterior + ventas?.TotalVentasDigitales_Antierior))-100 |
                                                    number:'1.0-0'}}%
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Unidades</th>
                                                <td>
                                                    {{ventas?.TotalUnidadesFisicas + ventas?.TotalUnidadesDigitales|
                                                    number:'1.0-0'}}
                                                </td>
                                                <td>
                                                    {{ventas?.TotalUnidadesFisicas_Anterior + ventas?.TotalUnidadesDigitales_Anterior| number:'1.0-0'}}
                                                </td>
                                                <td>
                                                    {{(((ventas?.TotalUnidadesFisicas +
                                                    ventas?.TotalUnidadesDigitales)*100)/(ventas?.TotalUnidadesFisicas_Anterior + ventas?.TotalUnidadesDigitales_Anterior))-100 |
                                                    number:'1.0-0'}}%
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Transacciones</th>
                                                <td>
                                                    {{ventas?.TotalTransaccionesFisicasConRepetidas + ventas?.TotalTransaccionesDigitales|
                                                    number:'1.0-0'}}
                                                </td>
                                                <td>
                                                    {{ventas?.TotalTransaccionesFisicasConRepetidas_Anterior + ventas?.TotalTransaccionesDigitalesConRepetidas_Anterior| number:'1.0-0'}}
                                                </td>
                                                <td>
                                                    {{(((ventas?.TotalTransaccionesFisicasConRepetidas + ventas?.TotalTransaccionesDigitales)*100)/(ventas?.TotalTransaccionesFisicasConRepetidas_Anterior + ventas?.TotalTransaccionesDigitalesConRepetidas_Anterior))-100 |
                                                    number:'1.0-0'}}%
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>UPT</th>
                                                <td>
                                                    {{(ventas?.TotalUnidadesFisicas + ventas?.TotalUnidadesDigitales)/(ventas?.TotalTransaccionesFisicasConRepetidas + ventas?.TotalTransaccionesDigitales)|
                                                    number:'1.2-2'}}
                                                </td>
                                                <td>
                                                    {{(ventas?.TotalUnidadesFisicas_Anterior + ventas?.TotalUnidadesDigitales_Anterior)/(ventas?.TotalTransaccionesFisicasConRepetidas_Anterior + ventas?.TotalTransaccionesDigitalesConRepetidas_Anterior)| number:'1.2-2'}}
                                                </td>
                                                <td>
                                                    {{((((ventas?.TotalUnidadesFisicas + ventas?.TotalUnidadesDigitales)/(ventas?.TotalTransaccionesFisicasConRepetidas + ventas?.TotalTransaccionesDigitales))*100)/((ventas?.TotalUnidadesFisicas_Anterior + ventas?.TotalUnidadesDigitales_Anterior)/(ventas?.TotalTransaccionesFisicasConRepetidas_Anterior + ventas?.TotalTransaccionesDigitalesConRepetidas_Anterior)))-100 |
                                                    number:'1.0-0'}}%
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>VPT</th>
                                                <td>
                                                    {{(ventas?.TotalVentasFisicas + ventas?.TotalVentasDigitales)/(ventas?.TotalTransaccionesFisicasConRepetidas + ventas?.TotalTransaccionesDigitales)|
                                                        currency : '$' : 'symbol' :'1.0-0'}}
                                                </td>
                                                <td>
                                                    {{(ventas?.TotalVentasFisicas_Anterior + ventas?.TotalVentasDigitales_Antierior)/(ventas?.TotalTransaccionesFisicasConRepetidas_Anterior + ventas?.TotalTransaccionesDigitalesConRepetidas_Anterior)| currency : '$' : 'symbol' :'1.0-0'}}
                                                </td>
                                                <td>
                                                    {{(((ventas?.TotalVentasFisicas + ventas?.TotalVentasDigitales)/(ventas?.TotalTransaccionesFisicasConRepetidas + ventas?.TotalTransaccionesDigitales)*100)/((ventas?.TotalVentasFisicas_Anterior + ventas?.TotalVentasDigitales_Antierior)/(ventas?.TotalTransaccionesFisicasConRepetidas_Anterior + ventas?.TotalTransaccionesDigitalesConRepetidas_Anterior)))-100 | number:'1.0-0'}}%
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button type="button" class="btn btn-flat-success" (click)="enviarexceindicadores()" rippleEffect><i
                                        data-feather='file-text'></i>Excel</button>
                                </div>

                            </div>
                        </div>
                        <!-- INICIO MOBILE -->
                        <div class="row ndiv">
        
                            <div class=" card col-lg-6 col-12 mb-1 mb-lg-0">
                              <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button" class="btn btn-outline-primary" rippleEffect style="font-size: 0.8rem;"
                                  (click)="mostrarVentass()">VENTAS</button><button type="button" class="btn btn-outline-primary"
                                  rippleEffect style="font-size: 0.8rem;" (click)="mostrarIndicadoress()">INDICADORES</button>
                                  <!-- <button
                                  type="button" class="btn btn-outline-primary" rippleEffect style="font-size: 0.8rem;"
                                  (click)="mostrarPotencialess()">POTENCIALES</button> -->
                              </div>
                            </div>
                          </div>
                          <div class="row" *ngIf="mostrarVentas">

                            <div class="col-12">
                    
                              <div class="card " style="background-color:#804ef5">
                                <div class="card-header">
                                  <div style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
                                    ACUMULADO FISICO: {{ventas?.TotalVentasFisicas | currency : '$' : 'symbol' :'1.0-0'}}
                                  </div>
                                </div>
                                <div class="divider my-2">
                                    <div class="divider-text"></div>
                                  </div>
                                  <button type="button" class="btn btn-relief-primary" (click)=mostrarDetalleVentasFisico() rippleEffect>
                                    <span *ngIf="mostrarDetalleVentasFisicos"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-minus-square">
                                        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                        <line x1="8" y1="12" x2="16" y2="12"></line>
                                      </svg>VER MENOS</span>
                                    <span *ngIf="!mostrarDetalleVentasFisicos"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-plus-square">
                                        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                        <line x1="12" y1="8" x2="12" y2="16"></line>
                                        <line x1="8" y1="12" x2="16" y2="12"></line>
                                      </svg> VER MAS</span>
                                  </button>
                              </div>
                            </div>
                            
                          </div>
                          <div class="card" *ngIf="mostrarDetalleVentasFisicos">
                            <table class="table table-hover-animation">
                              <tbody>
                            
                                <tr>
                                  <th>
                
                                    <div class="avatar rounded">
                                      <div class="avatar-content">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                          class="feather feather-shopping-bag">
                                          <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                                          <line x1="3" y1="6" x2="21" y2="6"></line>
                                          <path d="M16 10a4 4 0 0 1-8 0"></path>
                                        </svg>
                                      </div>
                                    </div>
                                    UND FISICAS
                                  </th>
                
                                  <th>:</th>
                                  <th>{{ventas?.TotalUnidadesFisicas}}</th>
                                  <!-- <th *ngFor="let item of diar">{{item.artFis | number:'1.0-0'}}</th> -->
                                </tr>
                                <div class="divider my-2">
                                  <div class="divider-text"></div>
                                </div>
                                <tr>
                                  <th>
                                    <div class="avatar rounded">
                                      <div class="avatar-content">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                          class="feather feather-credit-card">
                                          <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                                          <line x1="1" y1="10" x2="23" y2="10"></line>
                                        </svg>
                
                                      </div>
                                    </div>
                                    TRANS FISICAS
                                  </th>
                                  <th>:</th>
                                  <th> {{ventas?.TotalTransaccionesFisicas}}</th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="row" *ngIf="mostrarVentas">

                            <div class="col-12">
                    
                              <div class="card " style="background-color:#804ef5">
                                <div class="card-header">
                                  <div style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
                                    ACUMULADO DIGITAL: {{ventas?.TotalVentasDigitales | currency : '$' : 'symbol'
                                    :'1.0-0'}}
                                  </div>
                                </div>
                                <div class="divider my-2">
                                    <div class="divider-text"></div>
                                  </div>
                                  <button type="button" class="btn btn-relief-primary" (click)=mostrarDetalleVentasDigitales() rippleEffect>
                                    <span *ngIf="mostrarDetalleVentasDigitaless"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-minus-square">
                                        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                        <line x1="8" y1="12" x2="16" y2="12"></line>
                                      </svg>VER MENOS</span>
                                    <span *ngIf="!mostrarDetalleVentasDigitaless"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-plus-square">
                                        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                        <line x1="12" y1="8" x2="12" y2="16"></line>
                                        <line x1="8" y1="12" x2="16" y2="12"></line>
                                      </svg> VER MAS</span>
                                  </button>
                              </div>
                            </div>
                          </div>
                          <div class="card" *ngIf="mostrarDetalleVentasDigitaless">
                            <table class="table table-hover-animation">
                              <tbody>
                                
                                <tr>
                                  <th>
                
                                    <div class="avatar rounded">
                                      <div class="avatar-content">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                          class="feather feather-shopping-bag">
                                          <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                                          <line x1="3" y1="6" x2="21" y2="6"></line>
                                          <path d="M16 10a4 4 0 0 1-8 0"></path>
                                        </svg>
                                      </div>
                                    </div>
                                    UND DIGITALES
                                  </th>
                
                                  <th>:</th>
                                  <th>{{ventas?.TotalUnidadesDigitales}}</th>
                                </tr>
                                <div class="divider my-2">
                                  <div class="divider-text"></div>
                                </div>
                                <tr>
                                  <th>
                                    <div class="avatar rounded">
                                      <div class="avatar-content">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                          class="feather feather-credit-card">
                                          <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                                          <line x1="1" y1="10" x2="23" y2="10"></line>
                                        </svg>
                
                                      </div>
                                    </div>
                                    TRANS DIGITALES
                                  </th>
                                  <th>:</th>
                                  <th>{{ventas?.TotalTransaccionesDigitales}}</th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="row" *ngIf="mostrarVentas">

                            <div class="col-12">
                    
                              <div class="card " style="background-color:#804ef5">
                                <div class="card-header">
                                  <div style="color: aliceblue;display: block;font-weight: bolder;margin-left: auto;margin-right: auto;">
                                    ACUMULADO TOTAL: {{ventas?.TotalVentasFisicas+ventas?.TotalVentasDigitales | currency
                                        :
                                        '$' : 'symbol' :'1.0-0'}}
                                  </div>
                                </div>
                                <div class="divider my-2">
                                    <div class="divider-text"></div>
                                  </div>
                                  <button type="button" class="btn btn-relief-primary" (click)=mostrarDetalleVentasTotales() rippleEffect>
                                    <span *ngIf="mostrarDetalleVentasTotal"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-minus-square">
                                        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                        <line x1="8" y1="12" x2="16" y2="12"></line>
                                      </svg>VER MENOS</span>
                                    <span *ngIf="!mostrarDetalleVentasTotal"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-plus-square">
                                        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                        <line x1="12" y1="8" x2="12" y2="16"></line>
                                        <line x1="8" y1="12" x2="16" y2="12"></line>
                                      </svg> VER MAS</span>
                                  </button>
                              </div>
                            </div>
                          </div>
                          <div class="card" *ngIf="mostrarDetalleVentasTotal">
                            <table class="table table-hover-animation">
                              <tbody>
                                
                                
                                <div class="divider my-2">
                                  <div class="divider-text"></div>
                                </div>
                                <tr>
                                  <th>
                
                                    <div class="avatar rounded">
                                      <div class="avatar-content">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                          class="feather feather-shopping-bag">
                                          <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                                          <line x1="3" y1="6" x2="21" y2="6"></line>
                                          <path d="M16 10a4 4 0 0 1-8 0"></path>
                                        </svg>
                                      </div>
                                    </div>
                                    UND FISICAS
                                  </th>
                
                                  <th>:</th>
                                  <th>{{ventas?.TotalUnidadesFisicas+ventas?.TotalUnidadesDigitales}}</th>
                                </tr>
                                <div class="divider my-2">
                                  <div class="divider-text"></div>
                                </div>
                                <tr>
                                  <th>
                                    <div class="avatar rounded">
                                      <div class="avatar-content">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                          class="feather feather-credit-card">
                                          <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                                          <line x1="1" y1="10" x2="23" y2="10"></line>
                                        </svg>
                
                                      </div>
                                    </div>
                                    TRANS FISICAS
                                  </th>
                                  <th>:</th>
                                  <th>{{ventas?.TotalTransaccionesFisicasConRepetidas+ventas?.TotalTransaccionesDigitales}}</th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                    </div>
                </div>
            </section>
        </section>
    </div>
</div>